import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Container, Image, Button } from 'semantic-ui-react'
import { compose } from 'redux'
import fullParkingLotIcon from 'components/_assets/full-parking-lot.svg'
import * as actionTypes from 'redux/actionTypes'

import './FullParkingLot.scss'

const mapStateToProps = ({ nfc }) => {
  const { activeSessions } = nfc
  return { activeSessions }
}

const FullParkingLot = ({ t, match, history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: actionTypes.NFC_RESET_ZONE })
  }, [])

  const refreshPage = () => {
    const { params } = match
    history.push(`/p/${params.assetTag}`)
  }

  return (
    <div className="FullParkingLotRoute">
      <Container className="FullParkingLot--container">
        <div className="FullParkingLot--wrapper">
          <Image
            centered
            className="FullParkingLot--image"
            src={fullParkingLotIcon}
          />
          <div className="FullParkingLot--header">
            {t('nfc.fullParkingLot.fullParkingLotHeader')}
          </div>
          <div className="FullParkingLot--copy">
            {t('nfc.fullParkingLot.fullParkingLotCopy')}
          </div>
        </div>
        <Button
          className="FullParkingLot--refreshButton"
          primary
          fluid
          onClick={refreshPage}
        >
          {t('nfc.fullParkingLot.refreshButton')}
        </Button>
      </Container>
    </div>
  )
}

FullParkingLot.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetTag: PropTypes.string
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(FullParkingLot)
