import { baseUrl, cancelableFetch, addAppVersion, HEADERS } from './requests'

import { handleRawResponse, handleFetchError } from './responses'

const guestApiURL = baseUrl(GUEST_API_BASE)

function _mergeGuestAuthParams(params, user) {
  const { an_tag } = user // eslint-disable-line camelcase

  return {
    ...params,
    // eslint-disable-next-line camelcase
    an_tag
  }
}

export function apiGET(relativePath, params = {}) {
  const fullParams = addAppVersion(params)

  const requestPromise = cancelableFetch(guestApiURL(relativePath, fullParams))

  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiGET(relativePath, currentUser, params) {
  const authParams = _mergeGuestAuthParams(params, currentUser)
  return apiGET(relativePath, authParams)
}

export function apiPOST(relativePath, params = {}) {
  const fullParams = addAppVersion(params)
  const body = JSON.stringify(fullParams)
  const requestPromise = cancelableFetch(guestApiURL(relativePath), {
    method: 'POST',
    headers: HEADERS,
    body
  })

  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiPOST(relativePath, currentUser, params = {}) {
  const authParams = _mergeGuestAuthParams(params, currentUser)
  return apiPOST(relativePath, authParams)
}

export function apiPATCH(relativePath, params = {}) {
  const fullParams = addAppVersion(params)
  const body = JSON.stringify(fullParams)
  const requestPromise = cancelableFetch(guestApiURL(relativePath), {
    method: 'PATCH',
    headers: HEADERS,
    body
  })

  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiPATCH(relativePath, currentUser, params = {}) {
  const authParams = _mergeGuestAuthParams(params, currentUser)
  return apiPATCH(relativePath, authParams)
}

export function apiDELETE(relativePath, params = {}) {
  const fullParams = addAppVersion(params)
  const requestPromise = cancelableFetch(
    guestApiURL(relativePath, fullParams),
    { method: 'DELETE', header: HEADERS }
  )

  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiDELETE(relativePath, currentUser, params) {
  const authParams = _mergeGuestAuthParams(params, currentUser)
  return apiDELETE(relativePath, authParams)
}
