import { useTranslation } from 'react-i18next'

import catImg from './_assets/cat.png'

import styles from './NoEventAvailable.module.scss'

const NoEventAvailable = () => {
  const [t] = useTranslation()

  return (
    <div className={styles.page}>
      <img className={styles.catImg} src={catImg} alt="cat" />
      <div className={styles.youEarly}>{t('nfc.event.youEarly')}</div>
      <div className={styles.noEventParking}>
        {t('nfc.event.noEventParking')}
      </div>
    </div>
  )
}

export default NoEventAvailable
