import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isRequestSuccessful } from 'app/helpers/api'
import * as AssetTagActions from 'redux/actions'

const mapStateToProps = (state) => {
  return {
    cart: state.nfc.cart,
    loaded: state.nfc.loaded.rates,
    plateNumber: state.nfc.plateNumber
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class RatesLoader extends Component {
  componentDidMount() {
    const { assetTagActions, cart, loaded, handleError, plateNumber } = this.props

    if (!loaded) {
      trackEvent('Rates Loading')
      assetTagActions.loadRates(cart.id, plateNumber).then((json) => {
        trackEvent('Rates Loaded')
        if (!isRequestSuccessful(json)) {
          assetTagActions.failedLoadingRates()
          handleError()
        }

        return json

        /* TODO HANDLE ERROR
        if (includes(json.errors, 'not_allowed')) {
          return browserHistory.replace('/403')
        }

        return browserHistory.replace('/404')
        */
      })
    }
  }

  render() {
    const { loaded, children } = this.props
    if (loaded && children) {
      return children
    }
    return null
  }
}

RatesLoader.defaultProps = {
  handleError: () => {}
}

RatesLoader.propTypes = {
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    createCart: PropTypes.func.isRequired,
    failedLoadingCart: PropTypes.func.isRequired,
    loadRates: PropTypes.func.isRequired,
    failedLoadingRates: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func,
  plateNumber: PropTypes.string,
}
export default connect(mapStateToProps, mapDispatchToProps)(RatesLoader)
