import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import history from 'app/history'
import isEmpty from 'lodash/isEmpty'
import Header from '../layout/Header'
import Button from '../shared/Button'
import ErrorImage from './_assets/tto_error.svg'

import styles from './CameraAccessDeniedRoute.module.scss'

const CameraAccessDeniedRoute = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const assetTag = location.state?.assetTag

  const handleBack = () => {
    if (!isEmpty(assetTag)) {
      history.replace(`/t/${assetTag}`)
    } else {
      history.goBack()
    }
  }

  return (
    <div className={styles.page}>
      <Header backNav onBackClick={handleBack}>{t('nfc.tto.errors.title')}</Header>
      <div className={styles.body}>
        <img src={ErrorImage} alt="Illustration of construction" />
        <h1>{t('nfc.tto.errors.cameraAccessDenied')}</h1>
        <p>{t('nfc.tto.errors.cameraAccessDeniedCopy')}</p>
      </div>
      <Button onClick={handleBack}>{t('nfc.tto.errors.tryAgain')}</Button>
    </div>
  )
}

export default CameraAccessDeniedRoute
