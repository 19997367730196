import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { isRequestSuccessful } from 'app/helpers/api'
import { withRouter } from 'react-router-dom'
import includes from 'lodash/includes'

import * as ParkingSessionActions from 'redux/actions'

const mapStateToProps = (state) => {
  return {
    storedSessionHashid: state.nfc.parkingSession.hashid,
    loaded: state.nfc.loaded.parkingSession
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    parkingSessionActions: bindActionCreators(ParkingSessionActions, dispatch)
  }
}

class ParkingSessionLoader extends Component {
  componentDidMount() {
    const {
      parkingSessionActions,
      storedSessionHashid,
      sessionId,
      handleError,
      history
    } = this.props

    if (sessionId !== storedSessionHashid) {
      parkingSessionActions.loadParkingSession(sessionId).then((json) => {
        if (!isRequestSuccessful(json)) {
          if (
            json.status === 404 ||
            (json.errors && includes(json.errors, 'no_parking_session'))
          ) {
            history.replace('/p/errors/not-found')
          } else {
            handleError()
          }
        }

        return json
      })
    }
  }

  render() {
    const { loaded, children } = this.props
    if (loaded && children) {
      return <div>{children}</div>
    }
    return null
  }
}

ParkingSessionLoader.defaultProps = {
  handleError: () => {}
}

ParkingSessionLoader.propTypes = {
  sessionId: PropTypes.string.isRequired,
  storedSessionHashid: PropTypes.string,
  parkingSessionActions: PropTypes.shape({
    loadParkingSession: PropTypes.func.isRequired,
    failedLoadParkingSession: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ParkingSessionLoader)
