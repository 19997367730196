import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { fullLight, fullDark } from 'components/common/Logos'
import './BrandedTagline.scss'

const BrandedTagline = ({ t, variant = 'light', className }) => {
  const classes = classNames('BrandedTagline', className, {
    BrandedTagline__light: variant === 'light',
    BrandedTagline__dark: variant === 'dark'
  })

  const img = variant === 'light' ? fullLight : fullDark

  return (
    <div className={classes}>
      {t('nfc.brandedTagline')}
      &nbsp; <img className="BrandedTagline--brandLogo" src={img} />
    </div>
  )
}

BrandedTagline.propTypes = {
  t: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string
}

export default BrandedTagline
