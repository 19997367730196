export function handleRawResponse(response) {
  if (response.status === 401) {
    const debug = { errors: ['Unauthorized'] }
    const blob = new Blob([JSON.stringify(debug)], { type: 'application/json' })

    return new Response(blob, {
      status: response.status,
      statusText: 'Unauthorized'
    })
  }

  if (response.status === 404) {
    const debug = { errors: ['Not Found'] }
    const blob = new Blob([JSON.stringify(debug)], { type: 'application/json' })

    return new Response(blob, {
      status: response.status,
      statusText: 'Not Found'
    })
  }

  if (response.status >= 500) {
    const debug = { errors: ['handledInternalServerError'] }
    const blob = new Blob([JSON.stringify(debug)], { type: 'application/json' })

    return new Response(blob, {
      status: response.status,
      statusText: 'Internal Server Error'
    })
  }

  return response
}

export function handleFetchError(fail) {
  const debug = {
    errors: ['handledInternalServerError'],
    message: `${fail.name} - ${fail.message}`
  }
  const blob = new Blob([JSON.stringify(debug)], { type: 'application/json' })

  return new Response(blob, {
    status: 500,
    statusText: `Internal Server Error: ${fail.name} - ${fail.message}`
  })
}
