import { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import noParkingImg from 'components/rates/_assets/no-parking.svg'
import warningImg from 'components/rates/_assets/warning.svg'
import freeParkingImg from 'components/rates/_assets/free-parking.svg'

import './SpecialRateCard.scss'

const mapStateToProps = ({ nfc }) => ({
  rates: nfc.rates
})

class SpecialRateCard extends PureComponent {
  render() {
    const { rates, t } = this.props

    const notification = rates.find(
      (rate) => rate.behaviour_type === 'notification'
    )
    const isNotification = !isEmpty(notification)
    const isFreeParking = isNotification && notification.is_free_parking

    let image = <img src={noParkingImg} className="SpecialRateCard--icon" />

    if (isEmpty(rates)) {
      image = <img src={warningImg} className="SpecialRateCard--icon" />
    }

    if (isFreeParking) {
      image = <img src={freeParkingImg} className="SpecialRateCard--icon" />
    }

    return (
      <div className="SpecialRateCard">
        {image}
        <div className="SpecialRateCard--header">
          {isFreeParking ? notification.description : t('nfc.zone.noParking')}
        </div>
        {!isFreeParking && (
          <div className="SpecialRateCard--copy">
            {isNotification
              ? notification.description
              : t('nfc.zone.noRatesAvailable')}
          </div>
        )}
      </div>
    )
  }
}

SpecialRateCard.propTypes = {
  t: PropTypes.func.isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      behaviour_type: PropTypes.string,
      is_free_parking: PropTypes.bool
    })
  )
}

export default connect(mapStateToProps)(SpecialRateCard)
