import PropTypes from 'prop-types'
import VgsProvider from './VgsProvider'
import NFCCreditCard from './NFCCreditCard'

const CreditCardPayment = ({ setIsCCScreenOpen, onSuccess }) => {
  return (
    <VgsProvider>
      <NFCCreditCard
        setIsCCScreenOpen={setIsCCScreenOpen}
        onSuccess={onSuccess}
      />
    </VgsProvider>
  )
}

CreditCardPayment.propTypes = {
  setIsCCScreenOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default CreditCardPayment
