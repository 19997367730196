/* global VGS_VAULT_ID VGS_VAULT_ENV VGS_VAULT_CNAME */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Icon } from 'semantic-ui-react'
import { redirectToReceiptReminder } from 'components/purchase/helpers'
import PropTypes from 'prop-types'
import PayWithNewCreditCardButton from 'components/purchase/buttons/PayWithNewCreditCardButton'
import CvvModal from 'components/purchase/CvvModal'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import NFCShowErrorComponent from 'components/NFCShowErrorComponent'
import CardErrorModal from 'components/CardErrorModal'
import debounce from 'lodash/debounce'
import mapValues from 'lodash/mapValues'
import vgsCss from 'components/styles/vgs-input'
import Header from 'components/layout/Header'
import VgsProvider from 'components/purchase/VgsProvider'
import isEmpty from 'lodash/isEmpty'
import { isRequestSuccessful } from 'app/helpers/api'
import * as NFCActions from 'redux/actions'
import AccountLoader from 'components/loaders/AccountLoader'
import { tagPurchase, ga4Conversion } from 'app/helpers/tagManager'
import LockIcon from './_assets/lock-icon.svg'

import './NFCCreditCardPurchaseRoute.scss'

const mapStateToProps = ({ nfc }) => {
  return {
    plateNumber: nfc.plateNumber,
    cart: nfc.cart,
    assetTag: nfc.assetTag,
    account: nfc.account
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class NFCCreditCardPurchaseRoute extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      showCvvModal: false,
      isSubmitting: false,
      validForm: false,
      fieldErrors: {
        number: false,
        cvc: false,
        expiry: false,
        avs_code: false
      },
      isErrorModalOpen: false
    }
  }

  componentDidMount() {
    const { cart, match, nfcActions, t } = this.props

    if (isEmpty(cart)) {
      window.location.replace(`/p/${match.params.assetTag}`)
    }

    this.form = window.VGSCollect.create(
      VGS_VAULT_ID,
      VGS_VAULT_ENV,
      (state) => {
        mapValues(state, (field) => {
          const {
            isDirty,
            isValid,
            isFocused,
            isEmpty: isEmptyField,
            name
          } = field

          const errorCondition =
            isDirty && !isValid && !isFocused && !isEmptyField

          this.setState((prevState) => {
            const fieldErrors = {
              ...prevState.fieldErrors,
              [name]: errorCondition
            }

            const allFieldsValid = Object.values(state).every(
              (formField) => formField.isValid
            )
            const allFieldsFilled = !Object.values(state).some(
              (formField) => formField.isEmpty
            )

            if (allFieldsValid && allFieldsFilled) {
              nfcActions.clearErrors()
            }

            return {
              fieldErrors,
              validForm: allFieldsValid && allFieldsFilled
            }
          })
        })
      }
    )

    const css = vgsCss

    if (VGS_VAULT_ENV === 'live') {
      this.form.useCname(VGS_VAULT_CNAME)
    }

    this.form.field('#cc-number', {
      type: 'card-number',
      name: 'number',
      successColor: '#0FA844',
      errorColor: '#DA1818',
      placeholder: t('nfc.creditCardPayment.cardNumberPlaceholder'),
      validations: ['required', 'validCardNumber'],
      css,
      showCardIcon: {
        right: '25px'
      }
    })

    this.form.field('#cc-expiration-date', {
      type: 'card-expiration-date',
      name: 'expiry',
      validations: ['required', 'validCardExpirationDate'],
      yearLength: 2,
      placeholder: t('nfc.creditCardPayment.expirationPlaceholder'),
      css
    })

    this.form.field('#cc-cvc', {
      type: 'card-security-code',
      name: 'cvc',
      placeholder: t('nfc.creditCardPayment.cvvPlaceholder'),
      validations: ['required', 'validCardSecurityCode'],
      css
    })

    this.form.field('#cc-postal', {
      type: 'text',
      name: 'avs_code',
      // Uncomment below once we decide to require/validate postal code
      // validations: ['required', 'postal_code/us,ca'],
      placeholder: t('nfc.creditCardPayment.postalZipCodePlaceholder'),
      autoComplete: 'shipping postal-code',
      css
    })
  }

  showCvvModal = () => {
    this.setState({ showCvvModal: true })
  }

  hideCvvModal = () => {
    this.setState({ showCvvModal: false })
  }

  openCardErrorModal = () => {
    this.setState({ isErrorModalOpen: true })
  }

  closeCardErrorModal = () => {
    this.setState({ isErrorModalOpen: false, isSubmitting: false })
  }

  _submit = (e) => {
    e.preventDefault()
    this.setState({ isSubmitting: true })
    const {
      nfcActions: {
        showProcessingTransactionLoader,
        hideProcessingTransactionLoader,
        addError,
        clearErrors
      },
      cart,
      assetTag
    } = this.props
    const { extend_id: extendId } = cart
    const analyticsData = {
      is_extension: !!extendId,
      asset_tag: assetTag
    }
    const { validForm } = this.state
    clearErrors()
    if (!validForm) {
      addError('bad_cc_form')
      this.setState({ isSubmitting: false })
      return
    }
    trackEvent('Credit Card Purchase-With-Capture Clicked', analyticsData)

    try {
      showProcessingTransactionLoader()
      this.purchase()
    } catch {
      hideProcessingTransactionLoader()
    }
  }

  purchase = () => {
    const {
      nfcActions: { addError, hideProcessingTransactionLoader },
      account: { hashid }
    } = this.props

    new Promise((resolve) => {
      this.form.submit(
        '/v4/credit_cards/encrypt',
        {
          data: { uniq_identifier: hashid }
        },
        (_, response) => {
          if (response.ok) {
            resolve(response)
          } else {
            addError('bad_cc_form')
            this.setState({ isSubmitting: false })
            hideProcessingTransactionLoader()
          }
        }
      )
    }).then((response) => {
      if (response.ok) {
        this.createParkingSession(response.data)
      }
    })
  }

  createParkingSession = (data) => {
    const { enc_payload: encPayload, request_id: requestId } = data
    const {
      nfcActions: {
        hideProcessingTransactionLoader,
        createParkingSessionTransactionId
      },
      plateNumber,
      cart,
      assetTag
    } = this.props
    const { extend_id: extendId } = cart
    const analyticsData = {
      is_extension: !!extendId,
      asset_tag: assetTag
    }
    createParkingSessionTransactionId(
      cart.id,
      plateNumber,
      encPayload,
      requestId
    ).then((json) => {
      if (isRequestSuccessful(json)) {
        const { hashid } = json.data
        trackEvent('Credit Card Purchase Completion Succeeded', analyticsData)
        ga4Conversion(json.data, cart)
        tagPurchase(json.data, cart)
        redirectToReceiptReminder(hashid)
      } else {
        trackEvent('Credit Card Purchase Completion Failed', analyticsData)
        this.setState({ isSubmitting: false })
        hideProcessingTransactionLoader()
        this.openCardErrorModal()
        // TODO - handle errors
      }
    })
  }

  handleSubmit = debounce(this._submit, 500, { leading: true, trailing: false })

  render() {
    const { t, history } = this.props
    const {
      showCvvModal,
      isSubmitting,
      isErrorModalOpen,
      validForm,
      fieldErrors
    } = this.state
    return (
      <div className="NFCCreditCardPurchaseRoute">
        <VgsProvider />
        <AccountLoader />
        <ProcessingLoader t={t} />
        <Header backNav onBackClick={history.goBack}>
          {t('nfc.creditCardPayment.header')}
        </Header>

        <div className="NFCCreditCardPurchaseRoute--body">
          <div className="NFCCreditCardPurchaseRoute--form">
            <NFCShowErrorComponent
              t={t}
              className="NFCCreditCardPurchaseRoute--errorMessage"
            />
            <div className="NFCCreditCardPurchaseRoute--VGS-inputGroup">
              <div className="NFCCreditCardPurchaseRoute--label">
                {t('nfc.creditCardPayment.cardNumberLabel')}
              </div>
              <div
                id="cc-number"
                className="NFCCreditCardPurchaseRoute--VGS-input"
              >
                <div className="form-control-static">
                  <span className="fake-input" />
                </div>
              </div>
              {fieldErrors.number && (
                <p className="NFCCreditCardPurchaseRoute--inputError">
                  {t('nfc.creditCardPayment.cardNumberInvalid')}
                </p>
              )}
            </div>

            <div className="NFCCreditCardPurchaseRoute--VGS-inputGroup--row">
              <div className="NFCCreditCardPurchaseRoute--VGS-inputGroup">
                <div className="NFCCreditCardPurchaseRoute--label">
                  {t('nfc.creditCardPayment.expirationLabel')}
                </div>
                <div
                  id="cc-expiration-date"
                  className="NFCCreditCardPurchaseRoute--VGS-input--col"
                >
                  <div className="form-control-static">
                    <span />
                  </div>
                </div>
                {fieldErrors.expiry && (
                  <p className="NFCCreditCardPurchaseRoute--inputError">
                    {t('nfc.creditCardPayment.expiryInvalid')}
                  </p>
                )}
              </div>
              <div className="NFCCreditCardPurchaseRoute--VGS-inputGroup">
                <div className="NFCCreditCardPurchaseRoute--label">
                  {t('nfc.creditCardPayment.cvvLabel')}
                  <Icon
                    fitted
                    name="question circle"
                    onClick={this.showCvvModal}
                    size="small"
                  />
                </div>
                <div
                  id="cc-cvc"
                  className="NFCCreditCardPurchaseRoute--VGS-input--col"
                >
                  <div className="form-control-static">
                    <span className="fake-input" />
                  </div>
                </div>
                {fieldErrors.cvc && (
                  <p className="NFCCreditCardPurchaseRoute--inputError">
                    {t('nfc.creditCardPayment.cvvInvalid')}
                  </p>
                )}
              </div>
            </div>
            <div className="NFCCreditCardPurchaseRoute--VGS-inputGroup">
              <div className="NFCCreditCardPurchaseRoute--label">
                {t('nfc.creditCardPayment.postalZipCode')}
              </div>
              <div
                id="cc-postal"
                className="NFCCreditCardPurchaseRoute--VGS-input"
              >
                <div className="form-control-static">
                  <span className="fake-input" />
                </div>
              </div>
              {fieldErrors.avs_code && (
                <p className="NFCCreditCardPurchaseRoute--inputError">
                  {t('nfc.creditCardPayment.postalInvalid')}
                </p>
              )}
            </div>
          </div>

          <div className="NFCCreditCardPurchaseRoute--encryptionInfo">
            <img src={LockIcon} alt="lock icon" />
            <p
              dangerouslySetInnerHTML={{
                __html: t('nfc.creditCardPayment.encryptedDataTagline')
              }}
            />
          </div>
          <PayWithNewCreditCardButton
            t={t}
            onClick={this._submit}
            disabled={!validForm}
            loading={isSubmitting}
          />
        </div>
        <CvvModal
          t={t}
          isOpen={showCvvModal}
          onClose={this.hideCvvModal}
        />
        <CardErrorModal
          open={isErrorModalOpen}
          onClose={this.closeCardErrorModal}
        />
      </div>
    )
  }
}

NFCCreditCardPurchaseRoute.propTypes = {
  t: PropTypes.func,
  nfcActions: PropTypes.shape({
    createParkingSessionTransactionId: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    addError: PropTypes.func.isRequired,
    showProcessingTransactionLoader: PropTypes.func.isRequired,
    hideProcessingTransactionLoader: PropTypes.func.isRequired
  }),
  cart: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    extend_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetTag: PropTypes.string
    }).isRequired
  }).isRequired,
  assetTag: PropTypes.string,
  plateNumber: PropTypes.string.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  account: PropTypes.shape({
    hashid: PropTypes.string.isRequired
  }).isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter
)(NFCCreditCardPurchaseRoute)
