import { useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTruncateContentByLines } from 'hooks/useTruncateContentByLines'
import SeeMoreButton from 'components/common/SeeMoreButton'

import styles from './PurchaseCompleteCustomReceiptText.module.scss'

const MAX_LINES = 3

const PurchaseCompleteCustomReceiptText = () => {
  const { t } = useTranslation()
  const { customReceiptText } = useSelector((state) => state.nfc.parkingSession)
  const description = get(customReceiptText, 'description', null)
  const header = get(customReceiptText, 'header', null)

  const contentRef = useRef(null)
  const { isTruncated, showButton, truncationStyles, toggleTruncation } =
    useTruncateContentByLines(contentRef, MAX_LINES, customReceiptText)

  const buttonText = isTruncated
    ? t('nfc.purchaseComplete.seeMore')
    : t('nfc.purchaseComplete.seeLess')

  if (isEmpty(description) && isEmpty(header)) {
    return null
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.customReceiptHeader}>{header}</h4>
      <div
        ref={contentRef}
        style={truncationStyles}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {showButton && (
        <div className={styles.buttonContainer}>
          <SeeMoreButton onClick={toggleTruncation}>{buttonText}</SeeMoreButton>
        </div>
      )}
    </div>
  )
}

export default PurchaseCompleteCustomReceiptText
