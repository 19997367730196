import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './SeeMoreButton.module.scss'

const SeeMoreButton = ({ onClick, children, className }) => (
  <button
    type="button"
    className={classNames(styles.button, className)}
    onClick={onClick}
  >
    {children}
  </button>
)

SeeMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default SeeMoreButton
