import { Loader as SemanticLoader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import styles from './Loader.module.scss'

const Loader = () => {
  const { t } = useTranslation()
  return (
    <SemanticLoader size="large" active>
      <div className={styles.loadingText}>
        {t('nfc.tto.loading')}
      </div>
    </SemanticLoader>
  )
}

export default Loader
