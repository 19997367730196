import { useTranslation } from 'react-i18next'
import Accordion from '../shared/Accordion'
import OrderedList from '../shared/OrderedList'
import Header from '../layout/Header'
import SuccessBanner from './_assets/success_banner.svg'
import InfoIcon from './_assets/icon_info.svg'

import styles from './SuccessRoute.module.scss'

const SuccessRoute = () => {
  const { t } = useTranslation()

  const instructions = [
    {
      heading: (
        <p className={styles.listHeader}>
          {t('nfc.tto.success.instruction1-1')}
          <b>{t('nfc.tto.success.instruction1-2')}</b>
        </p>
      )
    },
    {
      heading: (
        <p className={styles.listHeader}>
          {t('nfc.tto.success.instruction2-1')}
          <b>{t('nfc.tto.success.instruction2-2')}</b>
        </p>
      )
    }
  ]

  const panels = [
    {
      key: 'notice',
      title: {
        content: t('nfc.tto.success.noticeHeader'),
        icon: <img src={InfoIcon} alt="Exclamation Icon" />
      },
      content: {
        content: (
          <p>
            {t('nfc.tto.success.notice1')}
            <em>
              <b>{t('nfc.tto.success.notice2')}</b>
            </em>
            {t('nfc.tto.success.notice3')}
          </p>
        )
      }
    }
  ]

  return (
    <div className={styles.page}>
      <Header>Success</Header>
      <div className={styles.banner}>
        <img src={SuccessBanner} alt="Receipt printing" />
      </div>
      <div className={styles.body}>
        <h1 className={styles.title}>{t('nfc.tto.success.title')}</h1>
        <OrderedList instructions={instructions} />
        <Accordion panels={panels} />
      </div>
    </div>
  )
}

export default SuccessRoute
