import React from 'react'
import PropTypes from 'prop-types'

import BlankContainer from 'components/layout/BlankContainer'
import DarkFooter from './DarkFooter'
import styles from './LayoutWithFooter.module.scss'

const LayoutWithFooter = ({ children }) => {
  return (
    <BlankContainer>
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
        <DarkFooter />
      </div>
    </BlankContainer>
  )
}

LayoutWithFooter.propTypes = {
  children: PropTypes.node,
  showDeleteLink: PropTypes.bool
}

export default LayoutWithFooter
