import React from 'react'
import PaymentIcon from 'components/purchase/PaymentIcon'
import { Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formatDateTime } from 'app/helpers/formatDateTime'
import classNames from 'classnames'
import { compose } from 'redux'

import CatImage from './_assets/cat.svg'
import './AvoidDoublePayment.scss'
import { PAYMENT_CARD_TYPES } from './constants'

const mapStateToProps = ({ nfc }) => {
  const { activeSessions } = nfc
  return { activeSessions }
}

const AvoidDoublePayment = ({ activeSessions, t, match, history }) => {
  const formatDate = (dateString) => formatDateTime(dateString).toFormat('DD')
  const formatTime = (dateString) => formatDateTime(dateString).toFormat('t')
  const formatPrice = (priceFloat) => `${parseFloat(priceFloat).toFixed(2)}`
  const isFree = (priceFloat) => parseFloat(priceFloat) === 0
  const redirectToViewReceipt = (hashid) => history.push(`/p/session/${hashid}`)
  const redirectToPurchaseAnotherSpot = () => {
    const { params } = match
    history.push(`/p/${params.assetTag}`)
  }

  const paymentDetailClasses = classNames({
    'AvoidDoublePayment--horizontalScroll': true,
    'AvoidDoublePayment--horizontalScrollSingle': activeSessions.length === 1
  })

  return (
    <div className="AvoidDoublePayment--page">
      <div className="AvoidDoublePayment--catImage">
        <img className="" src={CatImage} />
      </div>
      <div className="AvoidDoublePayment--alreadyPaid">
        {t('nfc.avoidDoublePayment.alreadyPaid')}
      </div>
      <div className="AvoidDoublePayment--recentlyPurchased">
        {t('nfc.avoidDoublePayment.recentlyPurchased')}
      </div>
      <div className={paymentDetailClasses}>
        {activeSessions.map((val) => {
          return (
            <div className="AvoidDoublePayment--paymentDetail" key={val.hashid}>
              <div className="AvoidDoublePayment--paymentDateTimeRow">
                <div className="AvoidDoublePayment--paymentDetailColumn">
                  <div className="AvoidDoublePayment--heading">
                    {t('nfc.avoidDoublePayment.startTime')}
                  </div>
                  <div className="AvoidDoublePayment--details">
                    {formatTime(val.startTime)}
                  </div>
                  <div className="AvoidDoublePayment--details">
                    {formatDate(val.startTime)}
                  </div>
                </div>
                <div className="AvoidDoublePayment--paymentDetailColumn">
                  <div className="AvoidDoublePayment--heading">
                    {t('nfc.avoidDoublePayment.endTime')}
                  </div>
                  <div className="AvoidDoublePayment--details">
                    {formatTime(val.expiry)}
                  </div>
                  <div className="AvoidDoublePayment--details">
                    {formatDate(val.expiry)}
                  </div>
                </div>
              </div>
              <div className="AvoidDoublePayment--paymentDetailRow">
                <div className="AvoidDoublePayment--paymentDetailColumn">
                  <div className="AvoidDoublePayment--heading">
                    {t('nfc.avoidDoublePayment.payment')}
                  </div>
                  <div className="AvoidDoublePayment--details">
                    {formatPrice(val.price)}
                  </div>
                  { val.cardType && !isFree(val.price) && (
                    <div className="AvoidDoublePayment--details AvoidDoublePayment--card">
                      <PaymentIcon
                        className="AvoidDoublePayment--image"
                        cardType={val.cardType}
                      />
                      {`${PAYMENT_CARD_TYPES[val.cardType]} ${
                        val.lastFour || ''
                      }`}
                    </div>
                  )}
                </div>
                <div className="AvoidDoublePayment--paymentDetailColumn">
                  <div className="AvoidDoublePayment--heading">
                    {t('nfc.avoidDoublePayment.vehiclePlate')}
                  </div>
                  <div className="AvoidDoublePayment--details">
                    {val.plateNumber}
                  </div>
                </div>
              </div>
              <Button
                className="AvoidDoublePayment--button"
                primary
                fluid
                onClick={() => redirectToViewReceipt(val.hashid)}
              >
                {t('nfc.avoidDoublePayment.viewReceipt')}
              </Button>
            </div>
          )
        })}
      </div>
      <div className="AvoidDoublePayment--purchaseButtonDiv">
        <Button
          className="AvoidDoublePayment--purchaseButton"
          primary
          fluid
          basic
          onClick={redirectToPurchaseAnotherSpot}
        >
          {t('nfc.avoidDoublePayment.purchaseAnotherSpot')}
        </Button>
      </div>
    </div>
  )
}

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(AvoidDoublePayment)

AvoidDoublePayment.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetTag: PropTypes.string
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  activeSessions: PropTypes.array
}
