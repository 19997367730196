import { useRef, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import makeAsyncScriptLoader from 'react-async-script'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import * as NFCActions from 'redux/actions'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isiOS } from 'app/helpers/device'
import { AUTH_TYPES } from 'components/tto/constants'
import PurchaseButton from './PurchaseButton'

const GOOGLE_PAY_URL = 'https://pay.google.com/gp/p/js/pay.js'
const GOOGLE_PAY_ENV = ENV.PRODUCTION ? 'PRODUCTION' : 'TEST'

import './GooglePayPurchaseButton.scss'

const formatAddress = (address = {}) => address.street

const mapStateToProps = ({ nfc }) => {
  const { assetTag, merchantInfo, zone } = nfc

  // Provided by google, not our MerchantInfo.id
  // see https://developers.google.com/pay/api/web/guides/tutorial#tokenization,
  // In TEST env, it works with `undefined` as a merchantId, but it's
  // null in the serialized response, which doesn't work.
  // In PRODUCTION, you'll definitely need a real key either way.
  const merchantId =
    get(merchantInfo, ['google_pay_gateway', 'merchant_id']) || undefined

  return {
    zone,
    assetTag,
    merchantId,
    isSupported: get(merchantInfo, ['google_pay_gateway', 'supported']),
    merchantName: get(merchantInfo, ['google_pay_gateway', 'display_name']),
    gatewayName: get(merchantInfo, ['google_pay_gateway', 'gateway']),
    gatewayMerchantId: get(merchantInfo, [
      'google_pay_gateway',
      'gateway_merchant_id'
    ]) // see https://developers.google.com/pay/api/web/guides/tutorial#tokenization
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0
}

const GooglePayPurchaseButton = ({
  google,
  merchantName,
  merchantId,
  gatewayName,
  gatewayMerchantId,
  zone: { address, currency_code: currencyCode },
  isSupported,
  disabled,
  assetTag,
  onSuccess,
  startTtoSession
}) => {
  if (isiOS()) {
    return null
  }
  if (!isSupported) {
    return null
  }

  // For holding one client across multiple renders
  const paymentsClient = useRef(null)
  // For holding DOM reference to attach the client button
  const buttonRef = useRef(null)
  // For holding variables outside of function scope for Google Pay callbacks

  const allowedCardNetworks =
    currencyCode === 'USD'
      ? ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA']
      : ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA', 'INTERAC']

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedCardNetworks,
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      billingAddressRequired: true,
      billingAddressParameters: { format: 'MIN' }
    }
  }

  const cardPaymentMethod = {
    ...baseCardPaymentMethod,
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: { gateway: gatewayName, gatewayMerchantId }
    }
  }

  const handlePayment = (paymentData) => {
    return new Promise((resolve) => {
      startTtoSession(paymentData, AUTH_TYPES.google_pay_auth)
        .then(({ data }) => {
          const {
            startTtoSession: { errors }
          } = data
          if (isEmpty(errors)) {
            resolve({ transactionState: 'SUCCESS' })
            onSuccess()
          } else {
            // Success just means "close the pay sheet without displaying errors in there"
            // Because we handle the error display ourselves
            resolve({ transactionState: 'SUCCESS' })
          }
          return data
        })
        .catch(() => {
          // TODO - handle catastrophic failure
        })
    })
  }

  const openGooglePay = () => {
    const countryCode = currencyCode === 'USD' ? 'US' : 'CA'
    const paymentDataRequest = {
      ...BASE_REQUEST,
      emailRequired: true,
      merchantInfo: {
        merchantName: `${merchantName} - ${formatAddress(address)}`,
        merchantId
      },
      allowedPaymentMethods: [cardPaymentMethod],
      transactionInfo: {
        // NOT_CURRENTLY_KNOWN | ESTIMATED | FINAL
        // https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo
        totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
        currencyCode,
        countryCode
      }
    }
    const analyticsData = {
      // is_extension: !!extendId,
      asset_tag: assetTag
    }

    trackEvent('Google Pay Purchase Started', analyticsData)
    paymentsClient.current
      .loadPaymentData(paymentDataRequest)
      .then(handlePayment)
      .catch(() => {
        // Because the user closing the pay sheet manually
        // is considered an error
      })
  }

  const handleClick = () => {
    if (!disabled) {
      openGooglePay()
    }
  }

  const debouncedHandleClick = useCallback(
    debounce(handleClick, 500, { leading: true, trailing: false }),
    [disabled]
  )

  const isGooglePayLoaded = google && google.payments
  useEffect(() => {
    if (isGooglePayLoaded) {
      paymentsClient.current = new google.payments.api.PaymentsClient({
        environment: GOOGLE_PAY_ENV
      })

      const isReadyToPayRequest = {
        ...BASE_REQUEST,
        allowedPaymentMethods: [baseCardPaymentMethod]
      }

      paymentsClient.current
        .isReadyToPay(isReadyToPayRequest)
        .then((res) => {
          if (res.result) {
            const button = paymentsClient.current.createButton({
              buttonSizeMode: 'fill',
              buttonType: 'plain',
              // Required parameter, but a no-op here because
              // it's easier to move the click logic into React
              // and have the function prce etc. update as props change
              onClick: () => {}
            })
            buttonRef.current.appendChild(button)
          }
        })
        .catch(() => {
          //   // TODO handle errors
        })
    }
  }, [isGooglePayLoaded])

  // Optional, just speeds things up a little once there's enough
  // cart info to fetch payment info fromm Google Pay in advance of the button click
  useEffect(() => {
    if (currencyCode && isGooglePayLoaded) {
      const prefetchPaymentDataRequest = {
        ...BASE_REQUEST,
        merchantInfo: { merchantName, merchantId },
        allowedPaymentMethods: [cardPaymentMethod],
        transactionInfo: {
          totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
          currencyCode
        }
      }

      paymentsClient.current.prefetchPaymentData(prefetchPaymentDataRequest)
    }
  }, [currencyCode, isGooglePayLoaded])

  return (
    <PurchaseButton
      onClick={debouncedHandleClick}
      className="NFCGooglePayPurchaseButton"
    >
      <div ref={buttonRef} className="NFCGooglePayPurchaseButton--container" />
    </PurchaseButton>
  )
}

GooglePayPurchaseButton.propTypes = {
  isSupported: PropTypes.bool,
  merchantName: PropTypes.string,
  merchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gatewayName: PropTypes.string,
  gatewayMerchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  google: PropTypes.shape({
    payments: PropTypes.shape({
      api: PropTypes.shape({
        PaymentsClient: PropTypes.func.isRequired
      })
    })
  }),
  zone: PropTypes.shape({
    address: PropTypes.shape({ street: PropTypes.string }),
    currency_code: PropTypes.string.isRequired
  }),
  nfcActions: PropTypes.shape({
    createParkingSessionGooglePay: PropTypes.func.isRequired
  }).isRequired,
  disabled: PropTypes.bool,
  assetTag: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  startTtoSession: PropTypes.func.isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  makeAsyncScriptLoader(GOOGLE_PAY_URL, { globalName: 'google' })
)(GooglePayPurchaseButton)
