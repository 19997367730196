import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import classNames from 'classnames'
import PurchaseButton from './PurchaseButton'

import './PayWithNewCreditCardButton.scss'

class PayWithNewCreditCardButton extends PureComponent {
  handleClick = (e) => {
    const { onClick, disabled, loading } = this.props

    if (!disabled && !loading) {
      onClick(e)
    }
  }

  renderLoader = () => {
    const { t } = this.props
    return (
      <PurchaseButton
        className="NFCPayWithNewCreditCardButton"
        loading="true"
        primary="true"
      >
        {t('nfc.processingTransaction')}
      </PurchaseButton>
    )
  }

  renderButton = () => {
    const { t, disabled, loading } = this.props

    return (
      <PurchaseButton
        className={classNames('NFCPayWithNewCreditCardButton', { disabled })}
        onClick={this.handleClick}
        disabled={disabled || loading}
      >
        {t('nfc.paymentMethods.payWithCreditCard')}
      </PurchaseButton>
    )
  }

  render() {
    const { loading } = this.props

    return (
      <div className="NFCPayWithNewCreditCardButtonWrapper">
        {loading ? this.renderLoader() : this.renderButton()}
      </div>
    )
  }
}

PayWithNewCreditCardButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default PayWithNewCreditCardButton
