import { Component } from 'react'
import { connect } from 'react-redux'
import { Dimmer } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import VgsProvider from 'components/purchase/VgsProvider'
import { PAYPAL_PURCHASE_SOURCES } from 'components/purchase/constants'

import ChooseCreditCardButton from 'components/purchase/buttons/ChooseCreditCardButton'
import DefaultCreditCardPurchaseButton from 'components/purchase/buttons/DefaultCreditCardPurchaseButton'
import ApplePayPurchaseButton from './buttons/ApplePayPurchaseButton'
import ZeroDollarPurchaseButton from './buttons/ZeroDollarPurchaseButton'
import GooglePayPurchaseButton from './buttons/GooglePayPurchaseButton'
import NavigateToCreditCardButton from './buttons/NavigateToCreditCardButton'

import './AllPurchaseButtons.scss'

const mapStateToProps = ({ nfc }) => {
  const { rates, cart, rate, account } = nfc.loaded
  return {
    creditCard: nfc.account.credit_card,
    cart: nfc.cart,
    loaded: rates && cart && rate && account,
    disabled: !(rates && cart && rate && account) || !nfc.cart.is_rate_selected
  }
}

class AllPurchaseButtons extends Component {
  renderCreditCardCreateOrDefault = () => {
    const { t, cart, disabled, creditCard, setIsCCScreenOpen } = this.props

    if (isEmpty(creditCard)) {
      return (
        <NavigateToCreditCardButton
          t={t}
          cart={cart}
          disabled={disabled}
          setIsCCScreenOpen={setIsCCScreenOpen}
        />
      )
    }

    return (
      <DefaultCreditCardPurchaseButton
        t={t}
        cart={cart}
        disabled={disabled}
        card={creditCard}
      />
    )
  }

  renderZeroDollarButton = () => {
    const { t, cart, disabled, onSuccess } = this.props

    return (
      <ZeroDollarPurchaseButton
        t={t}
        cart={cart}
        disabled={disabled}
        onSuccess={onSuccess}
      />
    )
  }

  renderNormalPurchaseButtons = () => {
    const { t, cart, disabled, creditCard, onSuccess } = this.props

    return (
      <div>
        <VgsProvider />
        <ApplePayPurchaseButton
          t={t}
          cart={cart}
          disabled={disabled}
          onSuccess={onSuccess}
        />
        {this.renderCreditCardCreateOrDefault()}
        <GooglePayPurchaseButton
          cart={cart}
          disabled={disabled}
          onSuccess={onSuccess}
        />
        {!isEmpty(creditCard) && (
          <ChooseCreditCardButton t={t} cart={cart} disabled={disabled} />
        )}
      </div>
    )
  }

  render() {
    const {
      disabled,
      cart: { is_zero_dollar_purchase: isZeroDollar }
    } = this.props

    return (
      <Dimmer.Dimmable className="AllPurchaseButtons--dimmable">
        <Dimmer
          inverted
          active={disabled}
          className="AllPurchaseButtons--dimmer"
        />
        {isZeroDollar
          ? this.renderZeroDollarButton()
          : this.renderNormalPurchaseButtons()}
      </Dimmer.Dimmable>
    )
  }
}

AllPurchaseButtons.propTypes = {
  t: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
  disabled: PropTypes.bool,
  cart: PropTypes.shape({
    is_zero_dollar_purchase: PropTypes.bool
  }),
  creditCard: PropTypes.shape({}),
  purchaseFlow: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  source: PropTypes.oneOf(values(PAYPAL_PURCHASE_SOURCES)),
  setIsCCScreenOpen: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(AllPurchaseButtons)
