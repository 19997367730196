import classNames from "classnames"
import PropTypes from 'prop-types'
import { Button as SemanticButton } from 'semantic-ui-react'

import './Button.scss'

const Button = ({
  className,
  onClick,
  children,
  type = 'button',
  loading = false,
  disabled = false,
  primary = true,
  link
}) => {
  const buttonClassName = classNames('Button--container', className, {
    'Button--container__disabled': disabled || loading,
    primary: primary && !link,
    link
  })

  return (
    <SemanticButton
      className={buttonClassName}
      onClick={onClick}
      type={type}
      loading={loading}
      disabled={disabled || loading}
    >
      {children}
    </SemanticButton>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node || PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  link: PropTypes.bool
}

export default Button
