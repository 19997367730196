import { Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './ErrorMessage.scss'

const ErrorMessage = ({
  className,
  content,
  visible,
  icon = 'exclamation triangle'
}) => {
  return (
    <Message
      className={classNames('ErrorMessage', className, { hidden: !visible })}
      icon={icon}
      content={content}
    />
  )
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  icon: PropTypes.string
}

export default ErrorMessage
