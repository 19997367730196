import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { accountSelector, loadSelector } from 'redux/reducers'
import { updateAccount } from 'redux/actions'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { formatPhoneNumber, parsePhoneNumber } from 'app/helpers/phoneNumber'
import { redirectToSession } from 'components/purchase/helpers'
import Input from 'components/common/Input'
import Button from 'components/common/Button'
import AccountLoader from 'components/loaders/AccountLoader'
import ParkingSessionLoader from 'components/loaders/ParkingSessionLoader'
import BellImage from './_assets/bell.svg'

import styles from './SmsNotificationRoute.module.scss'

const SmsNotificationRoute = () => {
  const { sessionId: parkingSessionId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const accountSession = useSelector(accountSelector)
  const loadSession = useSelector(loadSelector)

  const { mobile_phone_number: accountPhoneNumber } = accountSession
  const [phoneNumber, setPhoneNumber] = useState(accountPhoneNumber)
  const { account: accountLoaded, parkingSession: sessionLoaded } = loadSession

  useEffect(() => {
    if (accountLoaded && !isEmpty(accountPhoneNumber)) {
      setPhoneNumber(accountPhoneNumber)
    }
  }, [loadSession])

  const handleInputChange = (e) => {
    setErrorMessage('')
    setPhoneNumber(parsePhoneNumber(e.target.value))
  }

  const handleCancel = () => {
    redirectToSession(parkingSessionId)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    trackEvent('SMS Update Button Pressed')

    if (isEmpty(phoneNumber)) {
      setErrorMessage(t('nfc.smsNotification.formIncompleteError'))
      setLoading(false)
      return
    }

    if (accountLoaded) {
      dispatch(updateAccount({ mobilePhoneNumber: phoneNumber }))
        .then(() => {
          return redirectToSession(parkingSessionId)})
        .catch(() => {
          setLoading(false)
          setErrorMessage(t('nfc.smsNotification.genericError'))
        })
    }
  }

  if (loading) {
    return (
      <Loader active size="large">
        {t('nfc.smsNotification.saving')}
      </Loader>
    )
  }

  if (!sessionLoaded || !accountLoaded) {
    return (
      <>
        <ParkingSessionLoader sessionId={parkingSessionId} />
        <AccountLoader />
        <Loader active size="large">
          {t('nfc.smsNotification.loading')}
        </Loader>
      </>
    )
  }

  return (
    <div className={styles.page}>
      <div className={styles.imageContainer}>
        <img
          src={BellImage}
          alt="Illustration of bell in blue circular background"
        />
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <section className={styles.main}>
          <h1 className={styles.heading}>{t('nfc.smsNotification.header')}</h1>
          <p className={styles.instruction}>{t('nfc.smsNotification.instruction')}</p>
          <Input
            type="tel"
            value={formatPhoneNumber(phoneNumber)}
            placeholder={t('nfc.smsNotification.mobilePlaceholder')}
            onChange={handleInputChange}
            error={!isEmpty(errorMessage)}
            errorMessage={errorMessage}
          />
        </section>
        <Button type="submit" loading={loading}>
          {t('nfc.smsNotification.submit')}
        </Button>
      </form>
      <div className={styles.buttonWrapper}>
        <Button onClick={handleCancel} link loading={loading}>
          {t('nfc.smsNotification.cancel')}
        </Button>
      </div>
    </div>
  )
}

export default SmsNotificationRoute
