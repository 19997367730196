/* global VGS_VAULT_ID VGS_VAULT_ENV VGS_VAULT_CNAME */
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Icon, Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import mapValues from 'lodash/mapValues'
import indexOf from 'lodash/indexOf'
import { useMutation } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { trackEvent } from 'app/helpers/analyticsHelpers'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import * as NFCActions from 'redux/actions'
import AccountLoader from 'components/tto/loaders/graphql/AccountLoader'
import AssetTagLoader from 'components/tto/loaders/graphql/AssetTagLoader'
import Header from 'components/tto/layout/Header'
import TTOForm from 'components/tto/shared/TTOForm'
import PayWithNewCreditCardButton from 'components/tto/AuthorizationRoute/purchase/buttons/PayWithNewCreditCardButton'
import { AUTH_TYPES } from 'components/tto/constants'
import { StartTtoSession } from './operations'
import CvvModal from './CvvModal'
import TTOShowErrorComponent from './TTOShowErrorComponent'
import CardErrorModal from '../shared/Modal'
import vgsCss from './VgsInputCss'
import { paymentTypeToIcon } from './helpers'

import './CreditCardPurchaseRoute.scss'
import styles from './CreditCardPurchaseRoute.module.scss'

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

const CreditCardPurchaseRoute = ({ nfcActions }) => {
  const [t] = useTranslation()
  const history = useHistory()
  const { assetTag } = useParams()
  const { account, zone } = useSelector((state) => state.nfc)
  const [showCvvModal, setShowCvvModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [validForm, setValidForm] = useState(false)
  const [cvcValid, setCvcValid] = useState(true)
  const [numberValid, setNumberValid] = useState(true)
  const [expiryValid, setExpiryValid] = useState(true)
  const [postalValid, setPostalValid] = useState(true)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [modalError, setModalError] = useState({
    title: t('nfc.tto.errors.general_title'),
    message: t('nfc.tto.errors.general_message'),
    buttonText: t('nfc.tto.errors.general_button')
  })
  const [form, setForm] = useState(null)
  const { state: routerState } = useLocation()
  const barcode = get(routerState, 'barcode', null)
  const emailAddress = get(routerState, 'emailAddress', null)

  const [startTtoSession] = useMutation(StartTtoSession, {
    onCompleted: (data) => {
      const {
        startTtoSession: { errors }
      } = data
      if (!isEmpty(errors)) {
        setIsErrorModalOpen(true)
        setModalError({
          title: t(`nfc.tto.errors.${errors[0]}_title`),
          message: t(`nfc.tto.errors.${errors[0]}_message`),
          buttonText: t(`nfc.tto.errors.${errors[0]}_button`)
        })
      } else {
        history.push('/t/success')
      }
    },
    onError: () => {
      setIsErrorModalOpen(true)
      setModalError({
        title: t('nfc.tto.errors.general_title'),
        message: t('nfc.tto.errors.general_message'),
        buttonText: t('nfc.tto.errors.general_button')
      })
    }
  })

  useEffect(() => {
    const _form = window.VGSCollect.create(
      VGS_VAULT_ID,
      VGS_VAULT_ENV,
      (state) => {
        const valids = []
        mapValues(state, (field) => {
          if (
            field.isDirty === true &&
            field.isValid === false &&
            field.isFocused === false &&
            field.isEmpty === false
          ) {
            valids.push(field.isValid)
            // Error msg for each field
            if (field.name === 'number') {
              setNumberValid(false)
            }
            if (field.name === 'cvc') {
              setCvcValid(false)
            }
            if (field.name === 'expiry') {
              setExpiryValid(false)
            }
            if (field.name === 'avs_code') {
              setPostalValid(false)
            }
          } else {
            // Clear error msg for each field
            if (field.name === 'number') {
              setNumberValid(true)
            }
            if (field.name === 'cvc') {
              setCvcValid(true)
            }
            if (field.name === 'expiry') {
              setExpiryValid(true)
            }
            if (field.name === 'avs_code') {
              setPostalValid(true)
            }
          }
        })
        if (indexOf(valids, false) === -1) {
          nfcActions.clearErrors()
          setValidForm(true)
        }
      }
    )
    const css = vgsCss

    if (VGS_VAULT_ENV === 'live') {
      _form.useCname(VGS_VAULT_CNAME)
    }

    _form.field('#cc-number', {
      type: 'card-number',
      name: 'number',
      successColor: '#0FA844',
      errorColor: '#DA1818',
      validations: ['required', 'validCardNumber'],
      css
    })

    _form.field('#cc-expiration-date', {
      type: 'card-expiration-date',
      name: 'expiry',
      validations: ['required', 'validCardExpirationDate'],
      yearLength: 2,
      placeholder: 'MM/YY',
      css
    })

    _form.field('#cc-cvc', {
      type: 'card-security-code',
      name: 'cvc',
      validations: ['required', 'validCardSecurityCode'],
      css
    })

    _form.field('#cc-postal', {
      type: 'text',
      name: 'avs_code',
      // Uncomment below once we decide to require/validate postal code
      // validations: ['required', 'postal_code/us,ca'],
      autoComplete: 'shipping postal-code',
      css
    })
    setForm(_form)

    return () => {
      setForm(null)
    }
  }, [])

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
    setIsSubmitting(false)
  }

  const handleStartTtoSession = (response) => {
    startTtoSession({
      variables: {
        input: {
          payload: response,
          zoneHashid: zone.hashid,
          barcode,
          purchaseType: AUTH_TYPES.credit_card_auth,
          emailAddress
        }
      }
    })
  }

  const purchase = () => {
    const { hashid } = account
    const { hideProcessingTransactionLoader, addError } = nfcActions
    new Promise((resolve) => {
      form.submit(
        '/v4/credit_cards/encrypt',
        {
          data: { uniq_identifier: hashid }
        },
        (_, response) => {
          if (response.ok) {
            resolve(response)
          } else {
            addError('bad_cc_form')
            setIsSubmitting(false)
            hideProcessingTransactionLoader()
          }
        }
      )
    }).then((response) => {
      if (response.ok) {
        handleStartTtoSession(response.data)
        hideProcessingTransactionLoader()
      }
    })
  }

  const submit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const {
      showProcessingTransactionLoader,
      hideProcessingTransactionLoader,
      addError,
      clearErrors
    } = nfcActions
    const analyticsData = {
      asset_tag: assetTag
    }
    clearErrors()
    if (!validForm) {
      addError('bad_cc_form')
      setIsSubmitting(false)
      return
    }
    trackEvent('Credit Card Purchase-With-Capture Clicked', analyticsData)

    try {
      showProcessingTransactionLoader()
      purchase()
    } catch {
      hideProcessingTransactionLoader()
    }
  }

  const handleSubmit = debounce(submit, 500, {
    leading: true,
    trailing: false
  })

  return (
    <div className={styles.page}>
      <AccountLoader />
      <AssetTagLoader name={assetTag} />
      <ProcessingLoader t={t} />
      <Header
        backNav
        onBackClick={() =>
          history.push({
            pathname: `/t/${assetTag}/authorization`,
            state: { barcode, emailAddress }
          })
        }
      />

      <h1 className={styles.title}>{t('nfc.creditCardPayment.creditCard')}</h1>
      <p className={styles.instruction}>
        {t('nfc.creditCardPayment.enterCard')}
      </p>
      <div className={styles.brandWrapper}>
        {['MC', 'DI', 'VI', 'AMEX'].map((cardType) => (
          <img
            className={styles.cardBrand}
            src={paymentTypeToIcon(cardType)}
            key={cardType}
          />
        ))}
      </div>

      <div className={styles.ccForm}>
        <TTOShowErrorComponent t={t} />
        <div id="cc-number" className="CreditCardPurchaseRoute--VGS-input">
          <div className="TTOForm--label">
            {' '}
            {t('nfc.creditCardPayment.cardNumberLabel')}
          </div>
          <div className="form-control-static">
            <span className="fake-input" />
          </div>
          <TTOForm.ErrorLabel visible={!numberValid}>
            {t('nfc.creditCardPayment.cardNumberInvalid')}
          </TTOForm.ErrorLabel>
        </div>
        <Grid columns={2}>
          <Grid.Column>
            <div
              id="cc-expiration-date"
              className="CreditCardPurchaseRoute--VGS-input"
            >
              <div className="TTOForm--label">
                {' '}
                {t('nfc.creditCardPayment.expirationLabel')}
              </div>
              <div className="form-control-static">
                <span />
              </div>
              <TTOForm.ErrorLabel visible={!expiryValid}>
                {t('nfc.creditCardPayment.expiryInvalid')}
              </TTOForm.ErrorLabel>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div id="cc-cvc" className="CreditCardPurchaseRoute--VGS-input">
              <div className="TTOForm--label">
                {' '}
                {t('nfc.creditCardPayment.cvvLabel')} &nbsp;
                <Icon
                  name="question circle"
                  onClick={() => setShowCvvModal(true)}
                />
              </div>
              <div className="form-control-static">
                <span className="fake-input" />
              </div>
              <TTOForm.ErrorLabel visible={!cvcValid}>
                {t('nfc.creditCardPayment.cvvInvalid')}
              </TTOForm.ErrorLabel>
            </div>
          </Grid.Column>
        </Grid>

        <div id="cc-postal" className="CreditCardPurchaseRoute--VGS-input">
          <div className="TTOForm--label">
            {' '}
            {t('nfc.creditCardPayment.postalCode')}
          </div>
          <div className="form-control-static">
            <span className="fake-input" />
          </div>
          <TTOForm.ErrorLabel visible={!postalValid}>
            {t('nfc.creditCardPayment.postalInvalid')}
          </TTOForm.ErrorLabel>
        </div>
      </div>
      <PayWithNewCreditCardButton
        t={t}
        onClick={handleSubmit}
        disabled={isSubmitting}
      />
      <CvvModal
        t={t}
        isOpen={showCvvModal}
        onClose={() => setShowCvvModal(false)}
      />
      <CardErrorModal
        open={isErrorModalOpen}
        content={modalError}
        onClose={closeErrorModal}
      />
    </div>
  )
}

CreditCardPurchaseRoute.propTypes = {
  t: PropTypes.func,
  nfcActions: PropTypes.shape({
    authorizeCreditCardPayment: PropTypes.func,
    createParkingSessionTransactionId: PropTypes.func,
    requestPaymentToken: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    addError: PropTypes.func.isRequired,
    showProcessingTransactionLoader: PropTypes.func.isRequired,
    hideProcessingTransactionLoader: PropTypes.func.isRequired
  }),
  assetTag: PropTypes.string,
  account: PropTypes.shape({
    hashid: PropTypes.string
  })
}

export default compose(connect(null, mapDispatchToProps))(
  CreditCardPurchaseRoute
)
