import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'
import FallbackLogo from './_assets/logo_operator_fallback.svg'
import FallbackImage from './_assets/zone_fallback.svg'

import styles from './Banner.module.scss'

const Banner = ({
  zoneImage,
  logo,
  properName,
  street,
  zoneId
}) => {
  const { t } = useTranslation()
  
  const operatorLogo = isNil(logo) ? FallbackLogo : logo
  const bannerZoneImage = isNil(zoneImage) ? FallbackImage : zoneImage
  const backgroundStyle = {backgroundImage: `linear-gradient(to top, rgba(43,43,43,0.75), rgba(43,43,43,0)), url(${bannerZoneImage})`}

  return (
    <div className={styles.banner} style={ backgroundStyle }>
      <div className={styles.zoneInfoContainer}>
        <div className={styles.logo} style={{backgroundImage: `url(${operatorLogo})`}}
          role="img"
          aria-label="Operator logo"
        />
        <div className={styles.zoneInfo}>
          <p className={styles.operatorName}>{properName}</p>
          <div className={styles.addressZoneId}>
            {street && 
              <>
                <p className={styles.address}>{street}</p>
                <span>•</span>
              </>
            }
            <p className={styles.zone}>{t('nfc.tto.zone', { zoneId })}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Banner.propTypes = {
  zoneImage: PropTypes.string,
  logo: PropTypes.string,
  properName: PropTypes.string,
  street: PropTypes.string,
  zoneId: PropTypes.string
}

export default Banner
