export const AMERICAN_EXPRESS = 'Amex'
export const DISCOVER = 'Discover'
export const MASTERCARD = 'MasterCard'
export const VISA = 'Visa'
export const GENERIC = 'Generic'

export const PAYMENT_CARD_TYPES = {
  DI: DISCOVER,
  MC: MASTERCARD,
  VI: VISA,
  AMEX: AMERICAN_EXPRESS,
  GENERIC
}

export const ERROR_TYPES = {
  blacklisted: 'blacklisted',
  cart_was_used: 'cart_was_used',
  over_capacity: 'over_capacity'
}
