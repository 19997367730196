import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as NFCActions from 'redux/actions'
import isEmpty from 'lodash/isEmpty'
import { Message } from 'semantic-ui-react'
import { ERROR_TYPES } from 'components/constants'

import './NFCShowErrorComponent.scss'

const mapStateToProps = ({ nfc }) => ({
  error: nfc.error,
  assetTag: nfc.assetTag
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class NFCShowErrorComponent extends Component {
  componentDidMount() {
    const {
      nfcActions: { clearErrors },
      preserveErrorsOnMount
    } = this.props
    if (!preserveErrorsOnMount) {
      // unless otherwise specified,
      // we clear all the errors at mount
      // so we don't show the old ones
      clearErrors()
    }
  }

  errorMessage = () => {
    const { error, assetTag, t } = this.props

    if (error === ERROR_TYPES.cart_was_used) {
      const msg = t('nfc.zone.errors.cart_was_used', { url: `/p/${assetTag}` })
      return <div dangerouslySetInnerHTML={{ __html: msg }} />
    }

    return t(`nfc.zone.errors.${error}`)
  }

  render() {
    const { error, className } = this.props
    if (isEmpty(error)) {
      return null
    }

    return (
      <Message
        className={classNames('NFCShowErrorComponent-message', className)}
        icon="exclamation triangle"
        content={this.errorMessage()}
      />
    )
  }
}

NFCShowErrorComponent.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  assetTag: PropTypes.string,
  preserveErrorsOnMount: PropTypes.bool,
  nfcActions: PropTypes.shape({
    clearErrors: PropTypes.func
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NFCShowErrorComponent)
