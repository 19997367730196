import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { bindActionCreators } from 'redux'
import { Dropdown } from 'semantic-ui-react'
import ComponentLoader from 'components/ComponentLoader'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { formatPrice } from 'app/helpers/formatting'
import { isSmallScreen } from 'app/helpers/device'
import * as AssetTagActions from 'redux/actions'
import RateSelectComponent from 'components/purchase/RateSelectComponent'
import DownArrowIcon from './_assets/icon-arrow-down.svg'

import './RateDropDown.scss'

const mapStateToProps = (state) => {
  const { cart, rates, loaded, selectedRateId } = state.nfc

  return {
    cart,
    rates,
    loaded,
    selectedRate: find(rates, { id: selectedRateId })
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class RateDropDown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isRateSelectorOpen: false
    }
    this.dropdown = React.createRef()
    this.MAX_RATES_UNTIL_SIDE_BAR = 6
  }

  componentDidUpdate(prevProps) {
    const { loaded } = this.props
    if (!prevProps.loaded.rates && loaded.rates && this.dropdown.current) {
      this.dropdown.current.open()
    }
  }

  openRateSelector = () => {
    this.setState({ isRateSelectorOpen: true })
  }

  closeRateSelector = () => {
    this.setState({ isRateSelectorOpen: false })
  }

  selectRate = (event, data) => {
    const { assetTagActions, cart, loaded } = this.props
    if (loaded.rate) {
      const analyticsData = { rate_id: data.value }
      trackEvent('Rate Selected', analyticsData)
      assetTagActions.setRate(cart.id, data.value)
    }
  }

  dropdownText = (rate) => {
    const { loaded, t } = this.props

    if (!loaded.rate) {
      return t('nfc.zone.loadingRates')
    }
    if (!rate) {
      return t('nfc.zone.selectRate')
    }

    return rate.description
  }

  dropdownValue = (rate) => (rate ? rate.id : null)

  renderItems(rates, selectedRate) {
    return rates.map((rate) => ({
      text: (
        <p>
          <span className="RateDropdown--text--description">
            {rate.description}
          </span>
          <span className="RateDropdown--text--price">
            ${formatPrice(rate.price)}
          </span>
        </p>
      ),
      value: rate.id,
      selected: selectedRate && selectedRate.id === rate.id
    }))
  }

  dropdownIcon = () => (
    <img src={DownArrowIcon} alt="down arrow" className="RateDropdown--icon" />
  )

  renderDropdown = () => {
    const { rates, loaded, selectedRate } = this.props

    return (
      <Dropdown
        className={classNames('NFCZoneRoute--dropdown', {
          rateSelected: !isEmpty(selectedRate) && loaded.rate
        })}
        loading={!loaded.rate}
        disabled={!loaded.rate}
        value={this.dropdownValue(selectedRate)}
        text={this.dropdownText(selectedRate)}
        options={this.renderItems(rates, selectedRate)}
        onChange={this.selectRate}
        scrolling={rates.length > this.MAX_RATES_UNTIL_SIDE_BAR}
        icon={this.dropdownIcon()}
      />
    )
  }

  render() {
    const { rates, selectedRate, loaded, cart } = this.props
    const { isRateSelectorOpen } = this.state
    // The RateSelector will only open for screens that are less than 500px
    // All other screens will see the dropdown instead.
    // If there are more than MAX_RATES_UNTIL_SIDE_BAR rates (i.e., 6), there will be a scroll bar

    return (
      <div className="RateDropdown--container">
        <ComponentLoader
          active={!loaded.rates || !loaded.rate}
          inverted={false}
          inline="centered"
        >
          {rates.length > this.MAX_RATES_UNTIL_SIDE_BAR && isSmallScreen() ? (
            <>
              <button
                type="button"
                className={classNames('RateSelectButton', {
                  rateSelected: !isEmpty(selectedRate) && loaded.rate
                })}
                onClick={this.openRateSelector}
              >
                <span className="RateSelectButton--text">{this.dropdownText(selectedRate)}</span>
                <span className="RateSelectButton--icon">{this.dropdownIcon()}</span>
              </button>
              <RateSelectComponent
                isOpen={isRateSelectorOpen}
                onClose={this.closeRateSelector}
                rates={rates}
                selectedRate={selectedRate}
                cartId={cart.id}
              />
            </>
          ) : (
            this.renderDropdown()
          )}
        </ComponentLoader>
      </div>
    )
  }
}

RateDropDown.propTypes = {
  t: PropTypes.func.isRequired,
  rates: PropTypes.arrayOf(PropTypes.object).isRequired,
  cart: PropTypes.shape({
    id: PropTypes.number
  }),
  selectedRate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired
  }),
  loaded: PropTypes.shape({
    rates: PropTypes.bool.isRequired,
    rate: PropTypes.bool.isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    setRate: PropTypes.func.isRequired
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(RateDropDown)
