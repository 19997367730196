import classnames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import './PurchaseButton.scss'

class PurchaseButton extends PureComponent {
  render() {
    const { children, className, disabled, onClick } = this.props
    return (
      <button
        type="button"
        onClick={onClick}
        className={classnames('NFCPurchaseButton', className)}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        {children}
      </button>
    )
  }
}

PurchaseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default PurchaseButton
