import { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import Input from 'components/common/Input'
import Button from 'components/common/Button'
import ErrorMessage from 'components/common/ErrorMessage'

import { isRequestSuccessful } from 'app/helpers/api'

import { apiGET as guestApiGET } from 'app/helpers/guestApi'

import './PickerZoneInputComponent.scss'

const PickerZoneInputComponent = ({ zone, handleClick }) => {
  const [t] = useTranslation()
  const [key, setKey] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const { zone_id: zoneId, zone_option_key_label: label } = zone

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    guestApiGET(`zone_options/${zoneId}`, { key })
      .then((json) => {
        if (isRequestSuccessful(json)) {
          handleClick(json.data.option)
        } else {
          const msg = json.errors
          setErrorMsg(t(`nfc.errors.${msg}`))
        }
      })
      .then(() => {
        setLoading(false)
      })
  }

  const handleChange = (e) => {
    setErrorMsg('')
    setKey(e.target.value)
  }

  return (
    <form onSubmit={handleSubmit} className="PickerZoneInputComponent--form">
      <Input
        fluid
        onChange={handleChange}
        className="PickerZoneInputComponent--input"
        autoFocus
        error={!isEmpty(errorMsg)}
        loading={loading}
        placeholder={t('nfc.zoneOptions.placeholder', {
          label: label?.toLowerCase()
        })}
      />
      <ErrorMessage
        visible={!isEmpty(errorMsg)}
        content={errorMsg}
        className="PickerZoneInputComponent--error"
      />

      <Button type="submit" loading={loading}>
        {t('nfc.zoneOptions.continue')}
      </Button>
    </form>
  )
}

PickerZoneInputComponent.propTypes = {
  zone: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default PickerZoneInputComponent
