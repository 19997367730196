import history from 'app/history'
import {
  AMERICAN_EXPRESS,
  DISCOVER,
  MASTERCARD,
  VISA,
  PAYPAL,
  PAYMENT_CARD_TYPES,
  ONE_CARD,
  APPLE_PAY,
  GOOGLE_PAY,
  PAYROLL_DEDUCTION,
  INTERAC,
  GENERIC
} from 'components/constants'

export const redirectToSession = (sessionId) => {
  history.push(`/p/session/${sessionId}`)
}

export const redirectToReceiptReminder = (sessionId) => {
  history.push(`/p/session/${sessionId}/send-receipt`)
}

export const cardImages = {
  [AMERICAN_EXPRESS]: require('./_assets/cards-color_amex.svg'),
  [DISCOVER]: require('./_assets/cards-color_discover.svg'),
  [MASTERCARD]: require('./_assets/cards-color_mastercard.svg'),
  [VISA]: require('./_assets/cards-color_visa.svg'),
  [PAYPAL]: require('./_assets/paypal-add.svg'),
  [ONE_CARD]: require('./_assets/cards-color_one_card.svg'),
  [APPLE_PAY]: require('./_assets/cards-color_apple_pay.svg'),
  [PAYROLL_DEDUCTION]: require('./_assets/payroll-icon.svg'),
  [INTERAC]: require('./_assets/color_interac.svg'),
  [GOOGLE_PAY]: require('./_assets/google-pay-mark.svg'),
  [GENERIC]: require('./_assets/cards-generic-credit-card.svg')
}

export function paymentTypeToIcon(cardType) {
  const icon = cardImages[PAYMENT_CARD_TYPES[cardType]]
  return icon || cardImages[GENERIC]
}
