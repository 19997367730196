import { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import { Formik } from 'formik'
import { Loader, Dimmer, Transition } from 'semantic-ui-react'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isRequestSuccessful } from 'app/helpers/api'
import RateDropDown from 'components/purchase/RateDropDown'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import ErrorScreen from 'components/ErrorScreen'
import NFCShowErrorComponent from 'components/NFCShowErrorComponent'
import WarningCardComponent from 'components/warnings/WarningCardComponent'
import SpecialRateCard from 'components/rates/SpecialRateCard'
import Banner from 'components/common/Banner'
import Input from 'components/common/Input'
import ErrorMessage from 'components/common/ErrorMessage'
import { getIsPurchaseable } from 'redux/reducers'
import {
  NFC_ZONE_ACTION_TYPES,
  NFC_ZONE_VALIDATION_TYPES,
  APPLICATION_TYPE
} from 'components/constants'
import { PURCHASE_FLOWS } from 'components/purchase/constants'

import * as AssetTagActions from 'redux/actions'

import NFCPickerZoneComponent from './NFCPickerZoneComponent'
import AccountLoader from './loaders/AccountLoader'
import RatesLoader from './loaders/RatesLoader'
import CartLoader from './loaders/CartLoader'
import VehicleLoader from './loaders/VehicleLoader'
import AssetTagLoader from './loaders/AssetTagLoader'
import AllPurchaseButtons from './purchase/AllPurchaseButtons'
import CartInformation from './purchase/CartInformation'
import ZoneDescriptionComponent from './ZoneDescriptionComponent'
import ZoneOptionLabel from './ZoneOptionLabel'
import './NFCZoneRoute.scss'

const mapStateToProps = ({ nfc }) => {
  const {
    plateNumber,
    // action_type is a field on zone, not same as redux action type
    zone: {
      action_type: actionType,
      validation_type: validationType,
      zone_id: zoneId
    },
    zoneOption,
    loaded: {
      assetTag: assetTagLoaded,
      rates: ratesLoaded,
      plateNumber: plateLoaded,
      account: accountLoaded
    },
    isAlreadyPurchasedVisit,
    cart,
    account: { email_address: emailAddress },
    allTagData: { application_type: applicationType }
  } = nfc

  return {
    assetTagLoaded,
    plateLoaded,
    ratesLoaded,
    plateNumber,
    actionType,
    validationType,
    zoneOption,
    zoneId,
    cart,
    isAlreadyPurchasedVisit,
    isUnpurchaseable: ratesLoaded && !getIsPurchaseable(nfc),
    accountLoaded,
    emailAddress,
    applicationType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class NFCZoneRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTyping: false
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { assetTag }
      }
    } = this.props

    localStorage.setItem('assetTag', assetTag)
  }

  componentDidUpdate(prevProps) {
    const {
      zoneId,
      applicationType,
      assetTagActions: { clearUserInfo }
    } = this.props

    if (
      isEmpty(prevProps.applicationType) &&
      applicationType === APPLICATION_TYPE.publicXC
    ) {
      clearUserInfo()
    }

    if (
      prevProps.zoneId !== zoneId &&
      !isEmpty(applicationType) &&
      applicationType !== APPLICATION_TYPE.publicXC
    ) {
      this.handleCheckInventory()
      this.handleActiveSession()
    }
  }

  handleActiveSession = () => {
    const {
      zoneId,
      isAlreadyPurchasedVisit,
      assetTagActions: { getActiveSession },
      match: {
        params: { assetTag }
      },
      history
    } = this.props

    if (!isAlreadyPurchasedVisit) {
      getActiveSession(zoneId).then((json) => {
        if (
          json.data &&
          json.data.parking_sessions &&
          json.data.parking_sessions.length
        ) {
          history.push(`/p/${assetTag}/already-purchased`)
        }
      })
    }
  }

  handleCheckInventory = () => {
    const {
      zoneId,
      assetTagActions: { loadZoneInventory },
      match: {
        params: { assetTag }
      },
      history
    } = this.props

    loadZoneInventory(zoneId).then((json) => {
      if (!isRequestSuccessful(json)) {
        if (json.errors && includes(json.errors, 'over_capacity')) {
          setTimeout(() => {
            history.replace(`/p/${assetTag}/full-parking-lot`)
          }, 2000)
        }
      }
    })
  }

  get isPickerZone() {
    const { actionType } = this.props
    return actionType === NFC_ZONE_ACTION_TYPES.picker
  }

  handleChange = (e) => {
    const {
      assetTagActions: { setParkingPlate }
    } = this.props
    const regex = /[^0-9a-zA-Z\s]/g
    // removes special characters
    const normalizedPlate = e.target.value.toUpperCase().replace(regex, '')
    setParkingPlate(normalizedPlate)
    this.setState({ isTyping: true })
  }

  handleFocus = () => {
    trackEvent('Plate Field Focused')
  }

  handleOnBlur = (validateForm) => {
    const {
      assetTagActions: { loadRates },
      cart,
      plateNumber
    } = this.props

    validateForm()
    loadRates(cart.id, plateNumber)
    this.setState({ isTyping: false })
  }

  handleEmail = (e) => {
    const {
      assetTagActions: { updateEmailAddress }
    } = this.props
    updateEmailAddress(e.target.value)
    this.setState({ isTyping: true })
  }

  saveEmail = (validateForm) => {
    const {
      accountLoaded,
      emailAddress,
      assetTagActions: { updateAccount, loadAccount }
    } = this.props

    validateForm()

    if (accountLoaded) {
      updateAccount({ emailAddress })
    }
    loadAccount()
    this.setState({ isTyping: false })
  }

  shouldPreserveErrorsOnMount = () => {
    const {
      location: { search }
    } = this.props
    const query = new URLSearchParams(search)
    return !!query.get('preserveErrorsOnMount')
  }

  renderSpecialRates = () => {
    const { t } = this.props

    return (
      <div className="NFCZoneRoute--specialRateBlock">
        <div className="NFCZoneRoute--specialRateBlock--Card">
          <SpecialRateCard t={t} />
        </div>
      </div>
    )
  }

  renderCard = () => {
    const { isUnpurchaseable } = this.props
    return isUnpurchaseable && !this.isPickerZone
      ? this.renderSpecialRates()
      : this.renderCardContent()
  }

  renderCardContent = () => {
    const { assetTagLoaded } = this.props
    return (
      <>
        <Transition
          visible={!assetTagLoaded}
          unmountOnHide
          animation="fade"
          duration={200}
        >
          <Dimmer page active>
            <Loader size="large" />
          </Dimmer>
        </Transition>
        {this.isPickerZone ? <NFCPickerZoneComponent /> : this.renderForm()}
      </>
    )
  }

  renderPlateDescription = () => {
    const { validationType, t } = this.props
    const payBySpace = validationType === NFC_ZONE_VALIDATION_TYPES.by_space

    return payBySpace ? (
      <label htmlFor="spaceNumber" className="NFCZoneRoute--label">
        {t('nfc.zone.spaceNumber')}
      </label>
    ) : (
      <label htmlFor="plateNumber" className="NFCZoneRoute--label">
        {t('nfc.zone.plateNumber')}
      </label>
    )
  }

  renderForm = () => {
    const { plateNumber, plateLoaded, validationType, t, emailAddress } =
      this.props
    const { isTyping } = this.state
    const payBySpace = validationType === NFC_ZONE_VALIDATION_TYPES.by_space
    const hidePlateInput = validationType === NFC_ZONE_VALIDATION_TYPES.by_zone_option_key
    const emailRequired = WHITELABEL_CONFIG.FEATURE_FLAGS.REQUIRE_EMAIL

    return (
      <Formik
        validate={() => {
          const errors = {}
          if (payBySpace && isEmpty(plateNumber.trim())) {
            errors.plateNumber = t('nfc.zone.errorEmptySpace')
          } else if (isEmpty(plateNumber.trim())) {
            errors.plateNumber = t('nfc.zone.errorEmptyPlate')
          } else if (plateNumber.trim().length > 25) {
            errors.plateNumber = t('nfc.zone.errorPlateTooLong')
          } else if (emailRequired) {
            if (isEmpty(emailAddress.trim())) {
              errors.emailAddress = t('nfc.zone.errorEmptyEmailAddress')
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)
            ) {
              errors.emailAddress = t('nfc.zone.errorInvalidEmailAddress')
            }
          }

          return errors
        }}
      >
        {({ errors, validateForm }) => (
          <div className="NFCZoneRoute--purchaseWrapper">
            <div className="NFCZoneRoute--purchase">
              <WarningCardComponent />
              <NFCShowErrorComponent
                preserveErrorsOnMount={this.shouldPreserveErrorsOnMount()}
                t={t}
                className="NFCZoneRoute--showErrorComponent"
              />
              <form className="NFCZoneRoute--nfcForm">
                {!hidePlateInput && (
                  <div className="NFCZoneRoute--nfcFormGroup">
                    {this.renderPlateDescription()}
                    <Input
                      id={payBySpace ? 'spaceNumber' : 'plateNumber'}
                      onChange={this.handleChange}
                      value={plateNumber}
                      onBlur={() => this.handleOnBlur(validateForm)}
                      onFocus={this.handleFocus}
                      loading={!plateLoaded}
                      disabled={!plateLoaded}
                      placeholder={
                        payBySpace
                          ? t('nfc.zone.spacePlaceholder')
                          : t('nfc.zone.platePlaceholder')
                      }
                      className={classNames({
                        'NFCZoneRoute--inputError':
                          !isTyping && !isEmpty(errors.plateNumber)
                      })}
                    />
                    <ErrorMessage
                      className="NFCZoneRoute--errorMessage"
                      visible={!isTyping && !isEmpty(errors.plateNumber)}
                      content={errors.plateNumber}
                    />
                  </div>
                )}
                <div className="NFCZoneRoute--nfcFormGroup">
                  <label htmlFor="rateSelect" className="NFCZoneRoute--label">
                    {t('nfc.zone.chooseRate')}
                  </label>
                  <RateDropDown
                    id="rateSelect"
                    t={t}
                    openRateSelector={this.openRateSelector}
                  />
                </div>
                {emailRequired && (
                  <div className="NFCZoneRoute--nfcFormGroup">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="email" className="NFCZoneRoute--label">
                      Email
                    </label>
                    <Input
                      id="email"
                      type="email"
                      placeholder={t('nfc.zone.emailPlaceholder')}
                      onChange={this.handleEmail}
                      value={emailAddress}
                      onBlur={() => this.saveEmail(validateForm)}
                      className={classNames({
                        'NFCZoneRoute--inputError':
                          !isTyping && !isEmpty(errors.emailAddress)
                      })}
                    />
                    <ErrorMessage
                      className="NFCZoneRoute--errorMessage"
                      visible={!isTyping && !isEmpty(errors.emailAddress)}
                      content={errors.emailAddress}
                    />
                  </div>
                )}
                <CartInformation />
              </form>
            </div>
            <div className="NFCZoneRoute--actionWrapper">
              <p className="NFCZoneRoute--terms">
                <span>{t('nfc.zone.termsCopy')}</span>
                <a
                  target="_blank"
                  href={WHITELABEL_CONFIG.TERMS_URL}
                  rel="noreferrer"
                >
                  {t('nfc.zone.termsLink')}
                </a>
              </p>
              <AllPurchaseButtons
                className="NFCZoneRoute--AllPurchaseButtons"
                purchaseFlow={PURCHASE_FLOWS.checkout}
                t={t}
              />
            </div>
          </div>
        )}
      </Formik>
    )
  }

  render() {
    const {
      t,
      match: {
        params: { assetTag }
      }
    } = this.props

    return (
      <div className="NFCZoneRoute">
        <ErrorScreen>
          {({ showErrorUi }) => (
            <AssetTagLoader name={assetTag} handleError={showErrorUi}>
              {!this.isPickerZone && (
                <>
                  <VehicleLoader />
                  <CartLoader handleError={showErrorUi}>
                    {/* Load account after cart to get the merchant info id */}
                    <AccountLoader />
                    <RatesLoader handleError={showErrorUi} />
                  </CartLoader>
                </>
              )}
            </AssetTagLoader>
          )}
        </ErrorScreen>
        <ProcessingLoader t={t} />
        <Banner />
        <ZoneOptionLabel />
        {!this.isPickerZone && <ZoneDescriptionComponent />}
        {this.renderCard()}
      </div>
    )
  }
}

NFCZoneRoute.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetTag: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired,
  zoneId: PropTypes.string,
  isAlreadyPurchasedVisit: PropTypes.bool,
  assetTagActions: PropTypes.shape({
    setParkingPlate: PropTypes.func.isRequired,
    addPromoCode: PropTypes.func.isRequired,
    getActiveSession: PropTypes.func.isRequired,
    loadZoneInventory: PropTypes.func.isRequired,
    loadRates: PropTypes.func.isRequired,
    loadAccount: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
    updateEmailAddress: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    clearUserInfo: PropTypes.func.isRequired
  }).isRequired,
  plateNumber: PropTypes.string,
  plateLoaded: PropTypes.bool,
  isUnpurchaseable: PropTypes.bool,
  actionType: PropTypes.oneOf(Object.values(NFC_ZONE_ACTION_TYPES)),
  validationType: PropTypes.oneOf(Object.values(NFC_ZONE_VALIDATION_TYPES)),
  zoneOption: PropTypes.object,
  assetTagLoaded: PropTypes.bool,
  cart: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  emailAddress: PropTypes.string,
  accountLoaded: PropTypes.bool,
  applicationType: PropTypes.string
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(NFCZoneRoute)
