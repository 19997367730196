import { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import checkmarkImg from 'components/_assets/checkmark-in-circle.svg'
import BrandedTagline from './BrandedTagline'
import DarkFooter from './layout/DarkFooter'
import './NFCLoggedOutRoute.scss'

class NFCLoggedOutRoute extends PureComponent {
  render() {
    const { t } = this.props
    return (
      <div className="NFCLoggedOutRoute">
        <div className="NFCLoggedOutRoute--content">
          <div className="NFCLoggedOutRoute--copyContainer">
            <img className="NFCLoggedOutRoute--checkmark" src={checkmarkImg} />
            <div className="NFCLoggedOutRoute--header">
              {t('nfc.goodbye.header')}
            </div>
            <div className="NFCLoggedOutRoute--copy">
              {t('nfc.goodbye.copy')}
            </div>
          </div>
          <div className="NFCLoggedOutRoute--tagline">
            <BrandedTagline t={t} />
          </div>
        </div>

        <div className="NFCLoggedOutRoute--footer">
          <DarkFooter t={t} showDeleteLink={false} />
        </div>
      </div>
    )
  }
}

NFCLoggedOutRoute.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(NFCLoggedOutRoute)
