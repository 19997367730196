import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { AssetTagByName } from 'components/tto/operations'
import isNil from 'lodash/isNil'
import { snakeCaseKeysDeep } from 'components/helpers/formatting'

import * as actionTypes from 'redux/actionTypes'

const mapStateToProps = (state) => {
  return {
    loaded: state.nfc.loaded.assetTag
  }
}

const AssetTagLoader = ({
  name,
  history,
  loaded,
  children,
  redirectUrl = '/t/errors/not-found'
}) => {
  const dispatch = useDispatch()
  useQuery(AssetTagByName, {
    variables: { name },
    onCompleted: ({ assetTagByName }) => {
      if (isNil(assetTagByName)) {
        history.replace(redirectUrl)
      } else {
        const { zone, name: assetTag, ...allTagData } = assetTagByName
        const { merchantInfo } = zone

        dispatch({
          type: actionTypes.NFC_LOAD_ASSET_TAG,
          zone: snakeCaseKeysDeep(zone),
          allTagData: snakeCaseKeysDeep(allTagData),
          assetTag
        })
        dispatch({
          type: actionTypes.NFC_LOAD_MERCHANT_INFO,
          merchantInfo: snakeCaseKeysDeep(merchantInfo)
        })
      }
    }
  })

  if (loaded && children) {
    return <div>{children}</div>
  }
  return null
}

AssetTagLoader.defaultProps = {
  handleError: () => {}
}

AssetTagLoader.propTypes = {
  name: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  redirectUrl: PropTypes.string
}

export default compose(connect(mapStateToProps), withRouter)(AssetTagLoader)
