/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import BrandedTagline from 'components/BrandedTagline'
import { trackEvent } from 'app/helpers/analyticsHelpers'

import ForgetHistoryModal from './ForgetHistoryModal'

import './DarkFooter.scss'

class DarkFooter extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalIsVisible: false
    }
  }

  showModal = (e) => {
    e.preventDefault()
    trackEvent('Delete History Selected')
    this.setState({ modalIsVisible: true })
  }

  hideModal = () => {
    trackEvent('Delete History Cancelled')
    this.setState({ modalIsVisible: false })
  }

  renderDeleteLink = () => {
    const { t, showDeleteLink } = this.props
    const { modalIsVisible } = this.state

    if (showDeleteLink) {
      return (
        <div>
          <div
            onClick={this.showModal}
            className="NFCDarkFooter--deleteHistory"
          >
            {t('nfc.footer.deletePurchaseHistory')}
          </div>
          <ForgetHistoryModal
            t={t}
            isShowing={modalIsVisible}
            hide={this.hideModal}
            handleDelete={this.handleDelete}
          />
        </div>
      )
    }

    return null
  }

  render() {
    const { t } = this.props

    return (
      <div className="NFCDarkFooter">
        <div className="NFCDarkFooter--branding">
          <BrandedTagline t={t} />
        </div>
        {this.renderDeleteLink()}
      </div>
    )
  }
}

DarkFooter.defaultProps = {
  showDeleteLink: true
}

DarkFooter.propTypes = {
  t: PropTypes.func.isRequired,
  showDeleteLink: PropTypes.bool
}

export default DarkFooter
