import { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Modal, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import * as NFCActions from 'redux/actions'
import { trackEvent } from 'app/helpers/analyticsHelpers'

import './ErrorScreen.scss'

const mapStateToProps = ({ nfc }) => ({
  error: nfc.error
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class ErrorScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
      touchedAt: new Date()
    }
  }

  showErrorUi = () => {
    trackEvent('Error Screen Displayed')
    this.setState({ isVisible: true })
  }

  reset = () => {
    trackEvent('Reload From Error Screen Pressed')
    const {
      nfcActions: { clearErrors }
    } = this.props
    clearErrors()
    this.setState({ isVisible: false, touchedAt: new Date() })
  }

  renderErrorMessage = () => {
    const { error, t } = this.props

    if (isEmpty(error)) {
      return null
    }

    return (
      <div className="ErrorScreen--message">
        {t(`nfc.zone.errors.${error}`)}
      </div>
    )
  }

  render() {
    const { isVisible, touchedAt } = this.state
    const { t, children } = this.props

    return (
      <div key={touchedAt}>
        {children({ ...this.state, showErrorUi: this.showErrorUi })}
        <Modal
          open={isVisible}
          basic
          centered
          dimmer="blurring"
          size="mini"
          closeOnEscape={false}
          closeOnDimmerClick={false}
          closeOnDocumentClick={false}
        >
          <Modal.Content>
            <div className="ErrorScreen--content">
              <Icon name="exclamation triangle" size="huge" />
              <div className="ErrorScreen--header">
                {t('nfc.errorScreen.header')}
              </div>
              {this.renderErrorMessage()}
              <div className="ErrorScreen--copy">
                <div>{t('nfc.errorScreen.apology')}</div>
                <div>{t('nfc.errorScreen.instructions')}</div>
                <div>
                  <Trans i18nKey="nfc.errorScreen.contact">
                    placeholder
                    <a
                      className="ErrorScreen--contactEmail"
                      href={`mailto:${t('nfc.errorScreen.supportEmail')}`}
                    >
                      {t('nfc.errorScreen.supportEmail')}
                    </a>
                  </Trans>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions className="ErrorScreen--actions">
            <Button
              className="ErrorScreen--reloadButton"
              onClick={this.reset}
              size="big"
            >
              {t('nfc.errorScreen.buttonText')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

ErrorScreen.propTypes = {
  children: PropTypes.func, // This is not a typo
  t: PropTypes.func,
  nfcActions: PropTypes.shape({
    clearErrors: PropTypes.func.isRequired
  }),
  error: PropTypes.string
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(ErrorScreen)
