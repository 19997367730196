import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import load from 'load-script'
import history from 'app/history'
import Header from '../layout/Header'

import './ScanRoute.scss'

const ScanRoute = () => {
  const { t } = useTranslation()
  const { assetTag } = useParams()

  const scannerStyle = {
    window: {
      borderColor: '#00C1E0'
    },
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    hint: ''
  }
  const barcodeScannerRef = useRef(null)

  const handleScanResult = (result) => {
    barcodeScannerRef.current.dispose()
    const scannedText = result?.barcodes[0].text
    const authorizationRoute = {
      pathname: `/t/${assetTag}/authorization`,
      state: { barcode: scannedText }
    }
    history.push(authorizationRoute)
  }

  const handleBackClick = () => {
    history.replace(`/t/${assetTag}`)
  }

  const handleCameraAccessDenied = () => {
    history.push({
      pathname: '/t/errors/camera-access-denied',
      state: { assetTag }
    })
  }

  const createBarcodeScanner = (scanbotSDK) => {
    const configuration = {
      containerId: 'barcode-scanner-view',
      onBarcodesDetected: handleScanResult,
      style: scannerStyle,
      barcodeFormats: ['QR_CODE']
    }

    scanbotSDK
      .createBarcodeScanner(configuration)
      .then((scanner) => {
        barcodeScannerRef.current = scanner
      })
      .catch(handleCameraAccessDenied)
  }

  useEffect(() => {
    load(
      'https://cdn.jsdelivr.net/npm/scanbot-web-sdk@latest/bundle/ScanbotSDK.min.js',
      () => {
        window.ScanbotSDK?.initialize({
          licenseKey: SCANBOT_LICENSE_KEY
        })
          .then(createBarcodeScanner)
          .catch(handleCameraAccessDenied)
      }
    )

    return () => {
      if (barcodeScannerRef.current) {
        barcodeScannerRef.current.dispose()
      }
    }
  }, [])

  return (
    <div className="ScanRoute--container">
      <Header backNav onBackClick={handleBackClick}>
        {t('nfc.tto.scan.header')}
      </Header>
      <div id="barcode-scanner-view" className="ScanRoute--videoContainer" />
      <div className="ScanRoute--instruction">
        {t('nfc.tto.scan.instruction')}
      </div>
    </div>
  )
}

export default ScanRoute
