import { PureComponent } from 'react'
import { Form, Input as OriginalInput } from 'semantic-ui-react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import './NFCForm.scss'

const NFCForm = (props) => {
  return <Form {...props} />
}

const Label = ({ children, className }) => {
  return (
    <div className={classNames('NFCForm--label', className)}>{children}</div>
  )
}

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

const Field = ({ className, ...otherProps }) => {
  return (
    <Form.Field
      className={classNames('NFCForm--field', className)}
      {...otherProps}
    />
  )
}

Field.propTypes = {
  className: PropTypes.string
}

const ErrorLabel = ({ className, children, visible }) => {
  if (visible) {
    return (
      <div className={classNames('NFCForm--errorLabel', className)}>
        {children}
      </div>
    )
  }

  return null
}

ErrorLabel.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.node
}

class Input extends PureComponent {
  render() {
    const { className, ...otherProps } = this.props
    return (
      <OriginalInput
        className={classNames('NFCForm--input', className)}
        {...otherProps}
      />
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool
}

NFCForm.Label = Label
NFCForm.Field = Field
NFCForm.ErrorLabel = ErrorLabel
NFCForm.Input = Input

export default NFCForm
