import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { PureComponent } from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect as formConnect } from 'formik'
import debounce from 'lodash/debounce'
import { PAYMENT_CARD_TYPES } from 'components/constants'

import * as NFCActions from 'redux/actions'
import { redirectToReceiptReminder } from 'components/purchase/helpers'
import { isRequestSuccessful } from 'app/helpers/api'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { tagPurchase, ga4Conversion } from 'app/helpers/tagManager'

import PurchaseButton from './PurchaseButton'
import PaymentIcon from '../PaymentIcon'

import './DefaultCreditCardPurchaseButton.scss'

const mapStateToProps = ({ nfc }) => {
  return {
    creditCard: nfc.account.credit_card,
    plateNumber: nfc.plateNumber,
    cart: nfc.cart,
    assetTag: nfc.assetTag
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class DefaultCreditCardPurchaseButton extends PureComponent {
  // eslint-disable-next-line react/sort-comp
  _creditCardTokenPurchase = () => {
    const {
      nfcActions: { createParkingSessionCreditCardToken },
      creditCard,
      cart,
      plateNumber,
      assetTag
    } = this.props

    const { id: cartId, extend_id: extendId } = cart

    const analyticsData = {
      is_extension: !!extendId,
      asset_tag: assetTag
    }

    createParkingSessionCreditCardToken(
      cartId,
      creditCard.id,
      plateNumber
    ).then((json) => {
      if (isRequestSuccessful(json)) {
        const { hashid } = json.data
        trackEvent(
          'Default Credit Card Purchase Completion Succeeded',
          analyticsData
        )
        ga4Conversion(json.data, cart)
        tagPurchase(json.data, cart)
        redirectToReceiptReminder(hashid)
      } else {
        trackEvent(
          'Default Credit Card Purchase Completion Failed',
          analyticsData
        )
        // TODO - handle errors
      }
    })
  }

  debouncedOnClick = debounce(this._creditCardTokenPurchase, 500, {
    leading: true,
    trailing: false
  })

  handleClick = () => {
    const {
      disabled,
      formik: { validateForm }
    } = this.props

    if (!disabled) {
      validateForm().then((errors) => {
        if (isEmpty(errors)) {
          this.debouncedOnClick()
        }
      })
    }
  }

  render() {
    const { t, creditCard, disabled } = this.props

    const { card_type: cardType, last_four: lastFour } = creditCard

    return (
      <PurchaseButton
        className="NFCDefaultCreditCardPurchaseButton"
        onClick={this.handleClick}
        disabled={disabled}
      >
        <PaymentIcon
          className="NFCDefaultCreditCardPurchaseButton--brandIcon"
          cardType={cardType}
        />
        <div>{t('nfc.paymentMethods.payWithCreditCard')}</div>
        <div>(**{lastFour})</div>
      </PurchaseButton>
    )
  }
}

DefaultCreditCardPurchaseButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  creditCard: PropTypes.shape({
    id: PropTypes.string,
    card_type: PropTypes.oneOf(Object.keys(PAYMENT_CARD_TYPES)),
    last_four: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  plateNumber: PropTypes.string,
  nfcActions: PropTypes.shape({
    createParkingSessionCreditCardToken: PropTypes.func.isRequired
  }),
  cart: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extend_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  formik: PropTypes.shape({
    validateForm: PropTypes.func
  }).isRequired,
  assetTag: PropTypes.string.isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  formConnect
)(DefaultCreditCardPurchaseButton)
