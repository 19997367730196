import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import * as actionTypes from 'redux/actionTypes'
import { authTokenSelector } from 'redux/reducers'
import { InitializeAnonymousAccount } from 'components/events/operations'
import { snakeCaseKeysDeep } from 'components/helpers/formatting'

const mapStateToProps = (state) => {
  return {
    loaded: state.nfc.loaded.account,
    token: authTokenSelector(state)
  }
}
const AccountLoader = ({ loaded, token, children }) => {
  const dispatch = useDispatch()

  const [loadAccount] = useMutation(InitializeAnonymousAccount, {
    variables: {
      input: {
        token // null is ok here, it'll initialze a new account
      }
    },
    onCompleted: ({ initializeAnonymousAccount }) => {
      const { account, anTag } = initializeAnonymousAccount
      const storeAccount = snakeCaseKeysDeep(account)
      storeAccount.an_tag = anTag

      dispatch({
        type: actionTypes.NFC_LOAD_ACCOUNT,
        account: storeAccount
      })
    }
  })

  useEffect(() => {
    loadAccount()
  }, [loadAccount])

  if (loaded && children) {
    return children
  }
  return null
}

AccountLoader.defaultProps = {
  handleError: () => {}
}

AccountLoader.propTypes = {
  zone: PropTypes.shape({
    hashid: PropTypes.string.isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    createCart: PropTypes.func.isRequired,
    failedLoadingCart: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func
}
export default connect(mapStateToProps)(AccountLoader)
