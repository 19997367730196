import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { Container, Image } from 'semantic-ui-react'
import catImg from 'components/errors/_assets/404-cat.svg'
// import logo from "Coreapp/layout/header/_assets/logo-black.svg"
import { wordmarkDark } from 'components/common/Logos'

import './NFCNotFoundRoute.scss'

class NFCNotFoundRoute extends PureComponent {
  render() {
    const { t } = this.props

    return (
      <Container className="NFCNotFoundRoute--routeContainer">
        <div className="NFCNotFoundRoute--wrapper">
          <Image
            size="tiny"
            src={wordmarkDark.default}
            className="NFCNotFoundRoute--honkWordmark"
          />
          <div>
            <Image centered size="large" src={catImg} />
            <div className="NFCNotFoundRoute--header">
              {t('nfc.errorPages.404Header')}
            </div>
            <div className="NFCNotFoundRoute--copy">
              {t('nfc.errorPages.404Copy')}
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

NFCNotFoundRoute.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(NFCNotFoundRoute)
