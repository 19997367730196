// ZONE SEARCH

export const SEARCH_ZONES_LOADED = 'SEARCH_ZONES_LOADED'
export const PREFERRED_ZONES_LOADED = 'PREFERRED_ZONES_LOADED'
export const NEARBY_ZONES_LOADED = 'NEARBY_ZONES_LOADED'
export const SEARCH_ZONES_ERROR = 'SEARCH_ZONES_ERROR'
export const PREFERRED_ZONES_ERROR = 'PREFERRED_ZONES_ERROR'
export const NEARBY_ZONES_ERROR = 'PREFERRED_ZONES_ERROR'
export const CLEAR_GEOLOCATION = 'CLEAR_GEOLOCATION'
export const UPDATE_GEOLOCATION = 'UPDATE_GEOLOCATION'
export const UPDATE_SEARCH_LOCATION = 'UPDATE_SEARCH_LOCATION'
export const UPDATE_SEARCH_TYPE = 'UPDATE_SEARCH_TYPE'
export const UPDATE_MAP_ZOOM = 'UPDATE_MAP_ZOOM'
export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER'
export const SELECT_PIN = 'SELECT_PIN'
export const SEARCH_FILTER_PRODUCT = 'SEARCH_FILTER_PRODUCT'
export const TOGGLE_PRODUCT_TYPE = 'TOGGLE_PRODUCT_TYPE'
export const SHOW_DIMMER = 'SHOW_DIMMER'
export const SELECT_PRODUCT_TYPE = 'SELECT_PRODUCT_TYPE'
export const UPDATE_ZONE_SEARCH_COORDS = 'UPDATE_ZONE_SEARCH_COORDS'
export const UPDATE_SEARCH_RADIUS = 'UPDATE_SEARCH_RADIUS'
export const SEARCH_ZONES_LOADING = 'SEARCH_ZONES_LOADING'
export const SET_INSTRUCTIONS_VISIBILITY = 'SET_INSTRUCTIONS_VISIBILITY'
export const UPDATE_EVENT_VENUE = 'UPDATE_EVENT_VENUE'
export const UPDATE_EVENTS = 'UPDATE_EVENTS'
export const UPDATE_SELECTED_EVENT = 'UPDATE_SELECTED_EVENT'
export const CLEAR_EVENTS = 'CLEAR_EVENTS'

export const AUTOCOMPLETE_ZONES_LOADED = 'AUTOCOMPLETE_ZONES_LOADED'

// USER

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'

// VEHICLE

export const VEHICLES_SUCCESS = 'VEHICLES_SUCCESS'
export const VEHICLES_LOADING = 'LOADING_VEHICLES'
export const VEHICLE_DELETED = 'VEHICLE_DELETED'
export const VEHICLE_CREATED = 'VEHICLE_CREATED'
export const VEHICLE_UPDATED = 'VEHICLE_UPDATED'
export const VEHICLES_API_ERROR = 'VEHICLES_API_ERROR'
export const VEHICLES_REMOVE_PRIMARY = 'VEHICLES_REMOVE_PRIMARY'

export const USER_ERROR = 'USER_ERROR'
export const FACEBOOK_REQUEST = 'FACEBOOK_REQUEST'
export const FACEBOOK_SUCCESS = 'FACEBOOK_SUCCESS'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FOUND_PASSWORD_REQUEST = 'FOUND_PASSWORD_REQUEST'
export const FOUND_PASSWORD_SUCCESS = 'FOUND_PASSWORD_SUCCESS'

// Receipts
export const RECEIPTS_LOADED = 'RECEIPTS_LOADED'
export const RECEIPT_LOADED = 'RECEIPT_LOADED'
export const RECEIPTS_LOADING = 'RECEIPTS_LOADING'
export const RECEIPT_ERROR = 'RECEIPT_ERROR'

// SUBSCRIPTIONS
export const LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS'
export const LOAD_SUBSCRIPTION = 'LOAD_SUBSCRIPTION'
export const LOAD_SUBSCRIPTIONS_ERROR = 'LOAD_SUBSCRIPTIONS_ERROR'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'

// ZONES
export const LOAD_ZONE = 'LOAD_ZONE'
export const LOAD_ZONE_ERROR = 'LOAD_ZONE_ERROR'
export const LOAD_ZONE_RATES = 'LOAD_RATES'
export const LOAD_ZONE_RATES_ERROR = 'LOAD_RATES_ERROR'

// PURCHASE
export const SET_ZONE_TYPE = 'SET_ZONE_TYPE'
export const UPDATE_TIME = 'UPDATE_TIME'
export const UPDATE_END_TIME = 'UPDATE_END_TIME'
export const UPDATE_START_END_TIME = 'UPDATE_START_END_TIME'
export const RESET_TIMES = 'RESET_TIMES'

// CHECKOUT
export const RESET_CHECKOUT = 'RESET_CHECKOUT'
export const CREATE_CART = 'CREATE_CART'
export const LOAD_RATES = 'LOAD_RATES'
export const LOAD_RATES_ERROR = 'LOAD_RATES_ERROR'
export const ADD_RATE = 'ADD_RATE'
export const LOAD_CART = 'LOAD_CART'
export const CLAIM_CART = 'CLAIM_CART'
export const CHECKOUT_LOAD_VEHICLES = 'CHECKOUT_LOAD_VEHICLES'
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD'
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD'
export const SELECT_VEHICLE = 'SELECT_VEHICLE'
export const CHECKOUT_SUBMITTED = 'CHECKOUT_SUBMITTED'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'
export const CHECKOUT_UPDATE_NOTES = 'CHECKOUT_UPDATE_NOTES'
export const REMOVE_VEHICLE_SELECTION = 'REMOVE_VEHICLE_SELECTION'
export const REMOVE_CART = 'REMOVE_CART'
export const LOAD_CART_MERCHANT_INFO = 'LOAD_CART_MERCHANT_INFO'
export const CHECKOUT_LOAD_PAYMENT_METHODS = 'CHECKOUT_LOAD_PAYMENT_METHODS'
export const SET_SPACE_INFO = 'SET_SPACE_INFO'
export const CLEAR_SPACE_INFO = 'CLEAR_SPACE_INFO'
export const SET_PUSH_NOTIFICATION_ID = 'SET_PUSH_NOTIFICATION_ID'
export const CHECKOUT_PURCHASE_RESET = 'CHECKOUT_PURCHASE_RESET'
export const PREPURCHASE_READY = 'PREPURCHASE_READY'
export const APPLE_PAY_PREPURCHASE_COMPLETE = 'APPLE_PAY_PREPURCHASE_COMPLETE'
export const GOOGLE_PAY_PREPURCHASE_COMPLETE = 'GOOGLE_PAY_PREPURCHASE_COMPLETE'
export const PAYPAL_PREPURCHASE_COMPLETE = 'PAYPAL_PREPURCHASE_COMPLETE'
export const PREPURCHASE_COMPLETE = 'PREPURCHASE_COMPLETE'

// Errors
export const SET_ERROR_DRAWER_VISIBILITY = 'SET_ERROR_DRAWER_VISIBILITY'
export const SET_ERROR_DRAWER_MESSAGE = 'SET_ERROR_DRAWER_MESSAGE'

// ACCOUNT
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT'
export const SAVE_ACCOUNT = 'SAVE_ACCOUNT'

// PAYMENT CARDS
export const LOAD_PAYMENT_CARDS = 'LOAD_PAYMENT_CARDS'
export const LOAD_ONE_CARD_CARDS = 'LOAD_ONE_CARD_CARDS'
export const MAKE_PAYMENT_CARD_PRIMARY = 'MAKE_PAYMENT_CARD_PRIMARY'
export const UPDATE_PAYMENT_CARD = 'UPDATE_PAYMENT_CARD'
export const MAKE_ONE_CARD_PRIMARY = 'LOAD_ONE_CARD_PRIMARY'
export const DELETE_PAYMENT_CARD = 'DELETE_PAYMENT_CARD'
export const DELETE_ONE_CARD_CARD = 'DELETE_ONE_CARD_CARD'
export const LOAD_MERCHANT_INFOS = 'LOAD_MERCHANT_INFOS'

// SESSIONS
export const LOAD_SESSIONS = 'LOAD_SESSIONS'
export const LOAD_SESSION = 'LOAD_SESSION'
export const CHANGE_VEHICLE_IN_PLACE_PENDING = 'CHANGE_VEHICLE_IN_PLACE_PENDING'
export const CHANGE_VEHICLE_IN_PLACE_FULFILLED =
  'CHANGE_VEHICLE_IN_PLACE_FULFILLED'
export const CHANGE_VEHICLE_IN_PLACE_REJECTED =
  'CHANGE_VEHICLE_IN_PLACE_REJECTED'

export const LOAD_VEHICLE_SWITCH_DETAILS_PENDING =
  'LOAD_VEHICLE_SWITCH_DETAILS_PENDING'
export const LOAD_VEHICLE_SWITCH_DETAILS_FULFILLED =
  'LOAD_VEHICLE_SWITCH_DETAILS_FULFILLED'
export const LOAD_VEHICLE_SWITCH_DETAILS_REJECTED =
  'LOAD_VEHICLE_SWITCH_DETAILS_REJECTED'

// UTM DATA
export const SET_UTM_DATA = 'SET_UTM_DATA'

// FAVORITES
export const CREATE_FAVORITE = 'CREATE_FAVORITE'
export const CREATE_FAVORITE_ERROR = 'CREATE_FAVORITE_ERROR'
export const DELETE_FAVORITE = 'DELETE_FAVORITE'
export const DELETE_FAVORITE_ERROR = 'DELETE_FAVORITE_ERROR'
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE'
export const UPDATE_FAVORITE_ERROR = 'UPDATE_FAVORITE_ERROR'

// NFC
export const NFC_LOADING_ASSET_TAG = 'NFC_LOADING_ASSET_TAG'
export const NFC_LOAD_ASSET_TAG = 'NFC_LOAD_ASSET_TAG'
export const NFC_LOAD_ASSET_TAG_FAILED = 'NFC_LOAD_ASSET_TAG_FAILED'

export const NFC_LOADING_CART = 'NFC_LOADING_CART'
export const NFC_LOAD_CART = 'NFC_LOAD_CART'
export const NFC_LOAD_CART_FAILED = 'NFC_LOAD_CART_FAILED'
export const NFC_CLEAR_CART = 'NFC_CLEAR_CART'

export const NFC_LOADING_RATES = 'NFC_LOADING_RATES'
export const NFC_LOAD_RATES = 'NFC_LOAD_RATES'
export const NFC_LOAD_RATES_FAILED = 'NFC_LOAD_RATES_FAILED'
export const NFC_SETTING_RATE = 'NFC_SETTING_RATE'
export const NFC_SET_RATE = 'NFC_SET_RATE'

export const NFC_ADDING_PROMO_CODE = 'NFC_ADDING_PROMO_CODE'
export const NFC_ADD_PROMO_CODE = 'NFC_ADD_PROMO_CODE'
export const NFC_ADD_PROMO_CODE_FAILED = 'NFC_ADD_PROMO_CODE_FAILED'

export const NFC_LOADING_ACCOUNT = 'NFC_LOADING_ACCOUNT'
export const NFC_LOAD_ACCOUNT = 'NFC_LOAD_ACCOUNT'
export const NFC_LOAD_ACCOUNT_FAILED = 'NFC_LOAD_ACCOUNT_FAILED'
export const NFC_UPDATE_ACCOUNT_MOBILE_NUMBER =
  'NFC_UPDATE_ACCOUNT_MOBILE_NUMBER'
export const NFC_UPDATE_ACCOUNT_EMAIL = 'NFC_UPDATE_ACCOUNT_EMAIL'

export const NFC_ZERO_DOLLAR_PURCHASING = 'NFC_ZERO_DOLLAR_PURCHASING'
export const NFC_PAYPAL_PURCHASING = 'NFC_PAYPAL_PURCHASING'
export const NFC_PAYPAL_ORDER_REQUESTED = 'NFC_PAYPAL_ORDER_REQUESTED'
export const NFC_PAYPAL_ORDER_FAILED = 'NFC_PAYPAL_ORDER_FAILED'
export const NFC_APPLE_PAY_PURCHASING = 'NFC_APPLE_PAY_PURCHASING'
export const NFC_GOOGLE_PAY_PURCHASING = 'NFC_GOOGLE_PAY_PURCHASING'

export const NFC_CREDIT_CARD_TOKEN_PURCHASING =
  'NFC_CREDIT_CARD_TOKEN_PURCHASING'

export const NFC_START_PROCESSING_TRANSACTION =
  'NFC_START_PROCESSING_TRANSACTION'
export const NFC_STOP_PROCESSING_TRANSACTION = 'NFC_STOP_PROCESSING_TRANSACTION'
export const NFC_PURCHASE_COMPLETED = 'NFC_PURCHASE_COMPLETED'
export const NFC_PURCHASE_FAILED = 'NFC_PURCHASE_FAILED'

export const NFC_SET_PLATE_NUMBER = 'NFC_SET_PLATE_NUMBER'

export const NFC_LOAD_PARKING_SESSION = 'NFC_LOAD_PARKING_SESSION'
export const NFC_LOADING_PARKING_SESSION = 'NFC_LOADING_PARKING_SESSION'
export const NFC_LOADING_PARKING_SESSION_FAILED =
  'NFC_LOADING_PARKING_SESSION_FAILED'

export const NFC_LOADING_VEHICLE = 'NFC_LOADING_VEHICLE'
export const NFC_LOAD_VEHICLE = 'NFC_LOAD_VEHICLE'
export const NFC_LOAD_VEHICLE_FAILED = 'NFC_LOAD_VEHICLE_FAILED'
export const NFC_ADD_ERROR = 'NFC_ADD_ERROR'
export const NFC_REMOVE_ERROR = 'NFC_REMOVE_ERROR'
export const NFC_CLEAR_ERRORS = 'NFC_CLEAR_ERRORS'

export const NFC_LOAD_ZONE = 'NFC_LOAD_ZONE'
export const NFC_LOADING_ZONE = 'NFC_LOADING_ZONE'
export const NFC_LOAD_ZONE_FAILED = 'NFC_LOAD_ZONE_FAILED'
export const NFC_RESET_ZONE = 'NFC_RESET_ZONE'

export const NFC_LOAD_ZONE_INVENTORY = 'NFC_LOAD_ZONE_INVENTORY'

export const NFC_LOAD_ZONE_OPTION = 'NFC_LOAD_ZONE_OPTION'
export const NFC_CLEAR_ZONE_OPTION = 'NFC_CLEAR_ZONE_OPTION'

export const NFC_LOAD_CHARGER_STATE = 'NFC_LOAD_CHARGER_STATE'
export const NFC_START_CHARGER = 'NFC_START_CHARGER'

export const NFC_GET_ACTIVE_SESSIONS = 'NFC_GET_ACTIVE_SESSIONS'

export const NFC_LOGOUT = 'NFC_LOGOUT'
export const NFC_CLEAR_USER_INFO = 'NFC_CLEAR_USER_INFO'
export const NFC_LOAD_MERCHANT_INFO = 'NFC_LOAD_MERCHANT_INFO'

// EV CHARGING

export const LOAD_CHARGER_STATE = 'LOAD_CHARGER_STATE'
export const START_CHARGER = 'START_CHARGER'

// MODALS
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
