import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Loader } from 'semantic-ui-react'
import isEmpty from 'lodash/isEmpty'
import { cartSelector } from 'redux/reducers'
import { addPromoCode } from 'redux/actions'
import { formatPrice } from 'app/helpers/formatting'
import { isRequestSuccessful } from 'app/helpers/api'
import ClearIcon from './_assets/icon-close.svg'

import styles from './PromoCode.module.scss'

const PromoCode = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const {
    promo_code_id: promoCodeId,
    discount,
    id: cartId
  } = useSelector(cartSelector)
  const {
    cart: cartLoaded,
    rates: ratesLoaded,
    rate: rateLoaded
  } = useSelector((state) => state.nfc.loaded)

  const [hasPromoCode, setHasPromoCode] = useState(!!promoCodeId)
  const [loading, setLoading] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const [hasError, setHasError] = useState(false)
  const [promoCodeLoading, setPromoCodeLoading] = useState(false)

  const handleChange = (e) => {
    const { value } = e.target
    setHasError(false)
    setPromoCode(value.toUpperCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setPromoCodeLoading(true)
    return dispatch(addPromoCode(cartId, promoCode)).then((json) => {
      if (isRequestSuccessful(json)) {
        setShowForm(false)
        setPromoCode('')
      } else {
        setHasError(true)
      }

      setPromoCodeLoading(false)
      return json
    })
  }

  useEffect(() => {
    setHasPromoCode(!!promoCodeId)
  }, [promoCodeId])

  useEffect(() => {
    setLoading(!(cartLoaded && ratesLoaded && rateLoaded))
  }, [cartLoaded, ratesLoaded, rateLoaded])

  const handleDiscountDisplay = (amount) => {
    if (loading) {
      return ''
    }

    return `-$${formatPrice(amount)}`
  }

  const handleClick = () => {
    if (!loading) {
      setShowForm(true)
    }
  }

  const renderEmptyPromoCode = () => (
    <div className={styles.container}>
      <button
        className={classNames(styles.showFormButton, {
          [styles.disabled]: loading,
          [styles.empty]: !hasPromoCode
        })}
        onClick={handleClick}
        type="button"
      >
        {t('nfc.zone.promoCode.addPromo')}
      </button>
    </div>
  )

  const renderAppliedPromoCode = () => (
    <div className={classNames(styles.container, styles.applied)}>
      <div>{t('nfc.zone.promoCode.discount')}</div>
      <div>{handleDiscountDisplay(discount)}</div>
    </div>
  )

  const renderFormButton = () =>
    isEmpty(promoCode) ? (
      <button
        type="button"
        className={styles.formButton}
        onClick={() => setShowForm(false)}
      >
        {t('nfc.zone.promoCode.cancelButton')}
      </button>
    ) : (
      <button
        type="button"
        className={classNames(styles.formButton, styles.submit)}
        onClick={handleSubmit}
      >
        {t('nfc.zone.promoCode.applyButton')}
      </button>
    )

  const renderPromoCodeForm = () =>
    promoCodeLoading ? (
      <Loader inline="centered" active size="small" />
    ) : (
      // Cannot use onSubmit because it will rerender entire page without applying promo code
      <form className={styles.form}>
        <div className={styles.inputGroup}>
          <div className={styles.inputWrapper}>
            <input
              className={styles.input}
              value={promoCode}
              onChange={handleChange}
              placeholder={t('nfc.zone.promoCode.placeholder')}
            />
            {!isEmpty(promoCode) && (
              <button
                type="button"
                onClick={() => setPromoCode('')}
                className={styles.clearButton}
              >
                <img src={ClearIcon} alt="round x icon" />
              </button>
            )}
          </div>
          {renderFormButton()}
        </div>
        {hasError && (
          <p className={styles.errorMessage}>
            {t('nfc.zone.promoCode.errorLabel')}
          </p>
        )}
      </form>
    )

  const renderPromoCodeDefault = () =>
    hasPromoCode ? renderAppliedPromoCode() : renderEmptyPromoCode()

  return showForm ? renderPromoCodeForm() : renderPromoCodeDefault()
}

export default PromoCode
