import amex from './_assets/cards-color_amex.svg'
import discover from './_assets/cards-color_discover.svg'
import mastercard from './_assets/cards-color_mastercard.svg'
import visa from './_assets/cards-color_visa.svg'
import generic from './_assets/cards-generic-credit-card.svg'

import {
  AMERICAN_EXPRESS,
  DISCOVER,
  MASTERCARD,
  VISA,
  GENERIC,
  PAYMENT_CARD_TYPES
} from './constants'

export const cardImages = {
  [AMERICAN_EXPRESS]: amex,
  [DISCOVER]: discover,
  [MASTERCARD]: mastercard,
  [VISA]: visa,
  [GENERIC]: generic
}

export function paymentTypeToIcon(cardType) {
  const icon = cardImages[PAYMENT_CARD_TYPES[cardType]]
  return icon || cardImages[GENERIC]
}
