import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { connect as formConnect } from 'formik'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import * as NFCActions from 'redux/actions'
import { redirectToReceiptReminder } from 'components/purchase/helpers'
import { isRequestSuccessful } from 'app/helpers/api'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { tagPurchase, ga4Conversion } from 'app/helpers/tagManager'

import PurchaseButton from './PurchaseButton'

import './ZeroDollarPurchaseButton.scss'

const mapStateToProps = ({ nfc }) => {
  const {
    assetTag,
    plateNumber,
    cart: { id: cartId, is_extension: isExtension }
  } = nfc

  return {
    assetTag,
    cartId,
    plateNumber,
    isExtension: !!isExtension
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class ZeroDollarPurchaseButton extends PureComponent {
  handleClick = () => {
    const {
      cartId,
      plateNumber,
      isExtension,
      assetTag,
      cart,
      nfcActions: { createParkingSessionZeroDollar },
      formik: { validateForm }
    } = this.props

    const analyticsData = {
      is_extension: isExtension,
      asset_tag: assetTag
    }

    trackEvent('Zero Dollar Purchase Started', analyticsData)

    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        createParkingSessionZeroDollar(cartId, plateNumber).then((json) => {
          if (isRequestSuccessful(json)) {
            trackEvent(
              'Zero-Dollar Purchase Completion Succeeded',
              analyticsData
            )
            const { hashid } = json.data
            ga4Conversion(json.data, cart)
            tagPurchase(json.data, cart)
            redirectToReceiptReminder(hashid)
          } else {
            trackEvent('Zero Dollar Purchase Completion Failed', analyticsData)
          }
        })
      }
    })
  }

  render() {
    const { t, disabled } = this.props

    return (
      <PurchaseButton
        className="NFCZeroDollarPurchaseButton"
        onClick={this.handleClick}
        disabled={disabled}
      >
        {t('nfc.paymentMethods.zeroDollar')}
      </PurchaseButton>
    )
  }
}

ZeroDollarPurchaseButton.propTypes = {
  t: PropTypes.func.isRequired,
  cartId: PropTypes.number.isRequired,
  plateNumber: PropTypes.string,
  disabled: PropTypes.bool,
  isExtension: PropTypes.bool,
  assetTag: PropTypes.string,
  nfcActions: PropTypes.shape({
    createParkingSessionZeroDollar: PropTypes.func
  }).isRequired,
  formik: PropTypes.shape({
    validateForm: PropTypes.func
  }),
  cart: PropTypes.shape({
    id: PropTypes.number
  })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  formConnect
)(ZeroDollarPurchaseButton)
