import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Button, Input } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

import { SendSmsReceipt } from 'components/events/operations'
import { formatPhoneNumber } from 'components/helpers/formatting'
import MobileNumberSkip from './MobileNumberSkip'

import phoneHandImg from './_assets/phone_hand.png'
import styles from './MobileNumberRoute.module.scss'

const MobileNumberRoute = () => {
  const [t] = useTranslation()
  const [skip, setSkip] = useState(false)
  const [mobileNumber, setMobileNumber] = useState('')
  const [success, setSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)
  const history = useHistory()
  const { sessionId: parkingSessionId } = useParams()

  const goToSession = () => history.push(`/e/session/${parkingSessionId}`)

  useEffect(() => {
    if (!hasError) return () => {}

    const timer = setTimeout(() => setHasError(false), 5000)
    return () => clearTimeout(timer)
  }, [hasError])

  useEffect(() => {
    if (!success) return () => {}

    const timer = setTimeout(goToSession, 2000)
    return () => clearTimeout(timer)
  }, [success])

  const [sendReceipt, { loading }] = useMutation(SendSmsReceipt, {
    variables: {
      input: {
        parkingSessionId,
        // only submit numbers
        mobileNumber: mobileNumber.replace(/[^\d]/g, '')
      }
    },
    onCompleted: () => {
      setSuccess(true)
    }
  })

  if (skip)
    return (
      <MobileNumberSkip
        goBack={() => setSkip(false)}
        goToSession={goToSession}
      />
    )

  const handleSubmit = () => {
    if (success) return

    if (isEmpty(mobileNumber)) {
      setHasError(true)
    } else {
      sendReceipt()
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.body}>
        <h2 className={styles.header}>{t('nfc.event.saveQr')}</h2>
        <img className={styles.phoneImg} src={phoneHandImg} alt="phone" />
        <div className={styles.copy}>{t('nfc.event.qrInstructions')}</div>
        <Input
          error={hasError}
          className={styles.input}
          placeholder={t('nfc.event.mobilePlaceholder')}
          value={mobileNumber}
          onFocus={() => setHasError(false)}
          onChange={({ target: { value } }) => {
            setMobileNumber(formatPhoneNumber(value))
          }}
        />
        <Button
          fluid
          primary={!success}
          positive={success}
          loading={loading}
          disabled={loading}
          onClick={handleSubmit}
          className={styles.roundButton}
        >
          {success ? t('nfc.event.sent') : t('nfc.event.submit')}
        </Button>
        <Button
          fluid
          inverted
          primary
          loading={loading}
          disabled={loading || success}
          onClick={() => setSkip(true)}
          className={classNames(styles.roundButton, styles.invertedButton)}
        >
          {t('nfc.event.skip')}
        </Button>
      </div>
    </div>
  )
}

export default MobileNumberRoute
