import { gql } from '@apollo/client'

export const GetParkingSession = gql`
  query GetParkingSession($id: ID!) {
    parkingSession(id: $id) {
      id
      hashid
      endTime
      customInstructions
      reservation {
        barcodeImageUrl
        barcodeWidth
      }
      transactionHistory {
        pcCardType
        pcLastFour
        paymentDate
        total
        invoiceNumber
      }
      zone {
        zoneId
        hashid
        timezone
        address {
          street
          city
        }
        company {
          properName
        }
      }
    }
  }
`

export const SendXCReceiptEmail = gql`
  mutation sendXcReceiptEmail($input: SendXCReceiptEmailInput!) {
    sendXcReceiptEmail(input: $input) {
      errors
    }
  }
`
