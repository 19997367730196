import  { DateTime, Duration } from 'luxon'
import { formatDateTime } from 'app/helpers/formatDateTime'
import capitalize from 'lodash/capitalize'

export function formatPrice(priceFloat) {
  return parseFloat(priceFloat).toFixed(2)
}

export function formatTime(time, t) {
  if (time < DateTime.now().plus({ minutes: 1 })) {
    return t('search.now')
  }
  if (time < DateTime.now().plus({ days: 1}).startOf('day')) {
    return formatDateTime(time).toFormat('t')
  }

  return formatDateTime(time).toFormat('LLL d (h:mma)')
}

export function formatDate(time, t) {
  if (DateTime.now().hasSame(time, 'day')) {
    return t('search.today')
  }
  return formatDateTime(time).toFormat('DDD')
}

export function formatAtTime(time, t) {
  return formatDateTime(time).toFormat(`'${t('search.at')}' h:mma`)
}

export function formatDrawerTime(time) {
  if (time < DateTime.now().plus({ days: 1}).startOf('day')) {
    return formatDateTime(time).toFormat('t')
  }
  return formatDateTime(time).toFormat('LLL d, h:mma')
}

export function formatCompactTime(duration) {
  const minute = 1000 * 60
  const roundedDuration = Math.ceil(duration / minute) * minute
  const workingDuration = Duration.fromDurationLike(roundedDuration)

  let checkedKeys
  if (workingDuration.get('d')) {
    checkedKeys = ['d', 'h']
  } else {
    checkedKeys = ['h', 'm']
  }

  const denoms = checkedKeys.reduce((final, key) => {
    const value = workingDuration.get(key)
    if (value) {
      final.push(`${value}${key}`)
    }

    return final
  }, [])

  return denoms.join('')
}

export function humanize(string) {
  return (string || '')
    .toString() // might be a number
    .trim()
    .replace(/[\W_]+/g, ' ')
}

export function titleize(string) {
  return (string || '')
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ')
}

export function toSentence(array, options = {}) {
  const defaults = {
    wordsConnector: ', ',
    twoWordsConnector: ' and ',
    lastWordConector: ', and '
  }
  const { wordsConnector, twoWordsConnector, lastWordConector } = {
    ...defaults,
    ...options
  }

  if (array.length > 2) {
    return (
      array.slice(0, -1).join(wordsConnector) +
      lastWordConector +
      array.slice(-1)
    )
  }

  return array.join(twoWordsConnector)
}