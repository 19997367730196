/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Modal } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import * as NFCActions from 'redux/actions'

import './ForgetHistoryModal.scss'

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class ForgetHistoryModal extends PureComponent {
  handleDelete = () => {
    const {
      nfcActions: { logout },
      history
    } = this.props
    trackEvent('Delete History Confirmed')
    logout()
    history.push('/e/goodbye')
  }

  render() {
    const { t, isShowing, hide } = this.props

    return (
      <Modal open={isShowing} basic centered onClose={hide} closeIcon>
        <Modal.Content>
          <div className="ForgetHistoryModal--container">
            <div className="ForgetHistoryModal--content">
              <div className="ForgetHistoryModal--header">
                {t('nfc.footer.deleteHeader')}
              </div>
              <div className="ForgetHistoryModal--copy">
                {t('nfc.footer.deleteCopy')}
              </div>
            </div>
            <div className="ForgetHistoryModal--buttonSection">
              <div
                className="ForgetHistoryModal--button ForgetHistoryModal--proceedButton"
                onClick={this.handleDelete}
              >
                {t('nfc.footer.proceedButton')}
              </div>
              <div
                className="ForgetHistoryModal--button ForgetHistoryModal--cancelButton"
                onClick={hide}
              >
                {t('nfc.footer.cancelButton')}
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

ForgetHistoryModal.propTypes = {
  t: PropTypes.func.isRequired,
  isShowing: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  nfcActions: PropTypes.shape({
    logout: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter
)(ForgetHistoryModal)
