import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import { useTranslation, Trans } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import Button from 'components/common/Button'
import CloseIcon from './_assets/icon-close-grey.svg'

import './CardErrorModal.scss'

const CardErrorModal = ({ open, onClose }) => {
  const { t } = useTranslation()
  const supportEmail = WHITELABEL_CONFIG.SUPPORT_EMAIL_ADDRESS

  return (
    <Modal open={open} onClose={onClose} className="CardErrorModal">
      <Modal.Content>
        <div className="CardErrorModal--content">
          <button
            type="button"
            onClick={onClose}
            className="CardErrorModal--closeButton"
          >
            <img src={CloseIcon} alt="Close X icon" />
          </button>
          <h3 className="CardErrorModal--header">
            {t('nfc.errors.cardErrorTitle')}
          </h3>
          <p className="CardErrorModal--mainContent">
            {isEmpty(supportEmail) ? (
              t('nfc.errors.generalNoEmail')
            ) : (
              <Trans i18nKey="nfc.errors.general">
                placeholder
                <a
                  className="CardErrorModal--contactEmail"
                  href={`mailto:${supportEmail}`}
                >
                  {supportEmail}
                </a>
              </Trans>
            )}
          </p>
        </div>
        <Button onClick={onClose} className="CardErrorModal--button">
          {t('nfc.errors.cardErrorConfirm')}
        </Button>
      </Modal.Content>
    </Modal>
  )
}

CardErrorModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
}

export default CardErrorModal
