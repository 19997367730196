import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import keys from 'lodash/keys'
import { Transition } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { DateTime } from 'luxon'
import { camelCaseKeysDeep } from 'components/helpers/formatting'
import Button from 'components/common/Button'
import * as NFCActions from '../redux/actions'
import { CHARGER_CARD_TEXT } from './constants'

import checkmarkGreen from './_assets/checkmark-green.svg'
import chargingOnStatus from './_assets/ev-charging.svg'

import styles from './ChargerStatusCard.module.scss'

const mapStateToProps = ({ nfc }) => {
  const { parkingSession, zone, loaded } = nfc

  const { hashid, chargerState } = camelCaseKeysDeep(zone)
  const { id: parkingSessionId, expiry } = camelCaseKeysDeep(parkingSession)
  const { parkingSession: parkingSessionLoaded } = loaded

  return {
    zoneId: hashid,
    chargerState,
    expiry,
    parkingSessionId,
    parkingSessionLoaded
  }
}

const mapDispatchToProps = (dispatch) => ({
  nfcActions: bindActionCreators(NFCActions, dispatch)
})

const ChargerStatusCard = ({
  chargerState = 'inactive',
  zoneId,
  parkingSessionId,
  parkingSessionLoaded,
  nfcActions,
  expiry,
  t
}) => {
  const isChargerInactive = chargerState === CHARGER_CARD_TEXT.inactive.status
  const isChargerPending = chargerState === CHARGER_CARD_TEXT.pending.status
  const timeout = useRef()
  const [isStartRequested, setIsStartRequested] = useState(false)
  const [showChargerActiveCard, setShowChargerActiveCard] = useState(false)

  const handleClick = () => {
    setIsStartRequested(true)
    nfcActions.startCharger(zoneId, parkingSessionId).then(() => {
      setIsStartRequested(false)
    })
  }

  const pollChargerState = (callback) => {
    callback().then(() => {
      timeout.current = setTimeout(() => pollChargerState(callback), 4000)
    })
  }

  useEffect(() => {
    if (parkingSessionLoaded && DateTime.now() < expiry) {
      pollChargerState(() =>
        nfcActions.loadChargerState(zoneId, parkingSessionId)
      )
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [parkingSessionLoaded])

  if (!chargerState) return null

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (chargerState === CHARGER_CARD_TEXT.active.status) {
      const timer = setTimeout(() => {
        setShowChargerActiveCard(true)
      }, 2000)

      return () => clearTimeout(timer)
    }

    setShowChargerActiveCard(false)
  }, [chargerState])

  const renderChargingCard = () => (
    <div className={classNames(styles.card, styles.chargerOn)}>
      <h3>{t('nfc.purchaseComplete.evCharging.header')}</h3>
      <img src={chargingOnStatus} alt="charging on status badge" />
    </div>
  )

  const renderChargerStatusCard = () => (
    <div className={styles.card}>
      <h3>{t('nfc.purchaseComplete.evCharging.header')}</h3>
      <div className={styles.stepper}>
        <div className={classNames(styles.step, styles.purchase)}>
          <img
            className={styles.statusIcon}
            src={checkmarkGreen}
            alt="green checkmark icon"
          />
          {t('nfc.purchaseComplete.evCharging.purchaseComplete')}
        </div>
        <div className={styles.step}>
          {isChargerPending ? (
            <div className={styles.loader} />
          ) : (
            <img
              className={styles.statusIcon}
              src={CHARGER_CARD_TEXT[chargerState].icon}
              alt={`${chargerState} status icon`}
            />
          )}
          {CHARGER_CARD_TEXT[chargerState].text}
        </div>
        <Transition
          visible={isChargerInactive}
          transitionOnMount
          unmountOnHide
          duration={200}
        >
          <Button
            className={styles.button}
            onClick={handleClick}
            disabled={isStartRequested}
          >
            {t('nfc.purchaseComplete.evCharging.turnOn')}
          </Button>
        </Transition>
      </div>
    </div>
  )

  return showChargerActiveCard
    ? renderChargingCard()
    : renderChargerStatusCard()
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChargerStatusCard)

ChargerStatusCard.propTypes = {
  chargerState: PropTypes.oneOf(keys(CHARGER_CARD_TEXT)),
  t: PropTypes.func.isRequired,
  zoneId: PropTypes.string,
  parkingSessionId: PropTypes.number,
  parkingSessionLoaded: PropTypes.bool,
  expiry: PropTypes.string,
  nfcActions: PropTypes.shape({
    loadChargerState: PropTypes.func,
    startCharger: PropTypes.func
  })
}
