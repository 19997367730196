import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import * as NFCActions from 'redux/actions'
import { isApplePayJsAvailable } from 'components/common/apple_pay_js/ApplePayJSHandler'
import { formatPrice } from 'app/helpers/formatting'
import { isRequestSuccessful } from 'app/helpers/api'
import { DEFAULT_RESERVATION_PLATE } from 'components/purchase/constants'
import applePayImg from 'components/_assets/apple-pay-mark-rgb-052318.svg'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { baseUrl } from 'app/helpers/requests'

import PurchaseButton from './PurchaseButton'

import './ApplePayPurchaseButton.scss'

const ApplePayButtonStatus = { UNKNOWN: 0, AVAILABLE: 1, NOT_AVAILABLE: 2 }

const formatAddress = (address = {}) => address.street

const mapStateToProps = ({ nfc }) => ({
  zone: nfc.zone,
  cartId: nfc.cart.id,
  plateNumber: nfc.plateNumber,
  assetTag: nfc.assetTag,
  supported: get(nfc.merchantInfo, ['apple_pay_gateway', 'supported']),
  merchantId: get(nfc.merchantInfo, [
    'apple_pay_gateway',
    'merchant_identifier'
  ]),
  displayName: get(nfc.merchantInfo, ['apple_pay_gateway', 'display_name'])
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class ApplePayPurchaseButton extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      applePayButtonStatus: ApplePayButtonStatus.UNKNOWN
    }
  }

  componentDidMount() {
    const { merchantId } = this.props

    if (merchantId) {
      this.checkApplePayVisibility(merchantId)
    }
  }

  componentDidUpdate(prevProps) {
    const { merchantId } = this.props

    if (!prevProps.merchantId && merchantId) {
      this.checkApplePayVisibility(merchantId)
    }
  }

  handleSubmit = debounce(
    // eslint-disable-next-line react/destructuring-assignment
    this.props.nfcActions.createParkingSessionApplePay,
    500,
    {
      leading: true,
      trailing: false
    }
  )

  checkApplePayVisibility = (merchantId) => {
    try {
      const canMakePayments = isApplePayJsAvailable(merchantId)
      this.setState({
        applePayButtonStatus: canMakePayments
          ? ApplePayButtonStatus.AVAILABLE
          : ApplePayButtonStatus.NOT_AVAILABLE
      })
    } catch (e) {
      this.setState({
        applePayButtonStatus: ApplePayButtonStatus.NOT_AVAILABLE
      })
    }
  }

  handleClick = () => {
    const { disabled } = this.props

    if (!disabled) {
      this.openApplePay()
    }
  }

  openApplePay = () => {
    const {
      cart: {
        total,
        id: cartId,
        currency_code: currencyCode,
        extend_id: extendId
      },
      zone: { address },
      assetTag,
      disabled,
      displayName
    } = this.props

    let countryPaymentInfo = {}

    if (currencyCode === 'USD') {
      countryPaymentInfo = {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover']
      }
    } else {
      countryPaymentInfo = {
        countryCode: 'CA',
        currencyCode: 'CAD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'interac']
      }
    }

    const paymentRequest = {
      ...countryPaymentInfo,
      requiredShippingContactFields: ['email'],
      requiredBillingContactFields: ['postalAddress'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: `${displayName} - ${formatAddress(address)}`,
        amount: formatPrice(total)
      }
    }

    const analyticsData = {
      is_extension: !!extendId,
      asset_tag: assetTag
    }

    trackEvent('Apple Pay Purchase Started', analyticsData)

    const session = new ApplePaySession(2, paymentRequest)

    const getSession = (url) => {
      return new Promise((resolve, reject) => {
        const apiURL = baseUrl(GUEST_API_BASE)
        const requestUrl = `${apiURL(
          `carts/${cartId}/apple_pay_merchant_sessions`
        )}`
        const xhr = new XMLHttpRequest()
        xhr.open('POST', requestUrl)

        // These callbacks need `this` to be
        // correctly bound to the the xhr object.
        // Please don't change them to fat-arrows
        // without making sure the code still works
        xhr.onload = function handleLoad() {
          if (this.status < 200 || this.status >= 300) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              status: this.status,
              statusText: xhr.statusText
            })
          }

          return resolve(JSON.parse(xhr.response))
        }

        xhr.onerror = function handleError() {
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject({
            status: this.status,
            statusText: xhr.statusText
          })
        }

        xhr.setRequestHeader('Content-Type', 'application/json')

        // eslint-disable-next-line no-promise-executor-return
        return xhr.send(JSON.stringify({ url }))
      })
    }

    session.onvalidatemerchant = (event) => {
      const { validationURL } = event
      getSession(validationURL).then((response) => {
        const data = JSON.parse(response.data)
        session.completeMerchantValidation(data)
      })
    }

    session.onpaymentauthorized = (event) => {
      const { onSuccess } = this.props
      const { payment } = event
      const {
        token,
        billingContact: { postalCode: avsCode },
        shippingContact: { emailAddress }
      } = payment

      this.handleSubmit(
        cartId,
        DEFAULT_RESERVATION_PLATE,
        emailAddress,
        token,
        avsCode
      ).then((json) => {
        if (isRequestSuccessful(json)) {
          trackEvent('Apple Pay Purchase Completion Succeeded', analyticsData)
          session.completePayment(ApplePaySession.STATUS_SUCCESS)
          onSuccess({ parkingSession: json.data })
        } else {
          trackEvent('Apple Pay Purchase Completion Failed', analyticsData)
          session.completePayment(ApplePaySession.STATUS_FAILURE)
        }
      })
    }

    if (!disabled) {
      session.begin()
    }
  }

  render() {
    const { applePayButtonStatus } = this.state
    const { t, disabled, supported } = this.props

    if (applePayButtonStatus !== ApplePayButtonStatus.AVAILABLE) {
      return null
    }

    if (!supported) {
      return null
    }

    return (
      <div>
        <PurchaseButton
          className="NFCApplePayPurchaseButton"
          onClick={this.handleClick}
          disabled={disabled}
        >
          &nbsp;
          <img
            className="NFCApplePayPurchaseButton--wordmark"
            src={applePayImg}
          />
        </PurchaseButton>
        <div className="NFCApplePayPurchaseButton--or">{t('nfc.zone.or')}</div>
      </div>
    )
  }
}

ApplePayPurchaseButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  supported: PropTypes.bool,
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency_code: PropTypes.string.isRequired,
    extend_id: PropTypes.number.isRequired,
    total: PropTypes.string
  }).isRequired,
  zone: PropTypes.shape({
    address: PropTypes.shape({
      street: PropTypes.string
    })
  }),
  plateNumber: PropTypes.string,
  assetTag: PropTypes.string,
  nfcActions: PropTypes.shape({
    createParkingSessionApplePay: PropTypes.func
  }),
  merchantId: PropTypes.string,
  displayName: PropTypes.string,
  onSuccess: PropTypes.func.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApplePayPurchaseButton
)
