import amex from './_assets/payment_cards/cards-color_amex.svg'
import discover from './_assets/payment_cards/cards-color_discover.svg'
import mastercard from './_assets/payment_cards/cards-color_mastercard.svg'
import visa from './_assets/payment_cards/cards-color_visa.svg'
import paypal from './_assets/payment_cards/paypal-add.svg'
import oneCard from './_assets/payment_cards/cards-color_one_card.svg'
import applePay from './_assets/payment_cards/cards-color_apple_pay.svg'
import payroll from './_assets/payment_cards/payroll-icon.svg'
import interac from './_assets/payment_cards/color_interac.svg'
import googlePay from './_assets/payment_cards/google-pay-mark.svg'
import generic from './_assets/payment_cards/cards-generic-credit-card.svg'

import {
  AMERICAN_EXPRESS,
  DISCOVER,
  MASTERCARD,
  VISA,
  PAYPAL,
  PAYMENT_CARD_TYPES,
  ONE_CARD,
  APPLE_PAY,
  GOOGLE_PAY,
  PAYROLL_DEDUCTION,
  INTERAC,
  GENERIC
} from './constants'

export const cardImages = {
  [AMERICAN_EXPRESS]: amex,
  [DISCOVER]: discover,
  [MASTERCARD]: mastercard,
  [VISA]: visa,
  [PAYPAL]: paypal,
  [ONE_CARD]: oneCard,
  [APPLE_PAY]: applePay,
  [PAYROLL_DEDUCTION]: payroll,
  [INTERAC]: interac,
  [GOOGLE_PAY]: googlePay,
  [GENERIC]: generic
}

export function paymentTypeToIcon(cardType) {
  const icon = cardImages[PAYMENT_CARD_TYPES[cardType]]
  return icon || cardImages[GENERIC]
}
