import isEmpty from 'lodash/isEmpty'

export const formatPhoneNumber = (phoneNumber) => {
  if (isEmpty(phoneNumber)) return phoneNumber

  const captures = phoneNumber
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  const chunk1 = isEmpty(captures[2]) ? captures[1] : `(${captures[1]}) `
  const chunk2 = isEmpty(captures[3]) ? captures[2] : `${captures[2]}-`
  const chunk3 = captures[3]

  return [chunk1, chunk2, chunk3].join('')
}

export const parsePhoneNumber = (phoneNumber) =>
  phoneNumber.toString().replace(/[^\d]/g, '')
