import { Modal } from 'semantic-ui-react'
import { PropTypes } from 'prop-types'
import cvvImg from 'components/_assets/cvv.svg'
import Button from 'components/common/Button'
import CloseIcon from './_assets/close-icon.svg'

import styles from './CvvModal.module.scss'

const CvvModal = ({ t, isOpen, onClose }) => {
  return (
    <Modal open={isOpen} centered={false} className={styles.modal}>
      <div className={styles.container}>
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <img src={CloseIcon} alt="X Close Icon" />
        </button>
        <div className={styles.content}>
        <h2 className={styles.header}>
          {t('nfc.creditCardPayment.cvvModalHeader')}
        </h2>
        <p className={styles.message}>
          {t('nfc.creditCardPayment.cvvModalContent')}
        </p>
        <img className={styles.image} src={cvvImg} />
        <Button onClick={onClose}>{t('nfc.creditCardPayment.close')}</Button>
        </div>
      </div>
    </Modal>
  )
}

CvvModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default CvvModal
