import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import * as NFCActions from 'redux/actions'
import get from 'lodash/get'
import { isApplePayJsAvailable } from 'components/common/apple_pay_js/ApplePayJSHandler'
import { formatPrice } from 'app/helpers/formatting'
import { baseUrl } from 'app/helpers/requests'

import './TestButton.scss'

const ApplePayButtonStatus = { UNKNOWN: 0, AVAILABLE: 1, NOT_AVAILABLE: 2 }

const formatAddress = (address = {}) => address.street

const mapStateToProps = ({ nfc }) => ({
  zone: nfc.zone,
  cartId: nfc.cart.id,
  supported: get(nfc.merchantInfo, ['apple_pay_gateway', 'supported']),
  merchantId: get(nfc.merchantInfo, [
    'apple_pay_gateway',
    'merchant_identifier'
  ]),
  displayName: get(nfc.merchantInfo, ['apple_pay_gateway', 'display_name']),
  rates: nfc.rates
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class AppleTestButton extends PureComponent {
  // eslint-disable-next-line react/destructuring-assignment
  constructor(props) {
    super(props)
    this.state = {
      applePayButtonStatus: ApplePayButtonStatus.UNKNOWN
    }
  }

  componentDidMount() {
    const { merchantId, rates, cartId, nfcActions } = this.props

    const rateId = rates[0].id
    nfcActions.setRate(cartId, rateId)

    if (merchantId) {
      this.checkApplePayVisibility(merchantId)
    }
  }

  componentDidUpdate(prevProps) {
    const { merchantId } = this.props

    if (!prevProps.merchantId && merchantId) {
      this.checkApplePayVisibility(merchantId)
    }
  }

  checkApplePayVisibility = (merchantId) => {
    try {
      const canMakePayments = isApplePayJsAvailable(merchantId)
      this.setState({
        applePayButtonStatus: canMakePayments
          ? ApplePayButtonStatus.AVAILABLE
          : ApplePayButtonStatus.NOT_AVAILABLE
      })
    } catch (e) {
      this.setState({
        applePayButtonStatus: ApplePayButtonStatus.NOT_AVAILABLE
      })
    }
  }

  handleClick = () => {
    const {
      disabled,
    } = this.props

    if (!disabled) {
      this.openApplePay()
    }
  }
  
  openApplePay = () => {
    const {
      cart,
      zone: { address },
      disabled,
      displayName,
      setPayload,
      setHidePayload
    } = this.props
    
    const {
      total,
      id: cartId,
      currency_code: currencyCode
    } = cart
    
    let countryPaymentInfo = {}

    if (currencyCode === 'USD') {
      countryPaymentInfo = {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover']
      }
    } else {
      countryPaymentInfo = {
        countryCode: 'CA',
        currencyCode: 'CAD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'interac']
      }
    }

    const paymentRequest = {
      ...countryPaymentInfo,
      requiredShippingContactFields: ['email'],
      requiredBillingContactFields: ['postalAddress'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: `${displayName} - ${formatAddress(address)}`,
        amount: formatPrice(total)
      }
    }

    const session = new ApplePaySession(2, paymentRequest)

    const getSession = (url) => {
      return new Promise((resolve, reject) => {
        const apiURL = baseUrl(GUEST_API_BASE)
        const requestUrl = `${apiURL(
          `carts/${cartId}/apple_pay_merchant_sessions`
        )}`
        const xhr = new XMLHttpRequest()
        xhr.open('POST', requestUrl)

        // These callbacks need `this` to be
        // correctly bound to the the xhr object.
        // Please don't change them to fat-arrows
        // without making sure the code still works
        xhr.onload = function handleLoad() {
          if (this.status < 200 || this.status >= 300) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              status: this.status,
              statusText: xhr.statusText
            })
          }

          return resolve(JSON.parse(xhr.response))
        }

        xhr.onerror = function handleError() {
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject({
            status: this.status,
            statusText: xhr.statusText
          })
        }

        xhr.setRequestHeader('Content-Type', 'application/json')

        // eslint-disable-next-line no-promise-executor-return
        return xhr.send(JSON.stringify({ url }))
      })
    }

    session.onvalidatemerchant = (event) => {
      const { validationURL } = event
      getSession(validationURL).then((response) => {
        const data = JSON.parse(response.data)
        session.completeMerchantValidation(data)
      })
      .catch((error) => console.error(error))
    }

    session.onpaymentauthorized = (event) => {
      const paymentData = get(event, 'payment.token.paymentData', '')
      setPayload({ apple_payment_data: paymentData })
      setHidePayload(false)

      session.abort()
    }

    if (!disabled) {
      session.begin()
    }
  }

  render() {
    const { applePayButtonStatus } = this.state
    const { supported, disabled } = this.props

    if (applePayButtonStatus !== ApplePayButtonStatus.AVAILABLE) {
      return null
    }

    if (!supported) {
      return null
    }

    return (
      <div>
        <button
          type="button"
          className={classNames('NFCTestButton NFCTestButton--apple', {
            disabled
          })}
          onClick={this.handleClick}
        >
          Apple Pay
        </button>
      </div>
    )
  }
}

AppleTestButton.propTypes = {
  disabled: PropTypes.bool,
  supported: PropTypes.bool,
  cart: PropTypes.shape({
    total: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency_code: PropTypes.string,
    extend_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  zone: PropTypes.shape({
    address: PropTypes.shape({
      street: PropTypes.string
    })
  }),
  merchantId: PropTypes.string,
  displayName: PropTypes.string,
  setPayload: PropTypes.func,
  setHidePayload: PropTypes.func,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  cartId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nfcActions: PropTypes.shape({
    setRate: PropTypes.func
  })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AppleTestButton)
