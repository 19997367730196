import { useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTruncateContentByLines } from 'hooks/useTruncateContentByLines'
import SeeMoreButton from 'components/common/SeeMoreButton'

import styles from './PurchaseCompleteCustomInstructions.module.scss'

const MAX_LINES = 3

const PurchaseCompleteCustomInstructions = () => {
  const { t } = useTranslation()
  const { htmlInstructions } = useSelector((state) => state.nfc.parkingSession)

  const contentRef = useRef(null)
  const { isTruncated, showButton, truncationStyles, toggleTruncation } =
    useTruncateContentByLines(contentRef, MAX_LINES, htmlInstructions)

  const buttonText = isTruncated
    ? t('nfc.purchaseComplete.seeMore')
    : t('nfc.purchaseComplete.seeLess')

  if (isEmpty(htmlInstructions)) {
    return null
  }

  return (
    <div className={styles.container}>
      <div
        ref={contentRef}
        style={truncationStyles}
        dangerouslySetInnerHTML={{ __html: htmlInstructions }}
      />
      {showButton && (
        <div className={styles.buttonContainer}>
          <SeeMoreButton onClick={toggleTruncation}>{buttonText}</SeeMoreButton>
        </div>
      )}
    </div>
  )
}

export default PurchaseCompleteCustomInstructions
