import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'app/helpers/formatting'
import PromoCode from './PromoCode'

import './CartInformation.scss'

const CartInformation = () => {
  const cart = useSelector((state) => state.nfc.cart)
  const {
    cart: cartLoaded,
    rates: ratesLoaded,
    rate: rateLoaded
  } = useSelector((state) => state.nfc.loaded)
  const { total, fee, price } = cart

  const { t } = useTranslation()

  const handlePriceDisplay = (amount) => {
    const { is_rate_selected: isRateSelected } = cart

    if (!(cartLoaded && ratesLoaded && rateLoaded)) {
      return ''
    }

    if (!isRateSelected) {
      return '—'
    }

    return `$${formatPrice(amount)}`
  }

  return (
    <div className="NFCCartInformation--container">
      <div className="NFCCartInformation--feesRow">
        <div>{t('nfc.zone.parkingFees')}</div>
        <div>{handlePriceDisplay(price)}</div>
      </div>
      <div className="NFCCartInformation--feesRow">
        <div>{t('nfc.zone.serviceFee')}</div>
        <div>{handlePriceDisplay(fee)}</div>
      </div>
      <PromoCode />
      <div className="NFCCartInformation--totalRow">
        <div>{t('nfc.zone.total')}</div>
        <div>{handlePriceDisplay(total)}</div>
      </div>
    </div>
  )
}

export default CartInformation
