export function isApplePayJsAvailable(merchantId) {
  let unavailable = false
  unavailable = unavailable || !window.ApplePaySession
  if (!unavailable) {
    // Note that it seems to sort-of work without the right merchant id,
    // (or even *any* merchant id, but we shouldn't rely on that behaviour
    // not changing in the future
    const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantId)
    promise.then((canMakePayments) => {
      unavailable = unavailable || !!canMakePayments
    })
  }
  return !unavailable
}
