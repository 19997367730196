import { useState, useEffect } from 'react'

// Custom hook to manage truncation of content based on the specified number of lines.
// ref: React ref object for the content to be truncated.
// maxLines: The maximum number of lines to display.
export const useTruncateContentByLines = (ref, maxLines, dependency) => {
  // State to manage whether content is currently truncated.
  const [isTruncated, setIsTruncated] = useState(true)
  // State to determine whether the "Show More/Less" button should be shown.
  // Even if isTruncated is false, this button may not be shown if the content does not exceed the maximum height.
  const [showButton, setShowButton] = useState(false)
  const [maxTrucHeight, setMaxTrucHeight] = useState(null)

  const truncationStyles = {
    maxHeight: isTruncated
      ? `${maxTrucHeight}px`
      : `${ref.current.scrollHeight}px`,
    overflow: 'hidden',
    textOverflow: isTruncated ? 'ellipsis' : 'clip',
    display: isTruncated ? '-webkit-box' : 'block',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isTruncated && maxLines,
    transition: 'max-height 0.3s ease'
  }

  // Function to calculate the height of truncated content based on number of max lines set.
  // It updates the visibility of the "Show More" button based on whether content needs to be truncated or not.
  const updateTruncatedHeight = () => {
    if (!ref.current) return

    const children = Array.from(ref.current.children)
    const totalContentHeight = ref.current.scrollHeight
    let truncatedHeight = 0
    let currentLine = 0
    const lineStyles = []

    // Helper function to handle margin overlap logic
    const getMaxMargin = (prevMarginBottom, marginTop) =>
      Math.max(prevMarginBottom, marginTop)

    // Iterate through each child element and calculate line heights
    children.forEach((child) => {
      const style = window.getComputedStyle(child)
      const lineHeight = Math.round(parseFloat(style.lineHeight))
      const marginTop = Math.round(parseFloat(style.marginTop))
      const marginBottom = Math.round(parseFloat(style.marginBottom))
      const borderTop = Math.round(parseFloat(style.borderTopWidth))
      const borderBottom = Math.round(parseFloat(style.borderBottomWidth))

      // Total height of child element
      const childHeight = child.clientHeight
      // Calculate the number of lines in the child element
      const linesInChild = Math.round(childHeight / lineHeight)

      // Special handling for <hr> tags, since they don't have a height/line
      if (child.tagName === 'HR' && lineStyles.length > 0) {
        // The element previous to the HR tag will have its height increased to include HR height
        // The margin bottom of previous element will now take the new margin bottom of HR tag
        const lastLine = lineStyles[lineStyles.length - 1]
        lastLine.lineHeight +=
          getMaxMargin(lastLine.marginBottom, marginTop) +
          borderTop +
          borderBottom
        lastLine.marginBottom = marginBottom
      } else {
        // Calculate line heights for the child element
        for (let i = 0; i < linesInChild; i += 1) {
          // Break if we've reached the maximum number of lines
          if (currentLine >= maxLines) break

          const isFirstLineOfChild = i === 0
          const isLastLineOfChild = i === linesInChild - 1
          const isFirstLineOfWholeContent = currentLine === 0

          const lineData = {
            lineHeight,
            marginTop:
              isFirstLineOfChild && !isFirstLineOfWholeContent ? marginTop : 0,
            marginBottom: isLastLineOfChild ? marginBottom : 0,
            borderTop: isFirstLineOfChild ? borderTop : 0,
            borderBottom: isLastLineOfChild ? borderBottom : 0
          }

          lineStyles.push(lineData)
          currentLine += 1
        }
      }
    })

    // Adjust margins for collapsed cases
    lineStyles.forEach((line, index) => {
      if (index > 0) {
        const prevLine = lineStyles[index - 1]
        // margin-bottom of previous line if less than margin-top of current line,
        // it will increase to current margin-top due to margin collapse
        // if margin-bottom of previous line is greater than margin-top of current line,
        // it will remain the same
        prevLine.marginBottom = getMaxMargin(
          prevLine.marginBottom,
          line.marginTop
        )
        lineStyles[index].marginTop = 0 // Since margins are collapsed, marginTop becomes 0.
      }
    })

    // Calculate the final truncated height
    truncatedHeight = lineStyles.reduce(
      (
        total,
        { lineHeight, marginTop, marginBottom, borderTop, borderBottom }
      ) =>
        total +
        lineHeight +
        marginTop +
        marginBottom +
        borderTop +
        borderBottom,
      0
    )

    setMaxTrucHeight(truncatedHeight)
    setShowButton(totalContentHeight > truncatedHeight)
  }

  useEffect(() => {
    updateTruncatedHeight() // Initial calculation

    window.addEventListener('resize', updateTruncatedHeight)

    return () => {
      window.removeEventListener('resize', updateTruncatedHeight)
    }
  }, [dependency, ref, updateTruncatedHeight]) // Re-run when the dependency changes (e.g. content)

  return {
    isTruncated,
    showButton,
    truncationStyles,
    toggleTruncation: () => setIsTruncated((prev) => !prev)
  }
}
