import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import {
  accountSelector,
  loadSelector,
  applicationTypeSelector
} from 'redux/reducers'
import { updateEmailAddress, emailReceipt, updateAccount } from 'redux/actions'
import history from 'app/history'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { redirectToSession } from 'components/purchase/helpers'
import Input from 'components/common/Input'
import Button from 'components/common/Button'
import AccountLoader from 'components/loaders/AccountLoader'
import ParkingSessionLoader from 'components/loaders/ParkingSessionLoader'
import { APPLICATION_TYPE } from 'components/constants'
import { ANONYMOUS_EMAIL } from './constants'
import ReceiptImage from './_assets/receipt.svg'

import styles from './ReceiptReminderRoute.module.scss'

const ReceiptReminderRoute = () => {
  const { sessionId: parkingSessionId } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const accountSession = useSelector(accountSelector)
  const loadSession = useSelector(loadSelector)
  const applicationType = useSelector(applicationTypeSelector)
  const isPublicXC = applicationType === APPLICATION_TYPE.publicXC

  const isEdit = location.state?.edit === true

  const { email_address: accountEmail } = accountSession
  const [emailAddress, setEmailAddress] = useState(accountEmail)
  const { account: accountLoaded, parkingSession: sessionLoaded } = loadSession

  useEffect(() => {
    if (accountLoaded) {
      if (accountEmail === ANONYMOUS_EMAIL) {
        dispatch(updateEmailAddress(''))
        setEmailAddress('')
      } else {
        setEmailAddress(accountEmail)
      }

      if (
        !isEmpty(accountEmail) &&
        accountEmail !== ANONYMOUS_EMAIL &&
        !isEdit
      ) {
        redirectToSession(parkingSessionId)
      }
    }
  }, [loadSession])

  const handleInputChange = (e) => {
    setErrorMessage('')
    setEmailAddress(e.target.value)
  }

  const handleSkip = () => {
    if (isPublicXC) {
      history.replace(`/p/session/${parkingSessionId}`)
    } else {
      redirectToSession(parkingSessionId)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    trackEvent('Contact Info Submit Button Pressed')

    if (isEmpty(emailAddress)) {
      setErrorMessage(t('nfc.reminder.formIncompleteError'))
      setLoading(false)
      return
    }

    if (accountLoaded) {
      dispatch(updateAccount({ emailAddress }))
        .then(() => dispatch(emailReceipt(parkingSessionId)))
        .then(() => {
          if (isPublicXC) {
            history.replace(`/p/session/${parkingSessionId}`)
          } else {
            redirectToSession(parkingSessionId)
          }
        })
        .catch(() => {
          setLoading(false)
          setErrorMessage(t('nfc.reminder.genericError'))
        })
    }
  }

  if (loading) {
    return (
      <Loader active size="large">
        {t('nfc.reminder.sendingEmail')}
      </Loader>
    )
  }

  if (!sessionLoaded || !accountLoaded) {
    return (
      <>
        <ParkingSessionLoader sessionId={parkingSessionId} />
        <AccountLoader />
        <Loader active size="large">
          {t('nfc.reminder.loading')}
        </Loader>
      </>
    )
  }

  return (
    <div className={styles.ReceiptReminderPage}>
      <div className={styles.ImageContainer}>
        <img
          src={ReceiptImage}
          alt="Illustration of receipt in blue circular background"
        />
      </div>
      <form className={styles.ReceiptReminderForm} onSubmit={handleSubmit}>
        <section className={styles.main}>
          <h1 className={styles.Heading}>{t('nfc.reminder.header')}</h1>
          <p className={styles.Instruction}>{t('nfc.reminder.instruction')}</p>
          <Input
            type="email"
            value={emailAddress}
            placeholder={t('nfc.reminder.emailPlaceholder')}
            onChange={handleInputChange}
            error={!isEmpty(errorMessage)}
            errorMessage={errorMessage}
          />
        </section>
        <Button type="submit" loading={loading}>
          {t('nfc.reminder.submit')}
        </Button>
      </form>
      <div className={styles.ButtonWrapper}>
        <Button onClick={handleSkip} link loading={loading}>
          {isEdit ? t('nfc.reminder.cancel') : t('nfc.reminder.skip')}
        </Button>
      </div>
    </div>
  )
}

export default ReceiptReminderRoute
