import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { companySelector, addressSelector } from 'redux/reducers'
import history from 'app/history'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import AssetTagLoader from 'components/tto/loaders/graphql/AssetTagLoader'
import Banner from '../shared/Banner'
import OrderedList from '../shared/OrderedList'
import Loader from '../shared/Loader'
import Button from '../shared/Button'
import ApplePay from './_assets/logo_apple_pay.svg'
import GooglePay from './_assets/logo_google_pay.svg'
import Mastercard from './_assets/logo_mastercard.svg'
import Visa from './_assets/logo_visa.svg'
import Amex from './_assets/logo_amex.svg'

import styles from './StartRoute.module.scss'

const StartRoute = () => {
  const { t } = useTranslation()
  const { assetTag } = useParams()
  const company = useSelector(companySelector)
  const address = useSelector(addressSelector)
  const { street } = address
  const {
    proper_name: properName,
    logo_thumbnail: logo,
    square_logo_thumbnail: squareLogo
  } = company

  const zoneId = useSelector((state) => state.nfc.zone.zone_id)
  const zoneImages = useSelector((state) => state.nfc.zone.images)
  const zoneImage = isEmpty(zoneImages) ? null : zoneImages[0]
  const nfcAssetTag = useSelector((state) => state.nfc.assetTag)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!isNil(nfcAssetTag)) {
      setLoading(false)
    }
  }, [nfcAssetTag])

  const handleScan = () => {
    history.push(`/t/${assetTag}/scan`)
  }

  const instructions = [
    {
      key: 0,
      heading: (
        <h3 className={styles.listHeader}>
          {t('nfc.tto.instructions.heading1')}
        </h3>
      ),
      description: t('nfc.tto.instructions.description1')
    },
    {
      key: 1,
      heading: (
        <h3 className={styles.listHeader}>
          {t('nfc.tto.instructions.heading2')}
        </h3>
      ),
      description: t('nfc.tto.instructions.description2')
    },
    {
      key: 2,
      heading: (
        <h3 className={styles.listHeader}>
          {t('nfc.tto.instructions.heading3')}
        </h3>
      ),
      description: t('nfc.tto.instructions.description3')
    }
  ]

  if (loading) {
    return (
      <>
        <AssetTagLoader name={assetTag} redirectUrl="/t/errors/not-found" />
        <Loader />
      </>
    )
  }

  return (
    <div className={styles.page}>
      <Banner
        zoneImage={zoneImage}
        logo={squareLogo || logo}
        properName={properName}
        street={street}
        zoneId={zoneId}
      />
      <div className={styles.instructions}>
        <h2 className={styles.title}>{t('nfc.tto.title')}</h2>
        <p className={styles.subtitle}>{t('nfc.tto.subtitle')}</p>
        <div className={styles.paymentMethods}>
          <img src={ApplePay} alt="Apple Pay" />
          <img src={GooglePay} alt="Google Pay" />
          <img src={Mastercard} alt="Mastercard" />
          <img src={Visa} alt="Visa" />
          <img src={Amex} alt="Amex" />
        </div>
        <OrderedList instructions={instructions} />
        <Button className={styles.scanTicketButton} onClick={handleScan}>
          {t('nfc.tto.scanTicket')}
        </Button>
      </div>
    </div>
  )
}

export default StartRoute
