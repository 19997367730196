import { useState } from 'react'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Input, Loader } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/client'
import { formatDateTime } from 'app/helpers/formatDateTime'
import DarkFooter from 'components/events/shared/DarkFooter'
import { paymentTypeToIcon } from 'components/helpers'
import { useDispatch, useSelector } from 'react-redux'
import * as actionTypes from 'redux/actionTypes'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import {
  parkingSessionSelector,
  addressSelector,
  companySelector,
  transactionHistorySelector
} from 'redux/reducers'
import { snakeCaseKeysDeep } from 'components/helpers/formatting'
import { GetParkingSession, SendXCReceiptEmail } from './operations'
import checkmarkGreen from '../../_assets/checkmark-green.svg'

import styles from './DigitalReceipt.module.scss'
import { PAYMENT_CARD_TYPES, ZERO } from '../../constants'

const DigitalReceipt = () => {
  const [t] = useTranslation()
  const { sessionId } = useParams()
  const [emailAddress, setEmailAddress] = useState('')
  const [isEmailSent, setisEmailSent] = useState(false)
  const [hasError, setHasError] = useState(false)
  const parkingSession = useSelector(parkingSessionSelector)
  const adddress = useSelector(addressSelector)
  const zoneId = useSelector((state) => state.nfc.zone.zone_id)
  const company = useSelector(companySelector)
  const transactionHistory = useSelector(transactionHistorySelector)
  const dispatch = useDispatch()
  const formatPrice = (priceFloat) => `$${parseFloat(priceFloat).toFixed(2)}`

  const [sendEmailNotification, { loading }] = useMutation(SendXCReceiptEmail, {
    variables: {
      input: {
        emailAddress,
        parkingSessionId: sessionId
      }
    },
    onCompleted: ({ sendXcReceiptEmail: { errors } }) => {
      if (isEmpty(errors)) {
        setisEmailSent(true)
      } else {
        setHasError(true)
      }
    }
  })

  // Refresh the session EVERY time, even post-purchase
  // otherwise we need to keep changes synced across graphql
  // and REST responses
  const { loading: loadingSession } = useQuery(GetParkingSession, {
    variables: { id: sessionId },
    onCompleted: ({ parkingSession: responseParkingSession }) => {
      const {
        zone,
        transactionHistory: txn,
        endTime: expiry,
        id,
        hashid,
        plateNumber,
        customInstructions,
        reservation
      } = responseParkingSession
      const { paymentDate, invoiceNumber, total: price } = txn
      // Little weird - plateNumber will always be the same for XC-Events,
      // and we don't need a start time
      dispatch({
        type: actionTypes.NFC_LOAD_PARKING_SESSION,
        expiry,
        id,
        hashid,
        plateNumber,
        customInstructions,
        reservation: snakeCaseKeysDeep(reservation),
        zone: snakeCaseKeysDeep(zone),
        transactionHistory: {
          cardType: txn.pcCardType,
          lastFour: txn.pcLastFour,
          paymentDate,
          invoiceNumber,
          price
        }
      })
    }
  })

  if (loadingSession) {
    return (
      <div>
        <Loader active />
      </div>
    )
  }

  const { proper_name: properName } = company
  const { customInstructions } = parkingSession

  const barcodeImageUrl = get(parkingSession, [
    'reservation',
    'barcode_image_url'
  ])

  const barcodeWidth = get(parkingSession, ['reservation', 'barcode_width'])

  const handleSubmitEmail = () => {
    if (isEmpty(emailAddress)) {
      setHasError(true)
    } else {
      sendEmailNotification()
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.hero} />
      <div className={styles.qrCard}>
        <h2 className={styles.qrCardHeader}>{t('nfc.event.scanOpenGate')}</h2>
        <p className={styles.troubleScanning}>
          {t('nfc.event.havingTrouble')} <br />
          {t('nfc.event.turnScreenBrightness')}
        </p>
        {barcodeImageUrl && (
          <img
            style={{ width: '100%', maxWidth: `${barcodeWidth}px` }}
            className={styles.qrCodeImg}
            src={barcodeImageUrl}
            alt="scannable"
          />
        )}
        {customInstructions && (
          <p className={styles.customInstruction}>{customInstructions}</p>
        )}
      </div>
      <div className={styles.qrCardDetail}>
        <div className={styles.eventDateTime}>
          <div className={styles.leftBox}>
            <div className={classNames(styles.heading, styles.headingGap)}>
              {t('nfc.event.sessionValidUntil')}
            </div>
            <div className={styles.showTime}>
              {formatDateTime(parkingSession.expiry).toFormat('t')}
            </div>
            <div className={styles.showDate}>
              {formatDateTime(parkingSession.expiry).toFormat('DD')}
            </div>
          </div>
        </div>
        <div className={styles.eventDetail}>
          <div className={styles.heading}>{t('nfc.event.location')}</div>
          <div className={styles.eventDetailValue}>
            {adddress.street}
            <br />
            {t('nfc.event.zoneId', { zoneId })} <br />
            {t('nfc.event.operatedBy', { properName })}
          </div>
          <div className={styles.eventDetailRow}>
            <div className={styles.heading}>{t('nfc.event.invoice')}</div>
            <div
              className={classNames(
                styles.eventDetailValue,
                styles.strUpperCase
              )}
            >
              {transactionHistory.invoiceNumber}
            </div>
          </div>

          <div className={styles.paymentDetail}>
            <div className={styles.paymentLeftBox}>
              <div className={styles.heading}>{t('nfc.event.payment')}</div>
              <div className={styles.eventDetailValue}>
                {formatPrice(transactionHistory.price)}
              </div>
              {transactionHistory.cardType !== ZERO && (
                <div
                  className={classNames(styles.eventDetailValue, styles.card)}
                >
                  <img
                    className={styles.visaImg}
                    src={paymentTypeToIcon(transactionHistory.cardType)}
                  />
                  &nbsp;
                  <div>
                    {`${PAYMENT_CARD_TYPES[transactionHistory.cardType]} ${
                      transactionHistory.lastFour
                    }`}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.paymentRightBox}>
              <div className={styles.heading}>{t('nfc.event.paymentDate')}</div>
              <div className={styles.eventDetailValue}>
                {formatDateTime(transactionHistory.paymentDate).toFormat('DD')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isEmailSent && (
        <div className={styles.qrCardDetail}>
          <div className={styles.doYouReceipt}>
            {t('nfc.event.doYouWantReceipt')}
          </div>
          <div className={classNames(styles.formInuput)}>
            <Input
              className={styles.input}
              value={emailAddress}
              error={hasError}
              disabled={loading}
              onChange={(e) => setEmailAddress(e.target.value)}
              placeholder={t('nfc.event.yourEmailAddress')}
            >
              <input onFocus={() => setHasError(false)} />
            </Input>
            <Button
              content={t('nfc.event.send')}
              primary
              fluid
              loading={loading}
              disabled={loading}
              className={styles.buttonSend}
              onClick={handleSubmitEmail}
            />
          </div>
        </div>
      )}
      {isEmailSent && (
        <div className={styles.qrCardDetail}>
          <div className={styles.emailSuccessReceipt}>
            <img className={styles.checkMark} src={checkmarkGreen} />
            {t('nfc.event.emailedReceipt', { emailAddress })}
          </div>
        </div>
      )}
      <div className={styles.darkFooter}>
        <DarkFooter t={t} />
      </div>
    </div>
  )
}

export default DigitalReceipt
