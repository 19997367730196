import URL from 'url-parse'
import URLSearchParams from 'url-search-params'
import fetch from 'isomorphic-fetch'
import CancelablePromise from 'app/helpers/promise'

export const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export const baseUrl = (base) => (relativePath, params) => {
  let url = new URL(`${base}/${relativePath}`)

  if (params) {
    const urlParams = new URLSearchParams()
    Object.keys(params).forEach((key) => {
      urlParams.append(key, params[key])
    })
    url = new URL(`${url}?${urlParams.toString()}`)
  }
  return url.toString()
}

export const cancelableFetch = (url, fetchInit) => {
  return new CancelablePromise(fetch(url, fetchInit))
}

export function addAppVersion(params) {
  return { ...params, app_version: APP_VERSION }
}
