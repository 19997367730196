import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { isRequestSuccessful } from 'app/helpers/api'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import { DEFAULT_RESERVATION_PLATE } from 'components/purchase/constants'
import * as NFCActions from 'redux/actions'

const mapStateToProps = ({ nfc: { plateNumber } }) => ({
  plateNumber
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

const PaypalCheckoutRoute = ({
  namespace,
  match: {
    params: { cartId, assetTag }
  },
  location: { search },
  history,
  nfcActions: { showProcessingTransactionLoader, createParkingSessionPayPal }
}) => {
  useEffect(() => {
    showProcessingTransactionLoader()

    const query = new URLSearchParams(search)
    const token = query.get('token')

    if (query && token) {
      createParkingSessionPayPal(cartId, DEFAULT_RESERVATION_PLATE, token).then(
        (json) => {
          if (isRequestSuccessful(json)) {
            trackEvent('Paypal Completion Succeeded')
            const { hashid } = json.data
            history.push(`${namespace}/session/${hashid}/sms`)
          } else {
            trackEvent('Paypal Completion Failed')
            // Include querysting as an indicator
            // to not auto-clear error content
            // when mounting the asset tag route
            history.push({
              pathname: `${namespace}/${assetTag}`,
              search: queryString.stringify({ preserveErrorsOnMount: true })
            })
          }
        }
      )
    }
  }, [])

  const { t } = useTranslation()

  return <ProcessingLoader t={t} />
}

PaypalCheckoutRoute.propTypes = {
  namespace: PropTypes.oneOf(['/p', '/e']).isRequired,
  plateNumber: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cartId: PropTypes.string.isRequired,
      assetTag: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  nfcActions: PropTypes.shape({
    createParkingSessionPayPal: PropTypes.func.isRequired,
    showProcessingTransactionLoader: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaypalCheckoutRoute)
