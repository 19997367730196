/* eslint no-multi-spaces: ["error", { ignoreEOLComments: true }] */
import * as actionTypes from 'redux/actionTypes'
import isEmpty from 'lodash/isEmpty'
import every from 'lodash/every'
import get from 'lodash/get'
import pick from 'lodash/pick'
import identity from 'lodash/identity'
import isNil from 'lodash/isNil'

const GUEST_USER_KEY = 'guestUser'
const GUEST_PLATE_NUMBER_KEY = 'guestPlateNumber'
const GUEST_USER_PURCHASED_AT_KEY = 'purchasedAt'

// from https://usehooks.com/useLocalStorage/

export const storedNfcAccount = () => {
  try {
    const storedState = localStorage.getItem(GUEST_USER_KEY)
    if (storedState) {
      return JSON.parse(storedState)
    }
    return {}
  } catch (e) {
    return {}
  }
}

const storedPlateNumber = () => {
  try {
    const storedPlate = localStorage.getItem(GUEST_PLATE_NUMBER_KEY)
    return storedPlate || ''
  } catch (e) {
    return ''
  }
}

const initialPurchaseTimestamp = () => {
  try {
    const initialPurchase = localStorage.getItem(GUEST_USER_PURCHASED_AT_KEY)
    if (!initialPurchase) {
      return null
    }
    return new Date(initialPurchase)
  } catch (e) {
    return null
  }
}

const emptyState = {
  assetTag: null,
  merchantInfo: {},
  allTagData: {},
  zone: {},
  cart: {},
  rates: [],
  rules:{
    zone: false,
    rate: false,
  },
  plateNumber: '',
  account: {},
  initialPurchaseTimestamp: null,
  parkingSession: {
    expiry: null,
    id: null,
    hashid: null
  },
  transactionHistory: {},
  zoneOption: null,
  selectedRateId: null,
  processingTransaction: false,
  error: null,
  loaded: {
    assetTag: false, // AssetTag
    cart: false, // Cart creation
    rates: false, // Rates from cart
    rate: false, // Adding rate to cart
    account: false, // Account
    zone: false, // Zone used for zone info
    parkingSession: false, // Parking Session extensions maybe summary
    plateNumber: false
  },
  isAlreadyPurchasedVisit: false,
  activeSessions: []
}

const initialState = {
  ...emptyState,
  plateNumber: storedPlateNumber(),
  account: storedNfcAccount(),
  initialPurchaseTimestamp: initialPurchaseTimestamp(),
  loaded: {
    ...emptyState.loaded,
    plateNumber: !isEmpty(storedPlateNumber())
  }
}

export const getIsPurchaseable = ({ rates }) =>
  !isEmpty(rates) &&
  every(rates, (rate) => rate.behaviour_type !== 'notification')

// eslint-disable-next-line default-param-last
export default function nfcReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.NFC_LOADING_ASSET_TAG: {
      const loaded = {
        ...state.loaded,
        assetTag: false,
        zone: false
      }
      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOADING_CART: {
      const loaded = {
        ...state.loaded,
        cart: false
      }
      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOADING_RATES: {
      const loaded = {
        ...state.loaded,
        rates: false
      }
      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOAD_ASSET_TAG: {
      const { zone, assetTag, allTagData } = action
      const loaded = {
        ...state.loaded,
        assetTag: true,
        zone: true
      }
      return {
        ...state,
        zone,
        assetTag,
        allTagData,
        loaded
      }
    }
    case actionTypes.NFC_LOADING_PARKING_SESSION: {
      const loaded = {
        ...state.loaded,
        parkingSession: false,
        zone: false
      }
      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOAD_PARKING_SESSION: {
      const {
        zone,
        expiry,
        startTime,
        id,
        hashid,
        transactionHistory,
        customInstructions,
        customReceiptText,
        reservation,
        plateNumber,
        htmlInstructions,
        timezone,
        voided,
        extendedSession
      } = action

      const loaded = {
        ...state.loaded,
        parkingSession: true,
        zone: true
      }
      const parkingSession = {
        expiry: new Date(expiry),
        startTime: new Date(startTime),
        id,
        hashid,
        customInstructions,
        customReceiptText,
        reservation,
        htmlInstructions,
        timezone,
        voided,
        extendedSession
      }

      if (!isNil(plateNumber)) {
        localStorage.setItem(GUEST_PLATE_NUMBER_KEY, plateNumber)
      }
      const formattedPlateNumber = isNil(plateNumber) ? '' : plateNumber

      return {
        ...state,
        zone,
        parkingSession,
        plateNumber: formattedPlateNumber,
        loaded,
        transactionHistory
      }
    }
    case actionTypes.NFC_SET_PLATE_NUMBER: {
      const { plateNumber } = action

      // naive first-pastt
      if (!isNil(plateNumber)) {
        localStorage.setItem(GUEST_PLATE_NUMBER_KEY, plateNumber)
      }
      const formattedPlateNumber = isNil(plateNumber) ? '' : plateNumber

      return {
        ...state,
        plateNumber: formattedPlateNumber
      }
    }
    case actionTypes.NFC_LOAD_CART: {
      const { cart } = action
      const { merchant_info: merchantInfo } = cart
      const loaded = {
        ...state.loaded,
        cart: true,
        rate: true
      }
      const selectedRateId = cart.selected_rate

      return {
        ...state,
        cart,
        merchantInfo,
        loaded,
        selectedRateId
      }
    }
    case actionTypes.NFC_CLEAR_CART: {
      const loaded = {
        ...state.loaded,
        cart: false
      }
      return {
        ...state,
        cart: {},
        loaded
      }
    }
    case actionTypes.NFC_LOAD_RATES: {
      const { rates, rules } = action
      const loaded = {
        ...state.loaded,
        rates: true,
      }

      return {
        ...state,
        rates,
        rules,
        loaded
      }
    }
    case actionTypes.NFC_SETTING_RATE: {
      const loaded = {
        ...state.loaded,
        rate: false
      }
      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_SET_RATE: {
      const loaded = {
        ...state.loaded,
        rate: true
      }
      const { rateId, cart } = action
      return {
        ...state,
        loaded,
        cart,
        selectedRateId: rateId
      }
    }
    case actionTypes.NFC_LOADING_ACCOUNT: {
      const loaded = {
        ...state.loaded,
        account: false
      }
      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOAD_ACCOUNT: {
      const { account } = action
      const { an_tag } = account // eslint-disable-line camelcase

      localStorage.setItem(GUEST_USER_KEY, JSON.stringify({ an_tag }))

      const loaded = {
        ...state.loaded,
        account: true
      }

      return {
        ...state,
        account,
        loaded
      }
    }
    case actionTypes.NFC_UPDATE_ACCOUNT_MOBILE_NUMBER: {
      const { mobileNumber } = action
      const { account: existingAccount } = state

      return {
        ...state,
        account: { ...existingAccount, mobile_phone_number: mobileNumber }
      }
    }
    case actionTypes.NFC_UPDATE_ACCOUNT_EMAIL: {
      const { emailAddress } = action
      const { account: existingAccount } = state

      return {
        ...state,
        account: { ...existingAccount, email_address: emailAddress }
      }
    }
    case actionTypes.NFC_ZERO_DOLLAR_PURCHASING: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_CREDIT_CARD_TOKEN_PURCHASING: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_APPLE_PAY_PURCHASING: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_GOOGLE_PAY_PURCHASING: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_PAYPAL_ORDER_REQUESTED: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_PAYPAL_ORDER_FAILED: {
      return {
        ...state,
        processingTransaction: false,
        error: 'order_paypal'
      }
    }
    case actionTypes.NFC_PAYPAL_PURCHASING: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_START_PROCESSING_TRANSACTION: {
      return {
        ...state,
        processingTransaction: true
      }
    }
    case actionTypes.NFC_STOP_PROCESSING_TRANSACTION: {
      return {
        ...state,
        processingTransaction: false
      }
    }
    case actionTypes.NFC_PURCHASE_COMPLETED: {
      const { parkingSession, transactionHistory, zone } = action
      const { plateNumber } = parkingSession
      const loaded = {
        ...state.loaded,
        parkingSession: true,
        rates: false
      }

      // Booo side-effects in reducers
      const timestamp = state.initialPurchaseTimestamp || new Date()

      try {
        localStorage.setItem(GUEST_USER_PURCHASED_AT_KEY, timestamp)
        localStorage.removeItem(GUEST_PLATE_NUMBER_KEY)
      } catch (e) {
        // no-op
      }

      return {
        ...state,
        loaded,
        parkingSession,
        transactionHistory,
        zone,
        plateNumber,
        initialPurchaseTimestamp: timestamp,
        processingTransaction: false
      }
    }
    case actionTypes.NFC_PURCHASE_FAILED: {
      return {
        ...state,
        processingTransaction: false
      }
    }
    case actionTypes.NFC_LOADING_VEHICLE: {
      const loaded = {
        ...state.loaded,
        plateNumber: false
      }

      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOAD_VEHICLE: {
      let { plateNumber } = action
      const { validation_type: validationType } = state.zone
      // Didn't import NFC_ZONE_VALIDATION_TYPES.by_space because it was breaking tests
      if (validationType === 'by_space') {
        plateNumber = ''
      }

      const loaded = {
        ...state.loaded,
        plateNumber: true
      }

      if (!isNil(plateNumber)) {
        localStorage.setItem(GUEST_PLATE_NUMBER_KEY, plateNumber)
      }
      const formattedPlateNumber = isNil(plateNumber) ? '' : plateNumber

      return {
        ...state,
        plateNumber: formattedPlateNumber,
        loaded
      }
    }
    case actionTypes.NFC_LOAD_VEHICLE_FAILED: {
      const loaded = {
        ...state.loaded,
        plateNumber: true
      }

      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_ADDING_PROMO_CODE: {
      const loaded = {
        ...state.loaded,
        cart: false
      }

      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_ADD_PROMO_CODE: {
      const { cart } = action

      const loaded = {
        ...state.loaded,
        cart: true
      }

      return {
        ...state,
        loaded,
        cart
      }
    }
    case actionTypes.NFC_ADD_PROMO_CODE_FAILED: {
      const loaded = {
        ...state.loaded,
        cart: true
      }

      return {
        ...state,
        loaded
      }
    }
    case actionTypes.NFC_LOADING_ZONE: {
      return {
        ...state,
        loaded: {
          ...state.loaded,
          zone: false
        }
      }
    }
    case actionTypes.NFC_LOAD_ZONE: {
      const { zone } = action
      return {
        ...state,
        zone,
        loaded: {
          ...state.loaded,
          zone: true
        }
      }
    }
    case actionTypes.NFC_LOAD_ZONE_FAILED: {
      return {
        ...state,
        loaded: {
          ...state.loaded,
          zone: false
        }
      }
    }
    case actionTypes.NFC_LOAD_ZONE_INVENTORY: {
      return state
    }
    case actionTypes.NFC_LOAD_ZONE_OPTION: {
      const { zoneOption } = action
      return {
        ...state,
        zoneOption
      }
    }
    case actionTypes.NFC_CLEAR_ZONE_OPTION: {
      return {
        ...state,
        zoneOption: null,
        loaded: {
          ...state.loaded,
          // unload cart and rates so they get reloaded on zone option reselect
          cart: false,
          rates: false
        }
      }
    }
    case actionTypes.NFC_ADD_ERROR: {
      const { error } = action

      return {
        ...state,
        error
      }
    }
    case actionTypes.NFC_REMOVE_ERROR: {
      const error = null
      return {
        ...state,
        error
      }
    }
    case actionTypes.NFC_CLEAR_ERRORS: {
      const error = null

      return {
        ...state,
        error
      }
    }
    case actionTypes.NFC_LOGOUT: {
      localStorage.removeItem(GUEST_USER_KEY)
      localStorage.removeItem(GUEST_PLATE_NUMBER_KEY)
      localStorage.removeItem(GUEST_USER_PURCHASED_AT_KEY)

      return emptyState
    }
    // TODO this is going to update zone rather than session
    case actionTypes.NFC_LOAD_CHARGER_STATE: {
      const { charger_state } = action.zone // eslint-disable-line camelcase
      // must destructure session as action.session only contains charger_state
      return { ...state, zone: { ...state.zone, charger_state } }
    }
    case actionTypes.NFC_START_CHARGER: {
      const { charger_state } = action.zone // eslint-disable-line camelcase
      // charger_state from this action will always be 'pending'
      return { ...state, zone: { ...state.zone, charger_state } }
    }
    case actionTypes.NFC_GET_ACTIVE_SESSIONS: {
      return {
        ...state,
        activeSessions: action.parkingSessions.map((val) => {
          return {
            price: val.transaction_history.price,
            cardType: val.transaction_history.pc_card_type,
            lastFour: val.transaction_history.pc_last_four,
            expiry: val.expiry,
            startTime: val.start_time,
            plateNumber: val.plate_number,
            hashid: val.hashid
          }
        }),
        isAlreadyPurchasedVisit: true
      }
    }
    case actionTypes.NFC_RESET_ZONE: {
      return {
        ...state,
        zone: {},
        loaded: {
          assetTag: false,
          zone: false,
        }
      }
    }
    case actionTypes.NFC_LOAD_MERCHANT_INFO: {
      const { merchantInfo } = action
      return {
        ...state,
        merchantInfo
      }
    }
    case actionTypes.NFC_CLEAR_USER_INFO: {
      localStorage.removeItem(GUEST_USER_KEY)
      localStorage.removeItem(GUEST_PLATE_NUMBER_KEY)
      localStorage.removeItem(GUEST_USER_PURCHASED_AT_KEY)
      return {
        ...state,
        plateNumber: '',
        account: {}
      }
    }
    default:
      return state
  }
}
export const accountSelector = (state) => state.nfc.account || {}
export const authTokenSelector = (state) => accountSelector(state).an_tag
export const cartSelector = (state) => state.nfc.cart || {}
export const companySelector = (state) => state.nfc.zone.company || {}
export const transactionHistorySelector = (state) =>
  state.nfc.transactionHistory || {}

export const addressSelector = (state) => get(state, 'nfc.zone.address', {})
export const purchaseElementsLoadedSelector = (state) => {
  const checks = pick(state.nfc.loaded, ['cart', 'rates', 'rate'])
  return Object.values(checks).every(identity)
}
export const ratesSelector = (state) => get(state, 'nfc.rates', [])
export const parkingSessionSelector = (state) =>
  get(state, 'nfc.parkingSession', {})
export const loadSelector = (state) => state.nfc.loaded
export const zoneSelector = (state) => state.nfc.zone
export const applicationTypeSelector = (state) =>
  get(state, 'nfc.allTagData.application_type', '')
