import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import classNames from 'classnames'

import warningImg from './_assets/warning.png'

import styles from './MobileNumberRoute.module.scss'

const MobileNumberSkip = ({ goBack, goToSession }) => {
  const [t] = useTranslation()

  return (
    <div className={styles.page}>
      <div className={styles.body}>
        <h2 className={styles.header}>{t('nfc.event.areYouSure')}</h2>
        <img className={styles.warningImg} src={warningImg} alt="warning" />
        <div
          className={styles.copy}
          dangerouslySetInnerHTML={{ __html: t('nfc.event.skipCopy') }}
        />
        <Button
          onClick={goToSession}
          className={styles.roundButton}
          primary
          fluid
        >
          {t('nfc.event.skipButton')}
        </Button>
        <Button
          onClick={goBack}
          className={classNames(styles.roundButton, styles.invertedButton)}
          primary
          inverted
          fluid
        >
          {t('nfc.event.back')}
        </Button>
      </div>
    </div>
  )
}

MobileNumberSkip.propTypes = {
  goBack: PropTypes.func.isRequired,
  goToSession: PropTypes.func.isRequired
}

export default MobileNumberSkip
