import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Input from 'components/common/Input'

import styles from './plateNumber.module.scss'

const mapStateToProps = ({ nfc }) => ({
  plateNumber: nfc.plateNumber,
  loaded: nfc.loaded.parkingSession
})

class PlateNumber extends Component {
  handlePlateDisplay = () => {
    const { t, loaded, plateNumber: plate } = this.props

    return loaded ? plate : t('nfc.extend.loadingPlate')
  }

  render() {
    const { loaded } = this.props

    return (
      <Input
        className={classNames(styles.plateInput, { [styles.loading]: !loaded })}
        value={this.handlePlateDisplay()}
        disabled
      />
    )
  }
}

PlateNumber.propTypes = {
  t: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  plateNumber: PropTypes.string
}

export default connect(mapStateToProps)(PlateNumber)
