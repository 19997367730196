import { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector, useDispatch } from 'react-redux'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { NFC_ZONE_VALIDATION_TYPES } from 'components/constants'
import { setParkingPlate } from 'redux/actions'
import styles from './ZoneOptionLabel.module.scss'

const ZoneOptionLabel = () => {
  const dispatch = useDispatch()
  const zoneOption = useSelector((state) => state.nfc.zoneOption)
  const { validation_type: validationType } = useSelector(
    (state) => state.nfc.zone
  )

  useEffect(() => {
    if (validationType === NFC_ZONE_VALIDATION_TYPES.by_zone_option_key) {
      dispatch(setParkingPlate(zoneOption?.key))
    }
  }, [zoneOption, validationType, dispatch])

  if (
    isEmpty(zoneOption) ||
    (isEmpty(zoneOption.key) && isEmpty(zoneOption.key_label))
  ) {
    return null
  }

  const { key, key_label: label } = zoneOption

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.left}>{startCase(toLower(label))}</div>
        <div>{key}</div>
      </div>
    </div>
  )
}

export default ZoneOptionLabel
