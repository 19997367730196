import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import AppSignal from '@appsignal/javascript'
import { ErrorBoundary } from '@appsignal/react'
import Router from 'router'
import store from 'redux/store'
import i18n from './i18n' // eslint-disable-line no-unused-vars
import client from './apolloClient'

import 'vendor/semantic/dist/semantic.min.css'

const appsignal = new AppSignal({
  key: APPSIGNAL_KEY
})

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ErrorBoundary instance={appsignal}>
          <Router />
        </ErrorBoundary>
      </Provider>
    </ApolloProvider>
  )
}

export default App
