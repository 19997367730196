import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { zoneSelector } from 'redux/reducers'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { useTruncateContentByLines } from 'hooks/useTruncateContentByLines'
import SeeMoreButton from 'components/common/SeeMoreButton'
import Modal from './ZoneDescriptionModal'

import styles from './ZoneDescriptionComponent.module.scss'

const MAX_LINES = 4

const ZoneDescriptionComponent = () => {
  const { html_description: zoneDescription } = useSelector(zoneSelector)
  const { t } = useTranslation()

  const contentRef = useRef(null)
  const { showButton, truncationStyles } = useTruncateContentByLines(
    contentRef,
    MAX_LINES,
    zoneDescription
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (isEmpty(zoneDescription)) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div
          dangerouslySetInnerHTML={{
            __html: zoneDescription
          }}
          style={truncationStyles}
          ref={contentRef}
        />
        {showButton && (
          <SeeMoreButton
            onClick={() => setIsModalOpen(true)}
            className={styles.seeMoreButton}
          >
            {t('nfc.zone.zoneDescription.seeMore')}
          </SeeMoreButton>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        zoneDescription={zoneDescription}
      />
    </div>
  )
}

export default ZoneDescriptionComponent
