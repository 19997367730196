import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { companySelector, addressSelector } from 'redux/reducers'
import {
  PAYPAL_PURCHASE_FLOWS,
  PAYPAL_PURCHASE_SOURCES
} from 'components/purchase/constants'

import AllPurchaseButtons from 'components/events/purchase/AllPurchaseButtons'
import DarkFooter from 'components/events/shared/DarkFooter'

import ProcessingLoader from 'components/purchase/ProcessingLoader'
import AssetTagLoader from 'components/loaders/graphql/AssetTagLoader'
import AccountLoader from 'components/loaders/graphql/AccountLoader'
import CartLoader from 'components/loaders/graphql/CartLoader'
import RatesLoader from 'components/loaders/graphql/RatesLoader'
import CartInformation from 'components/events/StartRoute/CartInformation'
import CreditCardPayment from 'components/events/CreditCardPayment'

import styles from './StartRoute.module.scss'

const StartRoute = () => {
  const { assetTag } = useParams()
  const history = useHistory()
  const [t] = useTranslation()
  const [isCCScreenOpen, setIsCCScreenOpen] = useState(false)

  const company = useSelector(companySelector)
  const address = useSelector(addressSelector)
  const { street } = address
  const { proper_name: properName } = company

  const onSuccess = ({ parkingSession }) => {
    return history.push(`/e/session/${parkingSession.hashid}/sms`)
  }

  if (isCCScreenOpen)
    return (
      <CreditCardPayment
        onSuccess={onSuccess}
        setIsCCScreenOpen={setIsCCScreenOpen}
      />
    )

  return (
    <>
      <AssetTagLoader name={assetTag}>
        <CartLoader>
          <AccountLoader />
          <RatesLoader />
        </CartLoader>
      </AssetTagLoader>
      <div className={styles.page}>
        <div className={styles.hero}>
          <div className={styles.heroIdentifier}>{properName}</div>
          <div>{street}</div>
        </div>

        <div className={styles.ratesWrapper}>
          <CartInformation />
          <div className={styles.terms}>
            {t('nfc.event.termsCopy')}&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href={WHITELABEL_CONFIG.TERMS_URL}
            >
              {t('nfc.event.termsLink')}
            </a>
          </div>
        </div>

        <AllPurchaseButtons
          t={t}
          onSuccess={onSuccess}
          purchaseFlow={PAYPAL_PURCHASE_FLOWS.checkout}
          source={PAYPAL_PURCHASE_SOURCES.event}
          setIsCCScreenOpen={setIsCCScreenOpen}
        />
        <DarkFooter t={t} />
        <ProcessingLoader t={t} />
      </div>
    </>
  )
}

export default StartRoute
