import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Container, Image } from 'semantic-ui-react'
import catImg from 'components/errors/_assets/general-error-cat.svg'
import { wordmarkDark } from 'components/common/Logos'

import './NFCExtensionExpiredRoute.scss'

const NFCExtensionExpiredRoute = ({ t }) => {

  return (
    <Container className="NFCExtensionExpiredRoute--routeContainer">
      <div className="NFCExtensionExpiredRoute--wrapper">
        <Image
          size="tiny"
          src={wordmarkDark.default}
          className="NFCExtensionExpiredRoute--honkWordmark"
        />
        <div>
          <Image centered size="large" src={catImg} />
           <div className="NFCExtensionExpiredRoute--header">
            {t('nfc.errorPages.extensionExpiredHeader')}
          </div>
          <div className="NFCExtensionExpiredRoute--copy">
            {t('nfc.errorPages.extensionExpiredCopy')}
          </div>
        </div>
      </div>
    </Container>
  )
}

NFCExtensionExpiredRoute.propTypes = {
  t: PropTypes.func.isRequired
}

export default withTranslation()(NFCExtensionExpiredRoute)