import { Switch } from 'react-router-dom'
import RouteWithHelmet from 'app/router/RouteWithHelmet'
import WithAnalytics from 'app/router/WithAnalytics'
import BlankContainer from 'components/layout/BlankContainer'
import NFCLayoutWithFooter from 'components/layout/LayoutWithFooter'
import NFCLoggedOutRoute from 'components/NFCLoggedOutRoute'
import NFCNotFoundRoute from 'components/errors/NFCNotFoundRoute'
import NFCExtensionExpiredRoute from 'components/errors/NFCExtensionExpiredRoute'
import NFCSessionExtendRoute from 'components/NFCSessionExtendRoute'
import PaypalExtensionRoute from 'components/checkout/PaypalExtensionRoute'
import NFCCreditCardPurchaseRoute from 'components/NFCCreditCardPurchaseRoute'
import PaypalCheckoutRoute from 'components/checkout/PaypalCheckoutRoute'
import NFCZoneRoute from 'components/NFCZoneRoute'
import NFCExtensionTokenRoute from 'components/NFCExtensionTokenRoute'
import AvoidDoublePayment from 'components/AvoidDoublePayment'
import NFCPurchaseCompleteRoute from 'components/NFCPurchaseCompleteRoute'
import FullParkingLot from 'components/FullParkingLot'
import ReceiptReminderRoute from 'components/reminder/ReceiptReminderRoute'
import SmsNotificationRoute from 'components/reminder/SmsNotificationRoute'

import DigitalReceipt from 'components/events/DigitalReceipt'
import MobileNumberRoute from 'components/events/MobileNumberRoute'
import StartRoute from 'components/events/StartRoute'
import NoEventsAvailable from 'components/events/NoEventsAvailable'
import EventPaypalCheckoutRoute from 'components/events/checkout/PaypalCheckoutRoute'

// XC TTO
import LayoutWithFooter from 'components/tto/layout/LayoutWithFooter'
import TTOStartRoute from 'components/tto/StartRoute'
import TTOScanRoute from 'components/tto/ScanRoute'
import AuthorizationRoute from 'components/tto/AuthorizationRoute'
import UnderstandFeesRoute from 'components/tto/UnderstandFeesRoute'
import TTOSuccessRoute from 'components/tto/SuccessRoute'
import CreditCardPurchaseRoute from 'components/tto/CreditCardPurchaseRoute'
import TTOCameraAccessDeniedRoute from 'components/tto/errors/CameraAccessDeniedRoute'

// TESTING
import TestPayloadRoute from 'components/payload/TestPayloadRoute'

import { trackPage } from 'app/helpers/analyticsHelpers'

const Route = WithAnalytics(RouteWithHelmet, trackPage)

const routes = () => {
  return (
    <Switch>
      {/* Start XC */}
      <Route
        exact
        path="/p/:assetTag/checkout/:cartId/paypal-checkout"
        component={PaypalCheckoutRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/:assetTag/credit-card-purchase"
        component={NFCCreditCardPurchaseRoute}
        layout={NFCLayoutWithFooter}
      />
      <Route
        exact
        path="/p/session/:sessionId"
        component={NFCPurchaseCompleteRoute}
        layout={NFCLayoutWithFooter}
      />
      <Route
        exact
        path="/p/session/:sessionId/send-receipt"
        component={ReceiptReminderRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/session/:sessionId/sms"
        component={SmsNotificationRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/session/:sessionId/extend"
        component={NFCSessionExtendRoute}
        layout={NFCLayoutWithFooter}
      />
      <Route
        exact
        path="/p/session/:sessionId/extend/:cartId/paypal-checkout"
        component={PaypalExtensionRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/goodbye"
        component={NFCLoggedOutRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/errors/not-found"
        component={NFCNotFoundRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/errors/extension-expired"
        component={NFCExtensionExpiredRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/p/:assetTag/already-purchased"
        component={AvoidDoublePayment}
      />
      <Route
        exact
        path="/p/:assetTag"
        component={NFCZoneRoute}
        layout={NFCLayoutWithFooter}
      />
      <Route
        exact
        path="/p/:assetTag/full-parking-lot"
        component={FullParkingLot}
        layout={NFCLayoutWithFooter}
      />
      {/* End XC */}

      {/* Start XC-EVENTS */}
      <Route
        exact
        path="/e/goodbye"
        component={NFCLoggedOutRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/e/errors/not-found"
        component={NFCNotFoundRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/e/session/:sessionId/sms"
        component={MobileNumberRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/e/session/:sessionId"
        component={DigitalReceipt}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/e/:assetTag/checkout/:cartId/paypal-checkout"
        render={(props) => (
          <EventPaypalCheckoutRoute namespace="/e" {...props} /> // eslint-disable-line react/jsx-props-no-spreading
        )}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/e/no-rates-available"
        component={NoEventsAvailable}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/e/:assetTag"
        component={StartRoute}
        layout={BlankContainer}
      />
      {/* End XC-EVENTS */}

      {/* Start XC TTO */}
      <Route
        exact
        path="/t/success"
        component={TTOSuccessRoute}
        layout={LayoutWithFooter}
      />
      <Route
        exact
        path="/t/errors/camera-access-denied"
        component={TTOCameraAccessDeniedRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/t/errors/not-found"
        component={NFCNotFoundRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/t/:assetTag"
        component={TTOStartRoute}
        layout={LayoutWithFooter}
      />
      <Route
        exact
        path="/t/:assetTag/scan"
        component={TTOScanRoute}
        layout={BlankContainer}
      />
      <Route
        exact
        path="/t/:assetTag/authorization"
        component={AuthorizationRoute}
        layout={LayoutWithFooter}
      />
      <Route
        exact
        path="/t/:assetTag/fees"
        component={UnderstandFeesRoute}
        layout={LayoutWithFooter}
      />
      <Route
        exact
        path="/t/:assetTag/credit-card"
        component={CreditCardPurchaseRoute}
        layout={LayoutWithFooter}
      />

      {/* End XC TTO */}

      <Route
        exact
        path="/tap/kio/:extensionId"
        component={NFCExtensionTokenRoute}
      />

      {/* TESTING */}
      {process.env.REACT_APP_ENV !== 'production' && (
        <Route exact path="/payload/:assetTag" component={TestPayloadRoute} />
      )}
    </Switch>
  )
}

export default routes
