import { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Dimmer } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { StartTtoSession } from 'components/tto/AuthorizationRoute/operations'
import ApplePayPurchaseButton from './buttons/ApplePayPurchaseButton'
import GooglePayPurchaseButton from './buttons/GooglePayPurchaseButton'
import NavigateToCreditCardButton from './buttons/NavigateToCreditCardButton'
import { isValidEmail } from '../helpers'

const mapStateToProps = ({ nfc }) => {
  const { account } = nfc.loaded
  return {
    // TODO: Remove rates and cart, we won't use them
    // We can use account and email address to disable the buttons (if use didn't enter email address)
    zone: nfc.zone,
    disabled: !account
  }
}

const AllPurchaseButtons = ({
  zone,
  disabled,
  setIsErrorModalOpen,
  setModalError,
  barcode,
  emailAddress
}) => {
  const history = useHistory()
  const [t] = useTranslation()
  const [buttonsDisabled, setButtonsDisabled] = useState(
    disabled || !isValidEmail(emailAddress)
  )
  const { assetTag } = useParams()
  const [startTtoSession] = useMutation(StartTtoSession, {
    onCompleted: (data) => {
      const {
        startTtoSession: { errors }
      } = data
      if (!isEmpty(errors)) {
        setIsErrorModalOpen(true)
        setModalError({
          title: t(`nfc.tto.errors.${errors[0]}_title`),
          message: t(`nfc.tto.errors.${errors[0]}_message`),
          buttonText: t(`nfc.tto.errors.${errors[0]}_button`)
        })
      } else {
        history.push(`/t/success`)
      }
      return data
    },
    onError: () => {
      setIsErrorModalOpen(true)
      setModalError({
        title: t('nfc.tto.errors.general_title'),
        message: t('nfc.tto.errors.general_message'),
        buttonText: t('nfc.tto.errors.general_button')
      })
    }
  })

  useEffect(() => {
    setButtonsDisabled(disabled || !isValidEmail(emailAddress))
  }, [disabled, emailAddress])

  const onSuccess = () => {
    return history.push(`/t/success`)
  }

  const handleCreditCardClick = () => {
    history.push({
      pathname: `/t/${assetTag}/credit-card`,
      state: { barcode, emailAddress }
    })
  }

  const handleStartTtoSession = useCallback(
    (payload, purchaseType, additionalProps = {}) => {
      return startTtoSession({
        variables: {
          input: {
            payload,
            purchaseType,
            zoneHashid: zone.hashid,
            barcode,
            emailAddress,
            ...additionalProps
          }
        }
      })
    },
    [emailAddress]
  )

  return (
    <Dimmer.Dimmable>
      <Dimmer inverted active={buttonsDisabled} />
      <ApplePayPurchaseButton
        t={t}
        disabled={buttonsDisabled}
        onSuccess={onSuccess}
        startTtoSession={handleStartTtoSession}
        emailAddress={emailAddress}
      />
      <NavigateToCreditCardButton
        t={t}
        disabled={buttonsDisabled}
        onClick={handleCreditCardClick}
      />
      <GooglePayPurchaseButton
        disabled={buttonsDisabled}
        onSuccess={onSuccess}
        startTtoSession={handleStartTtoSession}
      />
    </Dimmer.Dimmable>
  )
}

AllPurchaseButtons.propTypes = {
  disabled: PropTypes.bool,
  zone: PropTypes.shape({
    hashid: PropTypes.string
  }),
  setIsErrorModalOpen: PropTypes.func,
  setModalError: PropTypes.func,
  barcode: PropTypes.string.isRequired,
  emailAddress: PropTypes.string
}

export default connect(mapStateToProps)(AllPurchaseButtons)
