import isEmpty from 'lodash/isEmpty'
import { ERROR_TYPES } from 'components/constants'
import {
  PAYMENT_METHODS,
  PAYPAL_PURCHASE_SOURCES
} from 'components/purchase/constants'

// Helpers and constants
import { isRequestSuccessful } from 'app/helpers/api'

import {
  apiGET as guestApiGET,
  apiPOST as guestApiPOST,
  authApiGET as guestAuthApiGET,
  authApiPOST as guestAuthApiPOST,
  authApiPATCH as guestAuthApiPATCH
} from 'app/helpers/guestApi'

import * as actionTypes from 'redux/actionTypes'

const PAYPAL_V4_PURCHASE_TYPE = 'pay_pal_checkout_v4'

const formatTransaction = (json) => {
  const {
    total_paid: totalPaid,
    pc_card_type: cardType,
    pc_last_four: lastFour,
    payment_date: paymentDate,
    invoice_number: invoiceNumber,
    payment_gateway: paymentGateway,
    base_amount: baseAmount,
    usage_fee: usageFee,
    has_refund: hasRefund,
    tax_items: taxItems,
    subtotal_amount: subtotalAmount,
    formatted_total_paid: formattedTotalPaid,
    formatted_usage_fee: formattedUsageFee,
    pay_pal_order_detail: payPalOrderDetail,
    discount
  } = json

  return {
    totalPaid,
    cardType,
    lastFour,
    paymentDate,
    invoiceNumber,
    paymentGateway,
    baseAmount,
    usageFee,
    hasRefund,
    taxItems,
    discount,
    subtotalAmount,
    formattedTotalPaid,
    formattedUsageFee,
    payPalOrderDetail
  }
}

const formatParkingSession = (json) => {
  const {
    custom_instructions: customInstructions,
    html_instructions: htmlInstructions,
    custom_receipt_text: customReceiptText,
    reservation,
    plate_number: plateNumber,
    end_time: expiry,
    start_time: startTime,
    id,
    hashid,
    timezone,
    voided,
    extended_session: extendedSession
  } = json

  return {
    plateNumber,
    expiry,
    startTime,
    customInstructions,
    customReceiptText,
    reservation, // Added for handling XC-events
    id,
    hashid,
    htmlInstructions,
    timezone,
    voided,
    extendedSession
  }
}

/* ------------ BEGIN: ASSET TAG ------------ */
export function failedLoadingAssetTag() {
  return { type: actionTypes.NFC_LOAD_ASSET_TAG_FAILED }
}

export function loadAssetTag(tag) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_ASSET_TAG })

    const uri = `asset_tags/${tag}`

    const { nfc } = getState()
    const { account } = { ...nfc }
    const hasAccount = !isEmpty(account)

    const request = hasAccount
      ? guestAuthApiGET(uri, account)
      : guestApiGET(uri)
    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const { zone, name: assetTag, ...allTagData } = json.data
        dispatch({
          type: actionTypes.NFC_LOAD_ASSET_TAG,
          zone,
          assetTag,
          allTagData
        })
      } else {
        dispatch({ type: actionTypes.NFC_LOAD_ASSET_TAG_FAILED })
      }
      return json
    })
  }
}
/* ------------ END: ASSET TAG ------------ */

/* ------------ BEGIN: CART ------------ */
export function failedLoadingCart() {
  return { type: actionTypes.NFC_LOAD_CART_FAILED }
}

export function createCart(zoneId, extendId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.NFC_LOADING_CART })
    const params = {
      product_type: 'hourly',
      zone_id: zoneId,
      extend_hashid: extendId
    }
    // if user is logged in need to pass the oa_tag since the cart selector will be confused
    // if the cart is associated with account and oa_tag is not passed
    const request = guestApiPOST('carts', params)

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        dispatch({ type: actionTypes.NFC_LOAD_CART, cart: json.data.cart })
      }
      return json
    })
  }
}

export function clearCart() {
  return { type: actionTypes.NFC_CLEAR_CART }
}

/* ------------ END: CART ------------ */

/* ------------ BEGIN: RATES ------------ */
export function failedLoadingRates() {
  return { type: actionTypes.NFC_LOAD_RATES_FAILED }
}

export function loadRates(cartId, plateNumber) {
  return (dispatch) => {
    dispatch({ type: actionTypes.NFC_LOADING_RATES })

    const uri = `carts/${cartId}/rates`
    const params = {
      plate_number: plateNumber
    }

    // if user is logged in need to pass the oa_tag since the cart selector will be confused
    // if the cart is associated with account and oa_tag is not passed
    const request = guestApiGET(uri, params)

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        dispatch({
          type: actionTypes.NFC_LOAD_RATES,
          rates: json.data.rates,
          rules: json.data.rules
        })
      } else {
        dispatch({ type: actionTypes.NFC_ADD_ERROR, error: 'set_rate' })
      }
      return json
    })
  }
}

export function addPromoCode(cartId, promoCode) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_ADDING_PROMO_CODE })
    const { nfc } = getState()
    const { account: currentAccount } = { ...nfc }

    const params = {
      promo_code: promoCode
    }

    const request = guestAuthApiPOST(
      `carts/${cartId}/add_promo_code`,
      currentAccount,
      params
    )
    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        dispatch({ type: actionTypes.NFC_ADD_PROMO_CODE, cart: json.data.cart })
      } else {
        dispatch({ type: actionTypes.NFC_ADD_PROMO_CODE_FAILED })
      }
      return json
    })
  }
}

export function setRate(cartId, rateId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.NFC_SETTING_RATE })
    const params = {
      rate_id: rateId
    }
    const request = guestApiPOST(`carts/${cartId}/set_rate`, params)
    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        dispatch({
          type: actionTypes.NFC_SET_RATE,
          cart: json.data.cart,
          rateId
        })
      } else {
        dispatch({ type: actionTypes.NFC_ADD_ERROR, error: 'set_rate' })
      }
      return json
    })
  }
}
/* ------------ END: RATES ------------ */
/* ------------ BEGIN: An Account ------------ */
export function failedLoadingAccount() {
  return { type: actionTypes.NFC_LOAD_ACCOUNT_FAILED }
}

export function loadAccount() {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_ACCOUNT })
    const uri = 'account'

    const { nfc } = getState()
    const {
      account: currentAccount,
      merchantInfo: { id: merchantInfoId }
    } = nfc
    const hasAccount = !isEmpty(currentAccount)

    const request = hasAccount
      ? guestAuthApiGET(uri, currentAccount, { merchantInfoId })
      : guestApiGET(uri)

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const account = json.data
        dispatch({ type: actionTypes.NFC_LOAD_ACCOUNT, account })
      } else {
        dispatch({ type: actionTypes.NFC_LOAD_ACCOUNT_FAILED })
      }
      return json
    })
  }
}

export function loadExpiryToken(token) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_ACCOUNT })
    const uri = 'extension_token'

    const { nfc } = getState()
    const { account: currentAccount } = { ...nfc }
    const hasAccount = !isEmpty(currentAccount)
    const params = { token }

    const request = hasAccount
      ? guestAuthApiGET(uri, currentAccount, params)
      : guestApiGET(uri, params)

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const account = json.data
        dispatch({ type: actionTypes.NFC_LOAD_ACCOUNT, account })
      } else {
        dispatch({ type: actionTypes.NFC_LOAD_ACCOUNT_FAILED })
      }
      return json
    })
  }
}

export function updateAccount({ mobilePhoneNumber, emailAddress }) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_ACCOUNT })
    const uri = 'account'

    const { nfc } = getState()
    const { account: currentAccount } = { ...nfc }

    const request = guestAuthApiPATCH(uri, currentAccount, {
      mobile_phone_number: mobilePhoneNumber,
      email_address: emailAddress
    })

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const account = json.data
        dispatch({ type: actionTypes.NFC_LOAD_ACCOUNT, account })
      } else {
        dispatch({ type: actionTypes.NFC_LOAD_ACCOUNT_FAILED })
        return Promise.reject(new Error('Unable to update account'))
      }
      return json
    })
  }
}

export function updateMobileNumber(mobileNumber) {
  return { type: actionTypes.NFC_UPDATE_ACCOUNT_MOBILE_NUMBER, mobileNumber }
}

export function updateEmailAddress(emailAddress) {
  return { type: actionTypes.NFC_UPDATE_ACCOUNT_EMAIL, emailAddress }
}

/* ------------ END: An Account  ------------ */

/* ------------ BEGIN: Purchases ------------ */

function generalPurchase(dispatch, getState, params, errorType) {
  const { nfc } = getState()
  const { account: currentAccount, assetTag } = { ...nfc }

  Object.assign(params, { asset_tag: assetTag })
  const request = guestAuthApiPOST('parking_sessions', currentAccount, params)

  return request.then((json) => {
    if (isRequestSuccessful(json)) {
      dispatch({
        type: actionTypes.NFC_PURCHASE_COMPLETED,
        parkingSession: formatParkingSession(json.data),
        transactionHistory: formatTransaction(json.data.transaction_history),
        zone: json.data.zone
      })
    } else {
      const error = ERROR_TYPES[json.errors[0]] || errorType
      dispatch({ type: actionTypes.NFC_PURCHASE_FAILED })
      dispatch({ type: actionTypes.NFC_ADD_ERROR, error })
    }

    return json
  })
}

export function createParkingSessionCreditCardToken(
  cartId,
  cardId,
  plateNumber
) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_CREDIT_CARD_TOKEN_PURCHASING })
    const params = {
      cart_id: cartId,
      plate_number: plateNumber,
      purchase_type: PAYMENT_METHODS.card,
      payload: {
        card_id: cardId
      }
    }
    return generalPurchase(dispatch, getState, params, 'payment_card')
  }
}

export function createPaypalOrder(
  cartId,
  flow,
  source = PAYPAL_PURCHASE_SOURCES.default
) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_PAYPAL_ORDER_REQUESTED })

    // Needed to select the right redirect urls
    const {
      nfc: {
        assetTag,
        parkingSession: { hashid: sessionId }
      }
    } = getState()

    const params = {
      asset_tag: assetTag,
      extending_session_hashid: sessionId,
      source,
      flow
    }

    return guestApiPOST(`carts/${cartId}/v4/pay_pal_checkouts`, params).then(
      (json) => {
        // We redirect away on success, so we only have error handling here
        if (!isRequestSuccessful(json)) {
          dispatch({ type: actionTypes.NFC_PAYPAL_ORDER_FAILED })
        }

        return json
      }
    )
  }
}

export function createParkingSessionPayPal(cartId, plateNumber, orderId) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_PAYPAL_PURCHASING })

    const params = {
      cart_id: cartId,
      plate_number: plateNumber,
      purchase_type: PAYPAL_V4_PURCHASE_TYPE,
      payload: { order_id: orderId }
    }
    return generalPurchase(dispatch, getState, params, 'payment_paypal')
  }
}

export function createParkingSessionApplePay(
  cartId,
  plateNumber,
  paymentEmail,
  data,
  avsCode
) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_APPLE_PAY_PURCHASING })
    const params = {
      cart_id: cartId,
      plate_number: plateNumber,
      payment_email: paymentEmail,
      purchase_type: PAYMENT_METHODS.apple_pay,
      payload: { data, avs_code: avsCode }
    }

    return generalPurchase(dispatch, getState, params, 'payment_apple_pay')
  }
}

export function createParkingSessionGooglePay(
  cartId,
  plateNumber,
  paymentEmail,
  data
) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_GOOGLE_PAY_PURCHASING })
    const params = {
      cart_id: cartId,
      plate_number: plateNumber,
      payment_email: paymentEmail,
      purchase_type: PAYMENT_METHODS.google_pay,
      payload: { data }
    }

    return generalPurchase(dispatch, getState, params, 'payment_google_pay')
  }
}

export function createParkingSessionZeroDollar(cartId, plateNumber) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_ZERO_DOLLAR_PURCHASING })

    const params = {
      cart_id: cartId,
      plate_number: plateNumber,
      payload: {}
    }

    return generalPurchase(dispatch, getState, params)
  }
}

export function requestPaymentToken() {
  return (dispatch, getState) => {
    const { nfc } = getState()
    const { account: currentAccount } = { ...nfc }
    return guestAuthApiPOST('payment_tokens', currentAccount, {})
  }
}

export function showProcessingTransactionLoader() {
  return { type: actionTypes.NFC_START_PROCESSING_TRANSACTION }
}

export function hideProcessingTransactionLoader() {
  return { type: actionTypes.NFC_STOP_PROCESSING_TRANSACTION }
}

export function createParkingSessionTransactionId(
  cartId,
  plateNumber,
  castlePayload,
  requestId
) {
  return (dispatch, getState) => {
    const params = {
      cart_id: cartId,
      plate_number: plateNumber,
      purchase_type: PAYMENT_METHODS.credit_card_sale,
      payload: { enc_payload: castlePayload, request_id: requestId }
    }
    return generalPurchase(dispatch, getState, params, 'payment_card')
  }
}
/* ------------ END: Purchases ------------ */

/* ------------ BEGIN: Parking Session ------------ */
export function failedLoadParkingSession() {
  return { type: actionTypes.NFC_LOADING_PARKING_SESSION_FAILED }
}

export function loadParkingSession(sessionID) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_PARKING_SESSION })

    const uri = `parking_sessions/${sessionID}`

    const { nfc } = getState()
    const { account } = { ...nfc }
    const hasAccount = !isEmpty(account)

    const request = hasAccount
      ? guestAuthApiGET(uri, account)
      : guestApiGET(uri)
    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const { zone, transaction_history: txn } = json.data
        const transactionHistory = formatTransaction(txn)

        dispatch({
          type: actionTypes.NFC_LOAD_PARKING_SESSION,
          ...formatParkingSession(json.data),
          zone,
          transactionHistory
        })
      } else {
        dispatch({ type: actionTypes.NFC_LOADING_PARKING_SESSION_FAILED })
      }
      return json
    })
  }
}

export function emailReceipt(sessionID) {
  // dispatch({ type: actionTypes.NFC_EMAI })
  return (dispatch, getState) => {
    // dispatch({ type: actionTypes.NFC_LOADING_PARKING_SESSION })

    const uri = `parking_sessions/${sessionID}/email_receipt`

    const { nfc } = getState()
    const { account } = { ...nfc }
    const hasAccount = !isEmpty(account)

    const request = hasAccount
      ? guestAuthApiPOST(uri, account)
      : guestApiPOST(uri)
    return request.then((json) => {
      return json
    })
  }
}

/* ------------ END: Parking Session  ------------ */

export function setParkingPlate(plateNumber) {
  return { type: actionTypes.NFC_SET_PLATE_NUMBER, plateNumber }
}

/* ------------ BEGIN: Vehicle ------------ */
export function loadVehicle() {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_VEHICLE })

    const uri = 'vehicles'

    const { nfc } = getState()
    const { account } = { ...nfc }
    const hasAccount = !isEmpty(account)

    const request = hasAccount
      ? guestAuthApiGET(uri, account)
      : guestApiGET(uri)

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const { plate_number: plateNumber } = json.data
        dispatch({
          type: actionTypes.NFC_LOAD_VEHICLE,
          plateNumber
        })
      } else {
        dispatch({ type: actionTypes.NFC_LOAD_VEHICLE_FAILED })
      }
      return json
    })
  }
}
/* ------------ END: Vehicle  ------------ */

/* ------------ BEGIN: Zone ------------ */
export function loadZone(zoneId) {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.NFC_LOADING_ZONE })
    const uri = `zones/${zoneId}`

    const { nfc: account } = getState()
    const hasAccount = !isEmpty(account)

    const request = hasAccount
      ? guestAuthApiGET(uri, account)
      : guestApiGET(uri)

    return request.then((json) => {
      if (isRequestSuccessful(json)) {
        const { data: zone } = json
        dispatch({
          type: actionTypes.NFC_LOAD_ZONE,
          zone
        })
      } else {
        dispatch({ type: actionTypes.NFC_LOAD_ZONE_FAILED })
      }
      return json
    })
  }
}

export function loadZoneInventory(zoneId) {
  return (dispatch) => {
    const uri = `zones/${zoneId}/check_inventory`

    const request = guestApiGET(uri)

    return request.then((json) => {
      dispatch({ type: actionTypes.NFC_LOAD_ZONE_INVENTORY })
      return json
    })
  }
}
/* ------------ END: Zone  ------------ */

/* ------------ BEGIN: Options Zone ------------ */
export function loadZoneOption(zoneOption) {
  return { type: actionTypes.NFC_LOAD_ZONE_OPTION, zoneOption }
}

export function clearZoneOption() {
  return { type: actionTypes.NFC_CLEAR_ZONE_OPTION }
}
/* ------------ END: Options Zone  ------------ */

/* ------------ BEGIN: EV Charging ------------ */

export function loadChargerState(zoneId, sessionId) {
  return (dispatch, getState) => {
    const {
      nfc: { account }
    } = getState()

    return guestAuthApiGET(`zones/${zoneId}/charger`, account, {
      parking_session_id: sessionId
    }).then((json) => {
      if (isRequestSuccessful(json)) {
        dispatch({ type: actionTypes.NFC_LOAD_CHARGER_STATE, zone: json.data })
      } else {
        dispatch({
          type: actionTypes.NFC_LOAD_CHARGER_STATE,
          zone: { charger_state: null }
        })
      }

      return json
    })
  }
}

export function startCharger(zoneId, sessionId) {
  return (dispatch, getState) => {
    const {
      nfc: { account }
    } = getState()

    return guestAuthApiPATCH(`zones/${zoneId}/charger/start`, account, {
      parking_session_id: sessionId
    }).then((json) => {
      if (isRequestSuccessful(json)) {
        dispatch({ type: actionTypes.NFC_START_CHARGER, zone: json.data })
      } else {
        dispatch({
          type: actionTypes.NFC_LOAD_CHARGER_STATE,
          zone: { charger_state: null }
        })
      }

      return json
    })
  }
}

/* ------------ END: EV Charging ------------ */

/* --------- BEGIN: Errors ---------*/
export function clearErrors() {
  return { type: actionTypes.NFC_CLEAR_ERRORS }
}

export function addError(error) {
  return { type: actionTypes.NFC_ADD_ERROR, error }
}

export function removeError() {
  return { type: actionTypes.NFC_REMOVE_ERROR }
}
/* --------- END: Errors ---------*/

/* --------- BEGIN: Session ---------*/

export function logout() {
  return { type: actionTypes.NFC_LOGOUT }
}

export function clearUserInfo() {
  return { type: actionTypes.NFC_CLEAR_USER_INFO }
}

/* --------- END: Session ---------*/

/* --------- BEGIN: Get Active Session ---------*/

export function getActiveSession(zoneId) {
  return (dispatch, getState) => {
    const {
      nfc: { account }
    } = getState()
    return guestAuthApiGET(`zones/${zoneId}/active_sessions`, account).then(
      (json) => {
        if (isRequestSuccessful(json)) {
          dispatch({
            type: actionTypes.NFC_GET_ACTIVE_SESSIONS,
            parkingSessions: json.data.parking_sessions
          })
        }
        return json
      }
    )
  }
}

/* --------- END: Get Active Session ---------*/
