import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { Icon } from 'semantic-ui-react'
import PurchaseButton from './PurchaseButton'

import styles from './PayWithNewCreditCardButton.module.scss'

class PayWithNewCreditCardButton extends PureComponent {
  handleClick = (e) => {
    const { onClick, disabled } = this.props

    if (!disabled) {
      onClick(e)
    }
  }

  renderLoader = () => {
    const { t } = this.props
    return (
      <PurchaseButton className={styles.ccButton} loading="true" primary="true">
        {t('nfc.processingTransaction')}
      </PurchaseButton>
    )
  }

  renderButton = () => {
    const { t, disabled } = this.props

    return (
      <PurchaseButton
        className={styles.ccButton}
        onClick={this.handleClick}
        disabled={disabled}
      >
        <Icon size="small" name="lock" />
        {t('nfc.paymentMethods.payWithCreditCard')}
      </PurchaseButton>
    )
  }

  render() {
    const { className, disabled } = this.props

    return (
      <div className={className}>
        {disabled ? this.renderLoader() : this.renderButton()}
      </div>
    )
  }
}

PayWithNewCreditCardButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default PayWithNewCreditCardButton
