import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

function WithAnalytics(Route, trackPage) {
  const Container = class extends PureComponent {
    render() {
      const { location } = this.props
      trackPage(location)
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Route {...this.props} />
    }
  }
  Container.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }
  return Container
}

export default WithAnalytics
