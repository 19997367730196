import React, { PureComponent } from 'react'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import ErrorScreen from 'components/ErrorScreen'
import NFCShowErrorComponent from 'components/NFCShowErrorComponent'
import SpecialRateCard from 'components/rates/SpecialRateCard'
import Banner from 'components/common/Banner'
import { getIsPurchaseable } from 'redux/reducers'
import { NFC_ZONE_VALIDATION_TYPES } from 'components/constants'
import * as AssetTagActions from 'redux/actions'
import { PURCHASE_FLOWS } from 'components/purchase/constants'

import CartInformation from './purchase/CartInformation'
import AllPurchaseButtons from './purchase/AllPurchaseButtons'
import ParkingSessionLoader from './loaders/ParkingSessionLoader'
import CartLoader from './loaders/CartLoader'
import RatesLoader from './loaders/RatesLoader'
import AccountLoader from './loaders/AccountLoader'
import RateDropDown from './purchase/RateDropDown'
import ExtensionInfo from './extend/extensionInfo'
import PlateNumber from './extend/plateNumber'

import styles from './NFCSessionExtendRoute.module.scss'

const mapStateToProps = ({ nfc }) => {
  return {
    isUnpurchaseable: nfc.loaded.rates && !getIsPurchaseable(nfc),
    validationType: nfc.zone.validation_type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class NFCSessionExtendRoute extends PureComponent {
  shouldPreserveErrorsOnMount = () => {
    const {
      location: { search }
    } = this.props
    const query = new URLSearchParams(search)
    return !!query.get('preserveErrorsOnMount')
  }

  renderSpecialRates = () => {
    const { t } = this.props

    return (
      <div className={styles.specialRateBlock}>
        <ExtensionInfo t={t} className={styles.extensionInfo} />
        <div className={styles.specialRateBlockCard}>
          <SpecialRateCard t={t} />
        </div>
      </div>
    )
  }

  renderCard = () => {
    const { isUnpurchaseable } = this.props
    return isUnpurchaseable ? this.renderSpecialRates() : this.renderForm()
  }

  renderForm = () => {
    const { t, validationType } = this.props
    const payByPlate = validationType === NFC_ZONE_VALIDATION_TYPES.by_plate

    return (
      <Formik>
        <div className={styles.body}>
          <div className={styles.mainContent}>
            <ExtensionInfo t={t} className={styles.extensionInfo} />
            <NFCShowErrorComponent
              preserveErrorsOnMount={this.shouldPreserveErrorsOnMount()}
              t={t}
              className={styles.showErrorComponent}
            />
            <form className={styles.form}>
              <div className={styles.formGroup}>
                <label htmlFor="rateSelect" className={styles.label}>
                  {payByPlate ? t('nfc.parkingPlate') : t('nfc.parkingSpace')}
                </label>
                <PlateNumber t={t} />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="rateSelect" className={styles.label}>
                  {t('nfc.zone.chooseRate')}
                </label>
                <RateDropDown id="rateSelect" t={t} />
              </div>
              <CartInformation t={t} openPromoModal={this.openPromoModal} />
            </form>
          </div>
          <div className={styles.actionContainer}>
            <p className={styles.terms}>
              <span>{t('nfc.zone.termsCopy')}</span>
              <a
                target="_blank"
                href={WHITELABEL_CONFIG.TERMS_URL}
                rel="noreferrer"
              >
                {t('nfc.zone.termsLink')}
              </a>
            </p>
            <AllPurchaseButtons
              t={t}
              purchaseFlow={PURCHASE_FLOWS.extension}
              className={styles.allPurchaseButtons}
            />
          </div>
        </div>
      </Formik>
    )
  }

  render() {
    const {
      t,
      match: {
        params: { sessionId }
      }
    } = this.props

    return (
      <div className={styles.page}>
        <ErrorScreen t={t}>
          {({ showErrorUi }) => (
            <ParkingSessionLoader
              sessionId={sessionId}
              handleError={showErrorUi}
            >
              <AccountLoader />
              <CartLoader handleError={showErrorUi}>
                <RatesLoader handleError={showErrorUi} />
              </CartLoader>
            </ParkingSessionLoader>
          )}
        </ErrorScreen>
        <ProcessingLoader t={t} />
        <Banner />
        {this.renderCard()}
      </div>
    )
  }
}

NFCSessionExtendRoute.propTypes = {
  t: PropTypes.func,
  isUnpurchaseable: PropTypes.bool.isRequired,
  validationType: PropTypes.oneOf(Object.values(NFC_ZONE_VALIDATION_TYPES)),
  match: PropTypes.shape({
    params: PropTypes.shape({
      sessionId: PropTypes.string
    }).isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    addPromoCode: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
}

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NFCSessionExtendRoute)
