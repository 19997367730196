import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { formatDateTime } from 'app/helpers/formatDateTime'

import styles from './StartEndDateContainer.module.scss'

const StartEndDateContainer = ({ startTime, endTime }) => {
  const { t } = useTranslation()

  const formatDate = useCallback(
    (dateString) =>
      formatDateTime(dateString).setLocale('en-US').toFormat('DD'),
    []
  )
  const formatTime = useCallback(
    (dateString) => formatDateTime(dateString).toFormat('t'),
    []
  )

  const renderDate = (label, date) => (
    <div className={styles.dateContainer}>
      <p className={styles.label}>{label}</p>
      <p className={styles.time}>{formatTime(date)}</p>
      <p className={styles.date}>{formatDate(date)}</p>
    </div>
  )

  return (
    <div className={styles.startEndDateContainer}>
      {renderDate(t('nfc.purchaseComplete.startDate'), startTime)}
      <div className={styles.dateDivider}>
        <span className={styles.circle} />
        <span className={styles.line} />
        <span className={styles.circle} />
      </div>
      {renderDate(t('nfc.purchaseComplete.endDate'), endTime)}
    </div>
  )
}

StartEndDateContainer.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired
}

export default StartEndDateContainer
