import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { GetCartRates } from 'components/events/operations'
import * as actionTypes from 'redux/actionTypes'
import { cartSelector } from 'redux/reducers'

import { snakeCaseKeysDeep } from 'components/helpers/formatting'

const RatesLoader = () => {
  const cart = useSelector(cartSelector)
  const dispatch = useDispatch()

  useQuery(GetCartRates, {
    variables: { cartId: cart.hashid },
    onCompleted: ({ cartRates }) => {
      const rates = cartRates.map(snakeCaseKeysDeep)
      dispatch({
        type: actionTypes.NFC_LOAD_RATES,
        rates
      })
    }
  })

  return null
}

RatesLoader.defaultProps = {
  handleError: () => {}
}

RatesLoader.propTypes = {
  zone: PropTypes.shape({
    zone_id: PropTypes.string.isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    createCart: PropTypes.func.isRequired,
    failedLoadingCart: PropTypes.func.isRequired
  }).isRequired,
  parkingSessionHashId: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func
}
export default RatesLoader
