import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isRequestSuccessful } from 'app/helpers/api'
import { withRouter } from 'react-router-dom'
import includes from 'lodash/includes'
import * as AssetTagActions from 'redux/actions'

const mapStateToProps = (state) => {
  return {
    zone: state.nfc.zone,
    parkingSessionHashId: state.nfc.parkingSession.hashid,
    loaded: state.nfc.loaded.cart
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class CartLoader extends Component {
  componentDidMount() {
    const { assetTagActions, zone, parkingSessionHashId, loaded, handleError, history } =
      this.props

    if (!loaded) {
      trackEvent('Cart Started Loading')
      assetTagActions
        .createCart(zone.zone_id, parkingSessionHashId)
        .then((json) => {
          if (!isRequestSuccessful(json)) {
            if (includes(json.errors, 'extension_expired')) {
              history.replace('/p/errors/extension-expired')
            }
            assetTagActions.failedLoadingCart()
            handleError()
          }
          trackEvent('Cart Started Loaded')
          return json

          /* TODO HANDLE ERROR
        if (includes(json.errors, 'not_allowed')) {
          return browserHistory.replace('/403')
        }

        return browserHistory.replace('/404')
        */
        })
    }
  }

  render() {
    const { loaded, children } = this.props
    if (loaded && children) {
      return children
    }
    return null
  }
}

CartLoader.defaultProps = {
  handleError: () => {}
}

CartLoader.propTypes = {
  zone: PropTypes.shape({
    zone_id: PropTypes.string.isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    createCart: PropTypes.func.isRequired,
    failedLoadingCart: PropTypes.func.isRequired
  }).isRequired,
  parkingSessionHashId: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
}
export default compose( 
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CartLoader)
