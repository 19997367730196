import { useState, useEffect, useCallback, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Loader } from 'semantic-ui-react'
import defaultTo from 'lodash/defaultTo'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { DateTime } from 'luxon'
import { logout, clearCart } from 'redux/actions'
import classnames from 'classnames'
import history from 'app/history'
import { formatDateTime } from 'app/helpers/formatDateTime'
import { formatPhoneNumber } from 'app/helpers/phoneNumber'
import Header from 'components/layout/Header'
import Button from 'components/shared/Button'
import ChargerStatusCard from './ChargerStatusCard'
import PurchaseCompleteCustomReceiptText from './PurchaseCompleteCustomReceiptText'
import ReminderButton from './ReminderButton'
import OverlayButton from './shared/OverlayButton'
import PurchaseCompleteCustomInstructions from './PurchaseCompleteCustomInstructions'
import StartEndDateContainer from './StartEndDateContainer'

import ParkingSessionLoader from './loaders/ParkingSessionLoader'
import AccountLoader from './loaders/AccountLoader'
import AssetTagLoader from './loaders/AssetTagLoader'

import {
  NFC_ZONE_VALIDATION_TYPES,
  PAYMENT_GATEWAYS,
  REMINDER_BUTTON_TYPES,
  APPLICATION_TYPE,
  PAYMENT_CARD_TYPES
} from './constants'
import BellIcon from './_assets/icon-bell.svg'
import ReceiptIcon from './_assets/icon-receipt.svg'

import './NFCPurchaseCompleteRoute.scss'
import styles from './NFCPurchaseCompleteRoute.module.scss'

const NFCPurchaseCompleteRoute = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sessionId } = useParams()

  const [timerStarted, setTimerStarted] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)

  const applicationType = useSelector(
    (state) => state.nfc.allTagData.application_type
  )
  const parkingSession = useSelector((state) => state.nfc.parkingSession)
  const transactionHistory = useSelector(
    (state) => state.nfc.transactionHistory
  )
  const zone = useSelector((state) => state.nfc.zone)
  const plateNumber = useSelector((state) => state.nfc.plateNumber)
  const emailAddress = useSelector((state) => state.nfc.account.email_address)
  const phoneNumber = useSelector(
    (state) => state.nfc.account.mobile_phone_number
  )
  const sessionLoaded = useSelector((state) => state.nfc.loaded.parkingSession)
  const accountLoaded = useSelector((state) => state.nfc.loaded.account)

  const publicXC = applicationType === APPLICATION_TYPE.publicXC
  const { startTime, expiry, timezone, voided, extendedSession } =
    parkingSession

  const {
    address,
    company,
    zone_id: zoneId,
    is_ev_charger: isEvCharger,
    validation_type: validationType
  } = zone

  const {
    invoiceNumber,
    cardType,
    lastFour,
    paymentDate,
    paymentGateway,
    discount,
    hasRefund,
    taxItems,
    subtotalAmount,
    formattedTotalPaid,
    formattedUsageFee,
    payPalOrderDetail
  } = transactionHistory

  const { proper_name: properName } = defaultTo(company, {})
  const { street } = defaultTo(address, {})

  const formatDate = useCallback(
    (dateString) => formatDateTime(dateString).toFormat("LLL d, y 'at' h:mm a"),
    []
  )
  const formatPrice = useCallback(
    (priceFloat) => `$${parseFloat(priceFloat).toFixed(2)}`,
    []
  )

  const handleLogout = () => {
    dispatch(logout())
    const localAssetTag = localStorage.getItem('assetTag')
    localStorage.removeItem('assetTag')
    history.replace(`/p/${localAssetTag}`)
  }

  useEffect(() => {
    dispatch(clearCart())
  }, [])

  useEffect(() => {
    let logoutTimer
    if (!timerStarted && sessionLoaded && publicXC) {
      setTimerStarted(true)
      logoutTimer = setTimeout(() => {
        handleLogout()
      }, 20000) // 20 seconds
    }

    const expiryDate = new Date(expiry)
    const timeNowWithTimezone = DateTime.now().setZone(timezone)
    const endTimeWithTimezone = DateTime.fromJSDate(expiryDate).setZone(timezone)

    setSessionExpired(timeNowWithTimezone >= endTimeWithTimezone)

    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer)
      }
    }
  }, [sessionLoaded, applicationType])

  if (!sessionLoaded || !accountLoaded) {
    const localAssetTag = localStorage.getItem('assetTag')

    return (
      <>
        <AssetTagLoader name={localAssetTag}>
          <AccountLoader />
          <ParkingSessionLoader sessionId={sessionId} />
        </AssetTagLoader>
        <Loader active size="large">
          {t('nfc.purchaseComplete.loadingSession')}
        </Loader>
      </>
    )
  }

  const parkingSessionInfo = [
    hasRefund && {
      key: 'status',
      label: t('nfc.purchaseComplete.status'),
      value: (
        <p className={styles.refundedStatus}>
          {t('nfc.purchaseComplete.refunded')}
        </p>
      )
    },
    {
      key: 'plateNumber',
      label:
        validationType === NFC_ZONE_VALIDATION_TYPES.by_plate
          ? t('nfc.purchaseComplete.vehicle')
          : t('nfc.purchaseComplete.spaceNumber'),
      value: <p>{plateNumber}</p>
    },
    {
      key: 'location',
      label: t('nfc.purchaseComplete.location'),
      value: (
        <p>
          {t('nfc.purchaseComplete.locationInfo', {
            street,
            zoneId,
            properName
          })}
        </p>
      )
    }
  ]

  const priceInfo = [
    {
      key: 'subtotalAmount',
      label: t('nfc.purchaseComplete.parkingPrice'),
      value: <p>{subtotalAmount}</p>
    },
    ...taxItems.map((taxItem) => ({
      key: taxItem.name,
      label: (
        <div className={styles.taxInfoRowLabel}>
          {taxItem.name.toUpperCase()}
        </div>
      ),
      value: <p className={styles.taxInfoRowValue}>{taxItem.amount}</p>
    })),
    {
      key: 'usageFee',
      label: t('nfc.purchaseComplete.feesPrice'),
      value: <p>{formattedUsageFee}</p>,
      divider: true
    },
    !!discount && {
      key: 'discount',
      label: t('nfc.purchaseComplete.discount'),
      value: <p className={styles.discount}>-{formatPrice(discount)}</p>,
      divider: true
    }
  ]

  const cardInfo = () => {
    switch (paymentGateway) {
      case PAYMENT_GATEWAYS.google_pay:
        return `${t('nfc.purchaseComplete.googlePay')} (${
          PAYMENT_CARD_TYPES[cardType]
        })`
      case PAYMENT_GATEWAYS.paypal:
        return t('nfc.purchaseComplete.paypal')
      case PAYMENT_GATEWAYS.apple_pay:
        return `${t('nfc.purchaseComplete.applePay')} (${
          PAYMENT_CARD_TYPES[cardType]
        } ${lastFour})`
      case PAYMENT_GATEWAYS.zero:
        return t('nfc.purchaseComplete.notApplicable')
      default:
        return `${PAYMENT_CARD_TYPES[cardType]} ${lastFour}`
    }
  }

  const row = (key, label, value, divider = false) => (
    <Fragment key={key}>
      {divider && <div className={styles.divider} />}
      <div className={styles.row}>
        <p>{label}</p>
        {value}
      </div>
    </Fragment>
  )

  return (
    <div className={styles.page}>
      <Header>
        {t('nfc.purchaseComplete.header', {
          invoiceNumber: invoiceNumber.toUpperCase()
        })}
      </Header>
      <div className={styles.main}>
        <h1 className={styles.sessionInfoHeader}>
          {t('nfc.purchaseComplete.parkingSessionHeader')}
        </h1>
        <PurchaseCompleteCustomInstructions />
        <StartEndDateContainer startTime={startTime} endTime={expiry} />
        {!sessionExpired && !voided && isNil(extendedSession) && (
          <Button
            onClick={() => history.push(`/p/session/${sessionId}/extend`)}
            className={styles.extendButton}
          >
            {t('nfc.purchaseComplete.extendSession')}
          </Button>
        )}
        {isEvCharger && <ChargerStatusCard />}

        <div className={styles.infoContainer}>
          {parkingSessionInfo.map((info) => {
            if (isEmpty(info)) return null
            return row(info.key, info.label, info.value, info.divider)
          })}
        </div>

        <PurchaseCompleteCustomReceiptText />

        <h3>
          {t('nfc.purchaseComplete.invoiceNumber', {
            invoiceNumber
          }).toUpperCase()}
        </h3>
        {priceInfo.map((info) => {
          if (isEmpty(info)) return null
          return row(info.key, info.label, info.value, info.divider)
        })}
        <div className={styles.divider} />
        <div className={classnames(styles.row, styles.titleRow)}>
          <p>{t('nfc.purchaseComplete.total')}</p>
          <p>{formattedTotalPaid}</p>
        </div>

        <div className={styles.paymentInfo}>
          <p>
            {t('nfc.purchaseComplete.chargedTo')}
            {cardInfo()}
          </p>
          {!isEmpty(payPalOrderDetail) && (
            <>
              <p>
                {t('nfc.purchaseComplete.paypalAccount', {
                  email: payPalOrderDetail.email_address
                })}
              </p>
              <p>
                {t('nfc.purchaseComplete.paypalOrderId', {
                  orderId: payPalOrderDetail.order_id
                })}
              </p>
            </>
          )}
          <p>
            {paymentGateway === PAYMENT_GATEWAYS.zero
              ? t('nfc.purchaseComplete.processedOn', {
                  paymentDate: formatDate(paymentDate)
                })
              : t('nfc.purchaseComplete.paidOn', {
                  paymentDate: formatDate(paymentDate)
                })}
          </p>
        </div>

        {publicXC && (
          <OverlayButton
            text={t('nfc.purchaseComplete.makeAnotherPurchase')}
            onClick={handleLogout}
          />
        )}
      </div>
      <div className={styles.reminderButtonsContainer}>
        <ReminderButton
          type={REMINDER_BUTTON_TYPES.sms}
          sessionId={sessionId}
          buttonText={t('nfc.purchaseComplete.expiryNotification')}
          icon={BellIcon}
          userInfo={formatPhoneNumber(phoneNumber)}
        />
        <div className={styles.buttonDivider} />
        <ReminderButton
          type={REMINDER_BUTTON_TYPES.email}
          sessionId={sessionId}
          buttonText={t('nfc.purchaseComplete.emailReceipt')}
          icon={ReceiptIcon}
          userInfo={emailAddress}
        />
      </div>
    </div>
  )
}

export default NFCPurchaseCompleteRoute
