import { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import MinusIcon from "./_assets/icon_minus.svg"
import PlusIcon from "./_assets/icon_plus.svg"

import styles from "./Accordion.module.scss"

const Accordion = ({
  panels,
  defaultActiveIndex = -1,
  className
}) => {
  const accordionClassName = classNames(styles.container, className)

  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex)

  const handleClick=(e, index) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  return (
    <div className={accordionClassName}>
      {panels.map((panel, index) => (
        <div key={panel.key} className={styles.panel} onClick={(e) => handleClick(e, index)}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {panel.title.icon}
              {panel.title.content}
            </div>
            <img 
              className={classNames(styles.collapseIcon, { [styles.active]: activeIndex === index })}
              src={activeIndex === index ? MinusIcon : PlusIcon}
              alt="Expand/Collapse Icon"
            /> 
          </div>
          <div className={classNames(styles.content, { [styles.active]: activeIndex === index})}>
            {panel.content.content}
          </div>
        </div>
      ))}
    </div> 
  )
}

Accordion.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.shape({
      content: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }).isRequired,
    content: PropTypes.shape({
      content: PropTypes.element,
    }).isRequired,
  })).isRequired,
  defaultActiveIndex: PropTypes.number,
}

export default Accordion
