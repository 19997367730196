import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import PropTypes from 'prop-types'

import { isValidEmail } from 'components/tto/AuthorizationRoute/helpers'
import Input from 'components/common/Input'
import styles from './EmailForm.module.scss'

const EmailForm = ({ emailAddress, setEmailAddress }) => {
  const { t } = useTranslation()
  const [isTyping, setIsTyping] = useState(false)

  const handChange = (e) => {
    setEmailAddress(e.target.value)
    setIsTyping(true)
  }

  const saveEmail = (validateForm) => {
    validateForm()
    setIsTyping(false)
  }

  return (
    <Formik
      // https://formik.org/docs/guides/validation#validate
      validate={() => {
        const errors = {}
        if (!isValidEmail(emailAddress) && !isEmpty(emailAddress)) {
          errors.emailAddress = t('nfc.tto.auth.errorInvalidEmailAddress')
        }
        return errors
      }}
    >
      {({ errors, validateForm }) => (
        <form className={styles.form}>
          <Input
            type="email"
            value={emailAddress}
            placeholder={t('nfc.tto.auth.emailPlaceholder')}
            onChange={handChange}
            onBlur={() => saveEmail(validateForm)}
            error={!isEmpty(errors.emailAddress) && !isTyping}
            errorMessage={errors.emailAddress}
          />
        </form>
      )}
    </Formik>
  )
}

EmailForm.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  setEmailAddress: PropTypes.func.isRequired
}

export default EmailForm
