import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import isEmpty from 'lodash/isEmpty'

import { storedNfcAccount } from 'redux/reducers'

// I took liberty here to change the request due to not being used on the backend
// only used for logging properties
function _addUserFingerprint(params) {
  let userFingerprint = localStorage.getItem(USER_FINGERPRINT_PARAM)
  if (isEmpty(userFingerprint)) {
    userFingerprint =
      Math.random().toString(36).substring(2, 30) +
      Math.random().toString(36).substring(2, 30)
    localStorage.setItem(USER_FINGERPRINT_PARAM, userFingerprint)
  }
  return { ...params, [USER_FINGERPRINT_PARAM]: userFingerprint }
}

// Custom fetch to append honkGuid for easy fingerprinting in logs
const fetchWithFingerprint = (uri, options) => {
  const url = new URL(uri)
  url.searchParams.append(USER_FINGERPRINT_PARAM, _addUserFingerprint())

  return fetch(url, options)
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = storedNfcAccount().an_tag
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-GUEST-AUTHENTICATION': token ? token.toString() : ''
    }
  }
})

const httpLink = new HttpLink({
  uri: `${API_BASE}/graphql`,
  fetch: fetchWithFingerprint
})

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache()
})

export default client
