// PayPal button
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { connect as formConnect } from 'formik'
import { bindActionCreators, compose } from 'redux'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { isRequestSuccessful } from 'app/helpers/api'
import { PAYPAL_PURCHASE_FLOWS } from 'components/purchase/constants'
import * as NFCActions from 'redux/actions'

import './PaypalPurchaseButton.scss'

const mapStateToProps = ({ nfc }) => ({
  cart: nfc.cart,
  plateNumber: nfc.plateNumber,
  assetTag: nfc.assetTag,
  merchantInfo: nfc.merchantInfo
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

const PaypalPurchaseButton = ({
  merchantInfo,
  assetTag,
  cart,
  disabled,
  formik: { validateForm },
  nfcActions: { createPaypalOrder, hideProcessingTransactionLoader },
  purchaseFlow
}) => {
  const history = useHistory()
  const { paypal_gateway: paypalGateway, currency } = merchantInfo
  if (!paypalGateway || !paypalGateway?.supported) {
    return null
  }

  const [orderId, setOrderId] = useState(null)
  const [readyForPurchase, setReadyForPurchase] = useState(false)

  const { client_id: clientId, merchant_id: merchantId } = paypalGateway

  useEffect(() => {
    if (readyForPurchase) {
      const purchaseUrl = `/p/${assetTag}/checkout/${cart.id}/paypal-checkout?token=${orderId}`
      history.push(purchaseUrl)
    }

    return () => {
      setReadyForPurchase(false)
    }
  }, [readyForPurchase])

  const createOrder = async () => {
    const errors = await validateForm()
    if (!isEmpty(errors)) {
      return null
    }

    const { id: cartId } = cart
    const json = await createPaypalOrder(cartId, purchaseFlow)
    if (isRequestSuccessful(json)) {
      const responseOrderId = get(json, 'data.order_id')
      const paypalOrderId = get(json, 'data.pay_pal_order_id')
      setOrderId(responseOrderId)
      return paypalOrderId
    }
    return null
  }

  const onApprove = () => {
    setReadyForPurchase(true)
  }

  const onError = () => {
    hideProcessingTransactionLoader()
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId,
        merchantId,
        currency,
        enableFunding: 'venmo',
        disableFunding:
          'card,credit,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort'
      }}
    >
      <PayPalButtons
        className="PaypalPurchaseButton"
        disabled={disabled}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
        onCancel={onError}
      />
    </PayPalScriptProvider>
  )
}

PaypalPurchaseButton.propTypes = {
  t: PropTypes.func.isRequired,
  cart: PropTypes.shape({
    id: PropTypes.number
  }),
  plateNumber: PropTypes.string,
  paypal: PropTypes.object,
  assetTag: PropTypes.string,
  nfcActions: PropTypes.shape({
    createParkingSessionPayPal: PropTypes.func.isRequired,
    createPaypalOrder: PropTypes.func.isRequired,
    hideProcessingTransactionLoader: PropTypes.func.isRequired
  }).isRequired,
  formik: PropTypes.shape({
    validateForm: PropTypes.func.isRequired
  }),
  disabled: PropTypes.bool,
  merchantInfo: PropTypes.shape({
    currency: PropTypes.string,
    paypal_gateway: PropTypes.shape({
      client_id: PropTypes.string,
      merchant_id: PropTypes.string,
      supported: PropTypes.bool
    })
  }),
  purchaseFlow: PropTypes.oneOf(Object.values(PAYPAL_PURCHASE_FLOWS))
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  formConnect
)(PaypalPurchaseButton)
