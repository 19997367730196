import { Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './TaxIncludedMessage.module.scss'

const TaxIncludedMessage = ({ className }) => {
  const { t } = useTranslation()

  return (
    <Message
      className={classNames(styles.messageContainer, className)}
      icon="exclamation circle"
      content={t('nfc.zone.taxIncludedMessage')}
    />
  )
}

TaxIncludedMessage.propTypes = {
  className: PropTypes.string
}

export default TaxIncludedMessage
