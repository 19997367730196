import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AssetTagActions from 'redux/actions'
import { trackEvent } from 'app/helpers/analyticsHelpers'

const mapStateToProps = (state) => {
  return {
    loaded: state.nfc.loaded.account
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class AccountLoader extends Component {
  componentDidMount() {
    const { assetTagActions, loaded } = this.props
    if (!loaded) {
      trackEvent('Account Started Loading')
      assetTagActions.loadAccount().then(() => {
        trackEvent('Account Loaded')
      })
    }
  }

  render() {
    return null
  }
}
AccountLoader.propTypes = {
  assetTagActions: PropTypes.shape({
    loadAccount: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountLoader)
