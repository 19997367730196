import { gql } from '@apollo/client'

const CartFields = gql`
  fragment CartFields on Cart {
    id
    hashid
    total
    fee
    itemQuantity
    currencyCode
    discount
    merchantInfo {
      creditCardGateway {
        supported
      }
      paypalGateway {
        supported
      }
      applePayGateway {
        supported
        merchantIdentifier
        displayName
      }
      googlePayGateway {
        supported
        displayName
        gateway
        gatewayMerchantId
        merchantId
      }
    }
  }
`

// This is probably more data than we need, this is just
// to get parity with the existing GuestAPI::AssetTagSerializer
export const AssetTagByName = gql`
  query AssetTagByName($name: String!) {
    assetTagByName(name: $name) {
      name
      zone {
        hashid
        zoneId
        timezone
        htmlDescription
        allowExtensions
        actionType
        validationType
        isEvCharger
        images
        currencyCode
        address {
          street
        }
        company {
          properName
          logoThumbnail
          squareLogoThumbnail
        }
        merchantInfo {
          id
          creditCardGateway {
            supported
          }
          paypalGateway {
            supported
          }
          applePayGateway {
            supported
            merchantIdentifier
            displayName
          }
          googlePayGateway {
            supported
            displayName
            gateway
            gatewayMerchantId
            merchantId
          }
        }
      }
    }
  }
`

export const CreateCart = gql`
  ${CartFields}
  mutation CreateCart($input: CreateCartInput!) {
    createCart(input: $input) {
      cart {
        ...CartFields
      }
      errors
    }
  }
`

export const GetCartRates = gql`
  query GetCartRates($cartId: ID!) {
    cartRates(cartId: $cartId) {
      hashid
      price
      description
      promoRate
    }
  }
`

export const SetCartRate = gql`
  ${CartFields}
  mutation SetCartRate($input: SetCartRateInput!) {
    setCartRate(input: $input) {
      cart {
        ...CartFields
      }
      errors
    }
  }
`
export const InitializeAnonymousAccount = gql`
  mutation InitializeAnonymousAccount(
    $input: InitializeAnonymousAccountInput!
  ) {
    initializeAnonymousAccount(input: $input) {
      account {
        emailAddress
      }
      anTag
      errors
    }
  }
`

export const AddPromoToCart = gql`
  ${CartFields}
  mutation AddPromoToCart($input: AddPromoToCartInput!) {
    addPromoToCart(input: $input) {
      cart {
        ...CartFields
      }
      errors
    }
  }
`

export const SendSmsReceipt = gql`
  mutation SendSmsReceipt($input: SendSmsReceiptInput!) {
    sendSmsReceipt(input: $input) {
      errors
    }
  }
`
