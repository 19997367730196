import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Loader, Dimmer } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import './ProcessingLoader.scss'

const mapStateToProps = ({ nfc }) => {
  return {
    processingTransaction: nfc.processingTransaction
  }
}

class ProcessingLoader extends PureComponent {
  render() {
    const { t, processingTransaction } = this.props
    return (
      <Dimmer page active={processingTransaction}>
        <Loader size="large">
          <div className="ProcessingLoader--header">
            {t('nfc.processingTransaction')}
          </div>
        </Loader>
      </Dimmer>
    )
  }
}

ProcessingLoader.propTypes = {
  t: PropTypes.func.isRequired,
  processingTransaction: PropTypes.bool
}

export default connect(mapStateToProps)(ProcessingLoader)
