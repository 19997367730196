import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Sidebar.module.scss'

const Sidebar = ({ isOpen, side, className, children }) => {
  const sideBarClassName = classNames(
    styles.container,
    {
      [styles.open]: isOpen,
      [styles.right]: side === 'right',
      [styles.left]: side === 'left'
    },
    className
  )

  return <div className={sideBarClassName}>{children}</div>
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  side: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

Sidebar.defaultProps = {
  side: 'left'
}

export default Sidebar
