import classNames from 'classnames'
import PropTypes from 'prop-types'

import './Input.scss'

const Input = ({
  className,
  error,
  type = 'text',
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  errorMessage,
  loading,
  disabled,
  id,
  autoFocus,
}) => {
  const inputClassName = classNames('InputGroup--Input', className, { error })

  return (
    <>
      <input
        id={id}
        className={inputClassName}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={loading || disabled}
        autoFocus={autoFocus}
      />
      {error && errorMessage && <div className="InputGroup--Error">{errorMessage}</div>}
    </>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel', 'number', 'url']),
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default Input
