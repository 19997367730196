import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ComponentLoader from 'components/ComponentLoader'
import PickerZoneInputComponent from 'components/picker_zone/PickerZoneInputComponent'
import * as AssetTagActions from 'redux/actions'
import RightArrow from './_assets/icon-right-arrow.svg'

import './NFCPickerZoneComponent.scss'
import './NFCZoneRoute.scss'

const mapStateToProps = ({ nfc }) => {
  const {
    zone,
    loaded: { zone: loaded }
  } = nfc
  return { zone, loaded }
}

const mapDispatchToProps = (dispatch) => ({
  assetTagActions: bindActionCreators(AssetTagActions, dispatch)
})

const PICKER_TYPES = {
  default: 'default',
  input: 'input'
}

const NFCPickerZoneComponent = ({ zone, loaded, assetTagActions }) => {
  const {
    picker_type: pickerType,
    address: { street },
    html_description: htmlDescription
  } = zone

  const handleClick = (option) => {
    assetTagActions
      .loadZone(option.zone_id)
      .then(() => assetTagActions.clearErrors())
      .then(() => assetTagActions.loadZoneOption(option))
  }

  const renderOptionSelect = () => (
    <div className="NFCPickerZoneComponent--zoneOptionsContainer">
      {zone.options.map((option) => (
        <button
          key={option.zone_id}
          onClick={() => handleClick(option)}
          className="NFCPickerZoneComponent--zoneSelectCard"
          type="button"
        >
          <div className="NFCPickerZoneComponent--zoneSelectCardText">
            <p className="NFCPickerZoneComponent--zoneSelectCardTitle">
              {option.title}
            </p>
            <p>{option.subtitle}</p>
          </div>
          <img
            className="NFCPickerZoneComponent--chevron"
            src={RightArrow}
            alt="grey right arrow"
          />
        </button>
      ))}
    </div>
  )

  return (
    <div className="NFCPickerZoneComponent--purchaseBlock">
      <ComponentLoader active={!loaded} inverted={false}>
        <h2 className="NFCPickerZoneComponent--header">{street}</h2>
        <div className="NFCPickerZoneComponent--subheaderContainer">
          <div
            className="NFCPickerZoneComponent--subheader"
            dangerouslySetInnerHTML={{ __html: htmlDescription }}
          />
        </div>
        {pickerType === PICKER_TYPES.input ? (
          <PickerZoneInputComponent zone={zone} handleClick={handleClick} />
        ) : (
          renderOptionSelect()
        )}
      </ComponentLoader>
    </div>
  )
}

NFCPickerZoneComponent.propTypes = {
  loaded: PropTypes.bool.isRequired,
  zone: PropTypes.shape({
    zone_id: PropTypes.string,
    html_description: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string.isRequired
    }).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        zone_id: PropTypes.string.isRequired
      })
    ).isRequired,
    picker_type: PropTypes.oneOf(Object.values(PICKER_TYPES))
  }).isRequired,
  assetTagActions: PropTypes.shape({
    clearErrors: PropTypes.func.isRequired,
    loadZoneOption: PropTypes.func.isRequired,
    loadZone: PropTypes.func.isRequired
  }).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NFCPickerZoneComponent)
