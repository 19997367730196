import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import infoIcon from 'components/warnings/_assets/info-icon.svg'

import './WarningCardComponent.scss'
import { useEffect, useState} from 'react'
import includes from 'lodash/includes'


const mapStateToProps = ({ nfc }) => {
  const {
    plateNumber,
    rules,
    loaded: {
      rates: ratesLoaded
    }
  } = nfc

  return { plateNumber, ratesLoaded, rules }
}

const WarningCardComponent = ({plateNumber, ratesLoaded, rules}) => {
  const { t } = useTranslation()
  const [showError, setShowError] = useState(false)
  const isRule = includes(rules, true)
  
  useEffect(() => {
    if (isRule) {
      setShowError(true)
    }
    if (showError && !isRule) {
      setShowError(false)
    }
  }, [ratesLoaded])

  if(!showError) return null

  return (
    <div className="WarningCardComponent">
      <div className="WarningCardComponent--container">
        <img className="WarningCardComponent--icon" src={infoIcon}/>
          <div className="WarningCardComponent--text">{t('nfc.warnings.rateRules', {plateNumber})}</div>
      </div>
    </div>
  )
}

WarningCardComponent.propTypes = {
  plateNumber: PropTypes.string,
  rules: PropTypes.object,
  ratesLoaded: PropTypes.bool
}

export default connect(
  mapStateToProps)(WarningCardComponent)