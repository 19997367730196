/* eslint-disable import/no-webpack-loader-syntax */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LngDetector from 'i18next-browser-languagedetector'

const resBundle = require('i18next-resource-store-loader' +
  '?include=\\.json$' +
  `&overrideDir=../../whitelabel/${WHITELABEL}/locales/overrides` +
  '&baseDir=.' +
  '!./locales/index.js')

const defaultVariables = {
  brandName: WHITELABEL_CONFIG.NAME,
  supportEmailAddress: WHITELABEL_CONFIG.SUPPORT_EMAIL_ADDRESS,
  termsUrl: WHITELABEL_CONFIG.TERMS_URL,
  tapHelpUrl: WHITELABEL_CONFIG.TAP_HELP_URL,
  privacyPolicyUrl: WHITELABEL_CONFIG.PRIVACY_POLICY_URL
}

i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    whitelist: ['en', 'fr'],
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: 'common',
    debug: false,
    resources: resBundle,
    interpolation: {
      defaultVariables
    }
  })

export default i18n
/* eslint-enable import/no-webpack-loader-syntax */
