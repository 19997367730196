import { Router } from 'react-router-dom'
import history from 'app/history'
import { trackPage } from 'app/helpers/analyticsHelpers'

import Routes from './routes'

export default class NFCRouter extends React.Component {
  componentDidUpdate() {
    const { location } = this.state
    trackPage(location)
  }

  render() {
    return (
      <Router history={history}>
        <Routes />
      </Router>
    )
  }
}
