import PropTypes from 'prop-types'
import { paymentTypeToIcon } from './helpers'

const PaymentIcon = (props) => {
  const { className, cardType } = props
  return <img className={className} src={paymentTypeToIcon(cardType).default} />
}

PaymentIcon.propTypes = {
  className: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired
}

export default PaymentIcon
