import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'

import './ComponentLoader.scss'

class ComponentLoader extends PureComponent {
  render() {
    const { active } = this.props
    if (active) {
      const { children, ...props } = this.props
      return (
        <div className="ComponentLoader--container">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Loader {...props} />
        </div>
      )
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children
  }
}

ComponentLoader.propTypes = {
  active: PropTypes.bool,
  inverted: PropTypes.bool,
  size: PropTypes.string,
  inline: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node.isRequired
}

ComponentLoader.defaultProps = {
  active: true,
  inverted: true,
  size: 'medium',
  inline: false
}

export default ComponentLoader
