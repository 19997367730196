import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import * as actionTypes from 'redux/actionTypes'
import { CreateCart } from 'components/events/operations'
import  { DateTime } from 'luxon'
import { snakeCaseKeysDeep } from 'components/helpers/formatting'

const mapStateToProps = (state) => {
  return {
    zone: state.nfc.zone,
    parkingSessionHashId: state.nfc.parkingSession.hashid,
    loaded: state.nfc.loaded.cart
  }
}

const CartLoader = ({ zone, loaded, children }) => {
  const dispatch = useDispatch()

  const [loadCart] = useMutation(CreateCart, {
    variables: {
      input: {
        zoneId: zone.hashid,
        startTime: DateTime.now().toISO(),
        productType: 'RESERVE'
      }
    },
    onCompleted: ({ createCart }) => {
      const { cart } = createCart
      const storeCart = snakeCaseKeysDeep(cart)
      storeCart.is_rate_selected = cart.itemQuantity > 0

      dispatch({
        type: actionTypes.NFC_LOAD_CART,
        cart: storeCart
      })
    }
  })

  useEffect(() => {
    loadCart()
  }, [loadCart])

  if (loaded && children) {
    return children
  }
  return null
}

CartLoader.defaultProps = {
  handleError: () => {}
}

CartLoader.propTypes = {
  zone: PropTypes.shape({
    hashid: PropTypes.string.isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    createCart: PropTypes.func.isRequired,
    failedLoadingCart: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func
}
export default connect(mapStateToProps)(CartLoader)
