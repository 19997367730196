import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'
import { withTranslation } from 'react-i18next'
import * as AssetTagActions from 'redux/actions'

import './NFCExtensionTokenRoute.scss'

const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

const ExtensionTokenRoute = (props) => {
  const {
    t,
    history,
    match: {
      params: { extensionId }
    },
    assetTagActions: { loadExpiryToken }
  } = props

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    loadExpiryToken(extensionId).then((json) => {
      if (json.ok) {
        const {
          data: { parking_session_id: sessionId }
        } = json
        history.push(`/p/session/${sessionId}/extend`)
      } else {
        setLoading(false)

        if (includes(json.errors, 'expired_token')) {
          setError(t('nfc.extensionToken.expiredToken'))
        } else {
          setError(t('nfc.extensionToken.generalError'))
        }
      }
    })
  })

  return (
    <div className="NFCExtensionTokenRoute">
      {loading && (
        <Loader size="large" active>
          <div className="NFCExtensionTokenRoute--loadingText">
            {t('nfc.extensionToken.loading')}
          </div>
        </Loader>
      )}
      {!loading && (
        <div className="NFCExtensionTokenRoute--errorText">{error}</div>
      )}
    </div>
  )
}

ExtensionTokenRoute.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      extensionId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  assetTagActions: PropTypes.shape({
    loadExpiryToken: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
  withRouter
)(ExtensionTokenRoute)
