import { Component } from 'react'
import { connect } from 'react-redux'
import { Dimmer } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import VgsProvider from 'components/purchase/VgsProvider'
import { APPLICATION_TYPE } from 'components/constants'
import { PAYPAL_PURCHASE_FLOWS } from 'components/purchase/constants'
import ApplePayPurchaseButton from './buttons/ApplePayPurchaseButton'
import PaypalPurchaseButton from './buttons/PaypalPurchaseButton'
import GooglePayPurchaseButton from './buttons/GooglePayPurchaseButton'
import NavigateToCreditCardButton from './buttons/NavigateToCreditCardButton'
import ChooseCreditCardButton from './buttons/ChooseCreditCardButton'
import DefaultCreditCardPurchaseButton from './buttons/DefaultCreditCardPurchaseButton'
import ZeroDollarPurchaseButton from './buttons/ZeroDollarPurchaseButton'

const mapStateToProps = ({ nfc }) => {
  const { rates, cart, rate, account } = nfc.loaded
  const {
    allTagData: { application_type: applicationType }
  } = nfc
  return {
    creditCard: nfc.account.credit_card,
    cart: nfc.cart,
    loaded: rates && cart && rate && account,
    disabled: !(rates && cart && rate && account) || !nfc.cart.is_rate_selected,
    applicationType
  }
}

class AllPurchaseButtons extends Component {
  renderCreditCardCreateOrDefault = () => {
    const { t, cart, disabled, creditCard } = this.props

    if (isEmpty(creditCard)) {
      return (
        <NavigateToCreditCardButton t={t} cart={cart} disabled={disabled} />
      )
    }

    return (
      <DefaultCreditCardPurchaseButton
        t={t}
        cart={cart}
        disabled={disabled}
        card={creditCard}
      />
    )
  }

  renderZeroDollarButton = () => {
    const { t, cart, disabled } = this.props

    return <ZeroDollarPurchaseButton t={t} cart={cart} disabled={disabled} />
  }

  renderNormalPurchaseButtons = () => {
    const { t, cart, disabled, creditCard, purchaseFlow, applicationType } =
      this.props

    if (applicationType === APPLICATION_TYPE.publicXC) {
      return <div>{this.renderCreditCardCreateOrDefault()}</div>
    }

    return (
      <div>
        <VgsProvider />
        <ApplePayPurchaseButton t={t} cart={cart} disabled={disabled} />
        {this.renderCreditCardCreateOrDefault()}
        <PaypalPurchaseButton
          t={t}
          cart={cart}
          disabled={disabled}
          purchaseFlow={PAYPAL_PURCHASE_FLOWS[purchaseFlow]}
        />
        <GooglePayPurchaseButton cart={cart} disabled={disabled} />
        {!isEmpty(creditCard) && (
          <ChooseCreditCardButton t={t} cart={cart} disabled={disabled} />
        )}
      </div>
    )
  }

  render() {
    const {
      disabled,
      cart: { is_zero_dollar_purchase: isZeroDollar },
      className
    } = this.props

    return (
      <Dimmer.Dimmable className={className}>
        <Dimmer
          inverted
          active={disabled}
          className="NFCAllPurchaseButtons--dimmer"
        />
        {isZeroDollar
          ? this.renderZeroDollarButton()
          : this.renderNormalPurchaseButtons()}
      </Dimmer.Dimmable>
    )
  }
}

AllPurchaseButtons.propTypes = {
  t: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
  disabled: PropTypes.bool,
  cart: PropTypes.shape({
    is_zero_dollar_purchase: PropTypes.bool
  }),
  creditCard: PropTypes.shape({}),
  purchaseFlow: PropTypes.string,
  className: PropTypes.string,
  applicationType: PropTypes.string
}

export default connect(mapStateToProps)(AllPurchaseButtons)
