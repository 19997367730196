import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { fullLight } from 'components/common/Logos'
import ForgetHistoryModal from '../ForgetHistoryModal'
import honkLogo from './_assets/white-logo-and-wordmark.svg'

import './DarkFooter.scss'

const DarkFooter = ({ showDeleteLink }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const { t } = useTranslation()
  const logoRequired = WHITELABEL_CONFIG.FEATURE_FLAGS.REQUIRE_LOGO

  const showModal = () => {
    setModalIsVisible(true)
  }

  const hideModal = () => {
    setModalIsVisible(false)
  }

  const renderDeleteLink = () => {
    if (showDeleteLink) {
      return (
        <div>
          <div
            onClick={showModal}
            onKeyPress={showModal}
            className="NFCDarkFooter--deleteHistory"
          >
            {t('nfc.footer.deletePurchaseHistory')}
          </div>
          <ForgetHistoryModal
            t={t}
            isShowing={modalIsVisible}
            hide={hideModal}
          />
        </div>
      )
    }

    return null
  }

  return !logoRequired ? (
    <div className="NFCDarkFooter">
      <div className="NFCDarkFooter--brandLogo">
        {t('nfc.footer.poweredByCaps')}
        &nbsp; <img className="NFCDarkFooter--brandHonkLogo" src={fullLight} />
      </div>
      {renderDeleteLink()}
    </div>
  ) : (
    <div className="NFCDarkFooter--whiteLabel">
      <div className="NFCDarkFooter--whitelabelLogo">
        &nbsp;{' '}
        <img className="NFCDarkFooter--brandWhitelabelLogo" src={fullLight} />
      </div>
      {renderDeleteLink()}
      <div className="NFCDarkFooter--honkLogo">
        {t('nfc.footer.poweredByCaps')}
        &nbsp; <img className="NFCDarkFooter--brandHonkLogo" src={honkLogo} />
      </div>
    </div>
  )
}

DarkFooter.defaultProps = {
  showDeleteLink: true
}

DarkFooter.propTypes = {
  showDeleteLink: PropTypes.bool
}

export default DarkFooter
