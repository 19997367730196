/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTranslation } from 'react-i18next'
import BrandedTagline from 'components/BrandedTagline'

import './DarkFooter.scss'

const DarkFooter = () => {
  const { t } = useTranslation()
  return (
    <div className="NFCDarkFooter">
      <div className="NFCDarkFooter--branding">
        <BrandedTagline className="TTONFCDarkFooter--tagline" t={t} />
      </div>
    </div>
  )
}

export default DarkFooter
