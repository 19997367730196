import MobileDetect from 'mobile-detect'

export function isiOS() {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.os() === 'iOS'
}

export function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  return md.mobile()
}

export function isSmallScreen() {
  return window.innerWidth < 500
}
