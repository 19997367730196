import ReactGA from 'react-ga4'

export function trackEvent(_name, _properties) {
  // this is not required after removing amplitude.js package and we wre keeping only for reference
  // const nfcStore = store.getState().nfc
  // const defaultData = {
  //   zoneId: get(nfcStore, 'zone.zone_id'),
  //   companyId: get(nfcStore, 'zone.company.unique'),
  //   tagId: nfcStore.assetTag,
  //   ...nfcStore.allTagData
  // }
}

export function trackPage(location) {
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname + location.search
  })
  // this is not required after removing amplitude.js package and we wre keeping only for reference
  // const eventName = 'Page Load'
  // const properties = {
  //   path: location.pathname
  // }
}
