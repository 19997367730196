import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './OrderedList.module.scss'

const OrderedList = ({ instructions, className }) => {
  const OrderedListClassName = classNames(styles.steps, className)

  return (
    <ul className={OrderedListClassName}>
      {instructions.map((instruction, index) => (
        <li className={styles.step} key={instruction.description}>
          <div className={styles.stepHeader}>
            <div
              className={classNames(styles.stepNumber, 'OrderedList--bullet')}
            >
              {index + 1}
            </div>
            {instruction.heading}
          </div>
          {instruction.description && (
            <p className={styles.stepDescription}>{instruction.description}</p>
          )}
        </li>
      ))}
    </ul>
  )
}

OrderedList.propTypes = {
  instructions: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.node,
      description: PropTypes.string
    })
  ),
  className: PropTypes.string
}

export default OrderedList
