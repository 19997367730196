import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Segment } from 'semantic-ui-react'
import { PropTypes } from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

import NFCForm from 'components/NFCForm'
import { AddPromoToCart } from 'components/events/operations'
import { snakeCaseKeysDeep } from 'components/helpers/formatting'
import * as actionTypes from 'redux/actionTypes'
import { cartSelector } from 'redux/reducers'
import 'components/purchase/NFCModal.scss'
import './PromoCodeModal.scss'

const mapStateToProps = ({ nfc: { loaded } }) => ({
  loading: !loaded.cart
})

const PromoCodeModal = ({ isOpen, loading, setIsPromoModalOpen }) => {
  const [t] = useTranslation()
  const [promoCode, setPromoCode] = useState('')
  const [hasError, setHasError] = useState(false)
  const { hashid: cartId } = useSelector(cartSelector)
  const dispatch = useDispatch()

  const [addPromoToCart] = useMutation(AddPromoToCart, {
    variables: { input: { cartId, promoCode } },
    onCompleted: ({ addPromoToCart: { cart, errors } }) => {
      if (isEmpty(errors)) {
        const storeCart = snakeCaseKeysDeep(cart)
        storeCart.is_rate_selected = cart.itemQuantity > 0
        const isZeroDollar = cart.total === 0 && cart.itemQuantity > 0
        storeCart.is_zero_dollar_purchase = isZeroDollar
        dispatch({
          type: actionTypes.NFC_ADD_PROMO_CODE,
          cart: storeCart
        })
        setIsPromoModalOpen(false)
      } else {
        dispatch({
          type: actionTypes.NFC_ADD_PROMO_CODE_FAILED
        })
        setHasError(true)
      }
    }
  })

  const handleChange = (e, { value }) => {
    setPromoCode(value)
  }

  const hasPromoCode = promoCode.length > 0

  return (
    <Modal open={isOpen} className="NFCModal" closeOnDimmerClick>
      <Modal.Content>
        <div className="NFCModal--content">
          <div className="NFCModal--header">
            {t('nfc.zone.promoCode.modalHeader')}
          </div>
          <div>
            <NFCForm.Field>
              <NFCForm.Input
                className="NFCPromoCodeModal--input"
                fluid
                placeholder={t('nfc.zone.promoCode.placeholder')}
                value={promoCode}
                onChange={handleChange}
                error={hasError}
              />
              <NFCForm.ErrorLabel visible={hasError}>
                {t('nfc.zone.promoCode.errorLabel')}
              </NFCForm.ErrorLabel>
            </NFCForm.Field>
          </div>
        </div>
      </Modal.Content>
      {loading && (
        <Modal.Actions className="NFCModal--actions">
          <div className="NFCModal--actionButton">
            <Segment basic loading />
          </div>
        </Modal.Actions>
      )}
      {!loading && (
        <Modal.Actions className="NFCModal--actions">
          <button
            type="button"
            className="NFCPromoCodeModal--textButton NFCModal--actionButton NFCModal--actionButton__cancel"
            onClick={() => setIsPromoModalOpen(false)}
          >
            {t('nfc.zone.promoCode.cancelButton')}
          </button>
          <button
            type="button"
            className={classNames(
              'NFCPromoCodeModal--textButton NFCModal--actionButton',
              {
                'NFCModal--actionButton__disabled': !hasPromoCode
              }
            )}
            onClick={addPromoToCart}
          >
            {t('nfc.zone.promoCode.submitButton')}
          </button>
        </Modal.Actions>
      )}
    </Modal>
  )
}

PromoCodeModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsPromoModalOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default connect(mapStateToProps)(PromoCodeModal)
