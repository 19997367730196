import PropTypes from 'prop-types'
import { Button as SemanticButton } from 'semantic-ui-react'
import classNames from 'classnames'

import './Button.scss'

const Button = ({
  children,
  onClick,
  type = "button",
  loading = false,
  disabled = false,
  primary = true,
  className
}) => {
  const buttonClassName = classNames("TTOButton--container", className, {
    'TTOButton--container__disabled': disabled || loading,
    'primary': primary
  })

  return (
    <SemanticButton
      className={buttonClassName}
      onClick={onClick}
      onKeyDown={onClick}
      type={type}
    >
      {children}
    </SemanticButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired || PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  className: PropTypes.string
}

export default Button
