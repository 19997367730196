import classNames from 'classnames'
import PropTypes from 'prop-types'
import BackArrow from './_assets/icon-arrow-left.svg'

import styles from './Header.module.scss'

const Header = ({
  children,
  right,
  className,
  isDark = true,
  backNav = false,
  onBackClick
}) => {
  const headerClasses = classNames(styles.container, className, {
    [styles.containerDark]: isDark
  })

  const renderBackButton = () => (
    <button className={styles.backButton} onClick={onBackClick} type="button">
      <img src={BackArrow} alt="Left arrow" />
    </button>
  )

  return (
    <header className={headerClasses}>
      <div className={styles.left}>{backNav && renderBackButton()}</div>
      <div className={styles.center}>
        <p>{children}</p>
      </div>
      <div className={styles.right}>{right}</div>
    </header>
  )
}

Header.propTypes = {
  children: PropTypes.node,
  right: PropTypes.element,
  className: PropTypes.string,
  isDark: PropTypes.bool,
  backNav: PropTypes.bool,
  onBackClick: PropTypes.func
}

export default Header
