import React from 'react'
import VgsProvider from './VgsProvider'
import CreditCardPurchaseRoute from './CreditCardPurchaseRoute'

const CrediCardPurchase = () => {
  return (
    <VgsProvider>
      <CreditCardPurchaseRoute />
    </VgsProvider>
  )
}

CrediCardPurchase.propTypes = {}

export default CrediCardPurchase
