/* global VGS_SRC_URL */
import { Component } from 'react'
import makeAsyncScriptLoader from 'react-async-script'
import PropTypes from 'prop-types'

class VgsProvider extends Component {
  render() {
    const { children, VGSCollect } = this.props
    if (children && VGSCollect) {
      return children
    }

    return null
  }
}

VgsProvider.propTypes = {
  children: PropTypes.node,
  VGSCollect: PropTypes.object.isRequired
}

export default makeAsyncScriptLoader(VGS_SRC_URL, { globalName: 'VGSCollect' })(
  VgsProvider
)
