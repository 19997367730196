import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import * as NFCActions from 'redux/actions'
import { isApplePayJsAvailable } from 'components/common/apple_pay_js/ApplePayJSHandler'
import { formatPrice } from 'app/helpers/formatting'
import applePayImg from 'components/_assets/apple-pay-mark-rgb-052318.svg'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { baseUrl } from 'app/helpers/requests'
import { AUTH_TYPES } from 'components/tto/constants'

import PurchaseButton from './PurchaseButton'

import './ApplePayPurchaseButton.scss'

const ApplePayButtonStatus = { UNKNOWN: 0, AVAILABLE: 1, NOT_AVAILABLE: 2 }

const formatAddress = (address = {}) => address.street

const mapStateToProps = ({ nfc }) => ({
  zone: nfc.zone,
  assetTag: nfc.assetTag,
  supported: get(nfc.merchantInfo, ['apple_pay_gateway', 'supported']),
  merchantId: get(nfc.merchantInfo, [
    'apple_pay_gateway',
    'merchant_identifier'
  ]),
  displayName: get(nfc.merchantInfo, ['apple_pay_gateway', 'display_name'])
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class ApplePayPurchaseButton extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      applePayButtonStatus: ApplePayButtonStatus.UNKNOWN
    }
  }

  componentDidMount() {
    const { merchantId } = this.props

    if (merchantId) {
      this.checkApplePayVisibility(merchantId)
    }
  }

  componentDidUpdate(prevProps) {
    const { merchantId } = this.props

    if (!prevProps.merchantId && merchantId) {
      this.checkApplePayVisibility(merchantId)
    }
  }

  handleSubmit = debounce(
    // eslint-disable-next-line react/destructuring-assignment
    this.props.startTtoSession,
    500,
    {
      leading: true,
      trailing: false
    }
  )

  checkApplePayVisibility = (merchantId) => {
    try {
      const canMakePayments = isApplePayJsAvailable(merchantId)
      this.setState({
        applePayButtonStatus: canMakePayments
          ? ApplePayButtonStatus.AVAILABLE
          : ApplePayButtonStatus.NOT_AVAILABLE
      })
    } catch (e) {
      this.setState({
        applePayButtonStatus: ApplePayButtonStatus.NOT_AVAILABLE
      })
    }
  }

  handleClick = () => {
    const { disabled } = this.props

    if (!disabled) {
      this.openApplePay()
    }
  }

  openApplePay = () => {
    const {
      zone: {
        address,
        currency_code: currencyCode,
        merchant_info: { id: merchantInfoId }
      },
      assetTag,
      disabled,
      displayName
    } = this.props

    let countryPaymentInfo = {}
    // TODO: Change
    const total = 20

    if (currencyCode === 'USD') {
      countryPaymentInfo = {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover']
      }
    } else {
      countryPaymentInfo = {
        countryCode: 'CA',
        currencyCode: 'CAD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover', 'interac']
      }
    }

    const paymentRequest = {
      ...countryPaymentInfo,
      requiredBillingContactFields: ['postalAddress'],
      merchantCapabilities: ['supports3DS'],
      total: {
        type: 'pending',
        label: `${displayName} - ${formatAddress(address)}`,
        amount: formatPrice(total)
      }
    }

    const analyticsData = {
      asset_tag: assetTag
    }

    trackEvent('Apple Pay Purchase Started', analyticsData)

    const session = new ApplePaySession(2, paymentRequest)

    const getSession = (url) => {
      return new Promise((resolve, reject) => {
        const apiURL = baseUrl(GUEST_API_BASE)
        const requestUrl = `${apiURL(`apple_pay_merchant_sessions`)}`
        const xhr = new XMLHttpRequest()
        xhr.open('POST', requestUrl)

        // These callbacks need `this` to be
        // correctly bound to the the xhr object.
        // Please don't change them to fat-arrows
        // without making sure the code still works
        xhr.onload = function handleLoad() {
          if (this.status < 200 || this.status >= 300) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject({
              status: this.status,
              statusText: xhr.statusText
            })
          }

          return resolve(JSON.parse(xhr.response))
        }

        xhr.onerror = function handleError() {
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject({
            status: this.status,
            statusText: xhr.statusText
          })
        }

        xhr.setRequestHeader('Content-Type', 'application/json')

        // eslint-disable-next-line no-promise-executor-return
        return xhr.send(
          JSON.stringify({ url, merchant_info_id: merchantInfoId })
        )
      })
    }

    session.onvalidatemerchant = (event) => {
      const { validationURL } = event
      getSession(validationURL).then((response) => {
        const data = JSON.parse(response.data)
        session.completeMerchantValidation(data)
      })
    }

    session.onpaymentauthorized = (event) => {
      const { onSuccess, emailAddress } = this.props
      const { payment } = event
      const {
        token,
        billingContact: { postalCode: avsCode }
      } = payment

      this.handleSubmit({ ...token, avsCode }, AUTH_TYPES.apple_pay_auth, {
        emailAddress
      }).then(({ data }) => {
        const {
          startTtoSession: { errors }
        } = data
        if (isEmpty(errors)) {
          trackEvent('Apple Pay Purchase Completion Succeeded', analyticsData)
          session.completePayment(ApplePaySession.STATUS_SUCCESS)
          onSuccess()
        } else {
          trackEvent('Apple Pay Purchase Completion Failed', analyticsData)
          session.completePayment(ApplePaySession.STATUS_FAILURE)
        }
        return data
      })
    }

    if (!disabled) {
      session.begin()
    }
  }

  render() {
    const { applePayButtonStatus } = this.state
    const { t, disabled, supported } = this.props

    if (applePayButtonStatus !== ApplePayButtonStatus.AVAILABLE) {
      return null
    }

    if (!supported) {
      return null
    }

    return (
      <div>
        <PurchaseButton
          className="NFCApplePayPurchaseButton"
          onClick={this.handleClick}
          disabled={disabled}
        >
          {t('nfc.tto.auth.continueWith')}&nbsp;
          <img
            className="NFCApplePayPurchaseButton--wordmark"
            src={applePayImg}
          />
        </PurchaseButton>
      </div>
    )
  }
}

ApplePayPurchaseButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  supported: PropTypes.bool,
  zone: PropTypes.shape({
    address: PropTypes.shape({
      street: PropTypes.string
    }),
    currency_code: PropTypes.string.isRequired,
    merchant_info: PropTypes.shape({ id: PropTypes.string.isRequired })
  }),
  assetTag: PropTypes.string,
  nfcActions: PropTypes.shape({
    createParkingSessionApplePay: PropTypes.func
  }),
  merchantId: PropTypes.string,
  displayName: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  startTtoSession: PropTypes.func.isRequired,
  emailAddress: PropTypes.string
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ApplePayPurchaseButton
)
