import history from 'app/history'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import { baseUrl, cancelableFetch, addAppVersion, HEADERS } from './requests'

import { handleRawResponse, handleFetchError } from './responses'

export function isRequestSuccessful(json) {
  return json.ok
}

export function getRequestErrors(json) {
  return json.errors
}

export const apiURL = baseUrl(API_BASE)

function _mergeAuthParams(params, user) {
  /* eslint-disable camelcase */
  const authTag = AUTH_PARAM
  /* eslint-enable */

  return {
    ...params,
    [authTag]: user[authTag]
  }
}

function _handleResponseJson(json) {
  if (!isRequestSuccessful(json)) {
    const errors = getRequestErrors(json)

    if (includes(errors, 'missing_authentication')) {
      history.push('/logout')
    } else if (includes(errors, 'unauthorized')) {
      history.push('/login')
    } else if (includes(errors, 'upgrade_required')) {
      history.push('/426')
    } else if (includes(errors, 'verification_required')) {
      history.push('/sms-verify')
    }
  }

  // Return JSON to maintain the rest of the .then chain
  return json
}

// I took liberty here to change the request due to not being used on the backend
// only used for logging properties
function _addUserFingerprint(params) {
  let userFingerprint = localStorage.getItem(USER_FINGERPRINT_PARAM)
  if (isEmpty(userFingerprint)) {
    userFingerprint =
      Math.random().toString(36).substring(2, 30) +
      Math.random().toString(36).substring(2, 30)
    localStorage.setItem(USER_FINGERPRINT_PARAM, userFingerprint)
  }
  return { ...params, [USER_FINGERPRINT_PARAM]: userFingerprint }
}

export function externalGET(html, params = {}) {
  let url = new URL(html)
  if (params) {
    const urlParams = new URLSearchParams()
    Object.keys(params).forEach((key) => {
      urlParams.append(key, params[key])
    })
    url = new URL(`${url}?${urlParams.toString()}`)
  }
  const path = url.toString()
  const requestPromise = cancelableFetch(path)

  return requestPromise.then(handleRawResponse).then((response) => {
    return response.json()
  })
}

export function externalPOST(html, params = {}) {
  const url = new URL(html)
  const body = JSON.stringify(params)
  const path = url.toString()
  const requestPromise = cancelableFetch(path, {
    method: 'POST',
    headers: HEADERS,
    body
  })
  return requestPromise.then(handleRawResponse).then((response) => {
    return response.json()
  })
}

export function apiGET(relativePath, params = {}) {
  const fullParams = addAppVersion(_addUserFingerprint(params))
  return cancelableFetch(apiURL(relativePath, fullParams))
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiGET(relativePath, currentUser, params) {
  const authParams = _mergeAuthParams(params, currentUser)
  return apiGET(relativePath, authParams).then(_handleResponseJson)
}

export function apiPOST(relativePath, params = {}) {
  const fullParams = addAppVersion(_addUserFingerprint(params))
  const body = JSON.stringify(fullParams)
  const requestPromise = cancelableFetch(apiURL(relativePath), {
    method: 'POST',
    headers: HEADERS,
    body
  })

  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiPOST(relativePath, currentUser, params = {}) {
  const authParams = _mergeAuthParams(params, currentUser)
  return apiPOST(relativePath, authParams).then(_handleResponseJson)
}

export function apiPATCH(relativePath, params = {}) {
  const fullParams = addAppVersion(_addUserFingerprint(params))
  const body = JSON.stringify(fullParams)
  const requestPromise = cancelableFetch(apiURL(relativePath), {
    method: 'PATCH',
    headers: HEADERS,
    body
  })

  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiPATCH(relativePath, currentUser, params = {}) {
  const authParams = _mergeAuthParams(params, currentUser)
  return apiPATCH(relativePath, authParams).then(_handleResponseJson)
}

export function apiDELETE(relativePath, params = {}) {
  const fullParams = addAppVersion(_addUserFingerprint(params))
  const requestPromise = cancelableFetch(apiURL(relativePath, fullParams), {
    method: 'DELETE',
    header: HEADERS
  })
  return requestPromise
    .then(handleRawResponse, handleFetchError)
    .then((response) => {
      return response.json()
    })
}

export function authApiDELETE(relativePath, currentUser, params) {
  const authParams = _mergeAuthParams(params, currentUser)
  return apiDELETE(relativePath, authParams).then(_handleResponseJson)
}
