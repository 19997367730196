import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Button.module.scss'

const Button = ({
  children,
  onClick,
  type = 'button',
  loading = false,
  disabled = false,
  className
}) => {
  const buttonClassName = classNames(styles.container, className, {
    [styles.loaded]: loading
  })

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired || PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default Button
