import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const initializeTagManagerAndGA = () => {
  if (!isEmpty(GOOGLE_TAG_MANAGER_ID)) {
    const tagMangerArgs = {
      gtmId: GOOGLE_TAG_MANAGER_ID
    }

    TagManager.initialize(tagMangerArgs)
  }

  const gaTags = []
  if (!isEmpty(GA_MEASUREMENT_ID)) {
    gaTags.push({
      trackingId: GA_MEASUREMENT_ID
    })
  }
  if (!isEmpty(GTAG_CONVERSION_ID)) {
    gaTags.push({ trackingId: GTAG_CONVERSION_ID })
  }

  ReactGA.initialize(gaTags)
}

export default initializeTagManagerAndGA

export const ga4Conversion = (response, cart) => {
  // https://developers.google.com/tag-platform/devguides/events

  const invoiceNumber = get(response, 'transaction_history.invoice_number', '')

  ReactGA.gtag('event', 'conversion', {
    send_to: `${GTAG_CONVERSION_ID}/${GTAG_CONVERSION_LABEL}`,
    value: parseFloat(cart.total),
    currency: cart.currency_code,
    transaction_id: invoiceNumber.toUpperCase()
  })
}

// Generic event tracking for GTM
export const tagEvent = (event) => {
  // need to check the specific object because winddow.google_tag_manager is defined even if GTM is not initialized
  if (!isEmpty(get(window, `google_tag_manager[${GOOGLE_TAG_MANAGER_ID}]`, null))) {
    TagManager.dataLayer({
      dataLayer: {
        ...event
      }
    })
  }
}

export const tagPurchase = (response, cart) => {
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
  const invoiceNumber = get(response, 'transaction_history.invoice_number', '')

  tagEvent({
    event: 'purchase',
    ecommerce: {
      transaction_id: invoiceNumber.toUpperCase(),
      value: parseFloat(cart.total),
      currency: cart.currency_code,
      coupon_id: cart.promo_code_id,
      items: [
        {
          item_id: response.zone?.zone_id,
          item_name: response.zone?.address?.street,
          discount: parseFloat(cart.discount),
          item_brand: response.zone?.company?.proper_name,
          item_category: cart.product_type,
          price: parseFloat(cart.price)
        }
      ]
    }
  })
}
