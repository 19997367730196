import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import * as NFCActions from 'redux/actions'
import { isRequestSuccessful } from 'app/helpers/api'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { DEFAULT_RESERVATION_PLATE } from 'components/purchase/constants'
import PurchaseButton from './PurchaseButton'

import './ZeroDollarPurchaseButton.scss'

const mapStateToProps = ({ nfc }) => {
  const {
    assetTag,
    cart: { id: cartId, is_extension: isExtension }
  } = nfc

  return {
    assetTag,
    cartId,
    isExtension: !!isExtension
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class ZeroDollarPurchaseButton extends PureComponent {
  handleClick = () => {
    const {
      cartId,
      isExtension,
      assetTag,
      nfcActions: { createParkingSessionZeroDollar },
      onSuccess
    } = this.props

    const analyticsData = {
      is_extension: isExtension,
      asset_tag: assetTag
    }

    trackEvent('Zero Dollar Purchase Started', analyticsData)

    createParkingSessionZeroDollar(cartId, DEFAULT_RESERVATION_PLATE).then(
      (json) => {
        if (isRequestSuccessful(json)) {
          trackEvent('Zero-Dollar Purchase Completion Succeeded', analyticsData)
          onSuccess({ parkingSession: json.data })
        } else {
          trackEvent('Zero Dollar Purchase Completion Failed', analyticsData)
        }
      }
    )
  }

  render() {
    const { t, disabled } = this.props

    return (
      <PurchaseButton
        className="NFCZeroDollarPurchaseButton"
        onClick={this.handleClick}
        disabled={disabled}
      >
        {t('nfc.paymentMethods.zeroDollar')}
      </PurchaseButton>
    )
  }
}

ZeroDollarPurchaseButton.propTypes = {
  t: PropTypes.func.isRequired,
  cartId: PropTypes.number.isRequired,
  plateNumber: PropTypes.string,
  disabled: PropTypes.bool,
  isExtension: PropTypes.bool,
  assetTag: PropTypes.string,
  nfcActions: PropTypes.shape({
    createParkingSessionZeroDollar: PropTypes.func
  }).isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ZeroDollarPurchaseButton
)
