import  { DateTime } from 'luxon'

export const formatDateTime = (dateString) => {
  const isoDateTime = DateTime.fromISO(dateString)
  
  if(isoDateTime.isValid) {
    return isoDateTime
  }

  return DateTime.fromJSDate(dateString)
}
