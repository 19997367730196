import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formatDateTime } from 'app/helpers/formatDateTime'
import { getIsPurchaseable } from 'redux/reducers'

import styles from './extensionInfo.module.scss'

const mapStateToProps = ({ nfc }) => ({
  expiry: nfc.parkingSession.expiry,
  loaded: nfc.loaded.parkingSession,
  isPurchaseable: nfc.loaded.rates && getIsPurchaseable(nfc)
})

class ExtensionInfo extends Component {
  formatInboundDate = (date) => {
    return formatDateTime(date).toFormat('t')
  }

  render() {
    const { expiry, loaded, t, isPurchaseable, className } = this.props
    return (
      <div className={className}>
        <h3 className={styles.title}>{t('nfc.extend.title')}</h3>
        {loaded && (
          <>
            <p className={styles.expiryInfo}>
              {t('nfc.extend.expiry', {
                expiry: this.formatInboundDate(expiry)
              })}
            </p>
            {isPurchaseable && (
              <p className={styles.rateInstruction}>
                {t('nfc.extend.rateCTA')}
              </p>
            )}
          </>
        )}
      </div>
    )
  }
}

ExtensionInfo.propTypes = {
  t: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  expiry: PropTypes.instanceOf(Date).isRequired,
  isPurchaseable: PropTypes.bool.isRequired,
  className: PropTypes.string
}

export default connect(mapStateToProps)(ExtensionInfo)
