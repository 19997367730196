import camelCase from 'lodash/camelCase'

const path = require('path')

// This is designed to handle only file per folder.
// Putting more than one file will result in weird behaviour.

const KNOWN_LOGO_DIRNAMES = [
  'full_dark',
  'full_light',
  'icon_dark',
  'icon_light',
  'wordmark_dark',
  'wordmark_light'
]

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    const simpleKey = path.dirname(next).replace(/^\.\//, '')

    if (KNOWN_LOGO_DIRNAMES.includes(simpleKey)) {
      const camelKey = camelCase(simpleKey)
      acc[camelKey] = require(next)
    }

    return acc
  }, {})

const logos = importAll(
  require.context('Theme/assets/logos/', true, /\.(gif|png|svg)$/)
)

// Because you can't export one-at-a-time dynamically
export const { fullDark } = logos
export const { fullLight } = logos
export const { iconDark } = logos
export const { iconLight } = logos
export const { wordmarkDark } = logos
export const { wordmarkLight } = logos

export default logos
