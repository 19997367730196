import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { isRequestSuccessful } from 'app/helpers/api'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { redirectToReceiptReminder } from 'components/purchase/helpers'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { tagPurchase, ga4Conversion } from 'app/helpers/tagManager'

import * as NFCActions from 'redux/actions'

const mapStateToProps = ({ nfc: { plateNumber, cart } }) => ({
  plateNumber,
  cart
})

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

const PaypalCheckoutRoute = ({
  plateNumber,
  cart,
  match: {
    params: { cartId, assetTag }
  },
  location: { search },
  history,
  nfcActions: { showProcessingTransactionLoader, createParkingSessionPayPal }
}) => {
  useEffect(() => {
    showProcessingTransactionLoader()

    const query = new URLSearchParams(search)
    const token = query.get('token')

    if (query && token) {
      createParkingSessionPayPal(cartId, plateNumber, token).then((json) => {
        if (isRequestSuccessful(json)) {
          trackEvent('Paypal Completion Succeeded')
          const { hashid } = json.data
          ga4Conversion(json.data, cart)
          tagPurchase(json.data, cart)
          redirectToReceiptReminder(hashid)
        } else {
          trackEvent('Paypal Completion Failed')
          // Include querysting as an indicator
          // to not auto-clear error content
          // when mounting the asset tag route
          history.push({
            pathname: `/p/${assetTag}`,
            search: queryString.stringify({ preserveErrorsOnMount: true })
          })
        }
      })
    }
  }, [])

  const { t } = useTranslation()

  return <ProcessingLoader t={t} />
}

PaypalCheckoutRoute.propTypes = {
  plateNumber: PropTypes.string,
  cart: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cartId: PropTypes.string.isRequired,
      assetTag: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  nfcActions: PropTypes.shape({
    createParkingSessionPaypal: PropTypes.func.isRequired,
    showProcessingTransactionLoader: PropTypes.func.isRequired,
    createParkingSessionPayPal: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaypalCheckoutRoute)
