import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { cartSelector, purchaseElementsLoadedSelector } from 'redux/reducers'

import styles from './PromoCode.module.scss'

const PromoCode = ({ openPromoModal }) => {
  const [t] = useTranslation()
  const cart = useSelector(cartSelector)
  const purchaseLoaded = useSelector(purchaseElementsLoadedSelector)
  const loading = !purchaseLoaded
  const { discount } = cart
  const hasPromoCode = discount > 0

  const handleClick = () => {
    if (!loading) {
      openPromoModal()
    }
  }

  const renderEmptyPromoCode = () => {
    return (
      <button
        type="button"
        className={classNames(styles.promoCode, styles.textButton, {
          [styles.disabled]: loading,
          [styles.empty]: !loading
        })}
        onClick={handleClick}
      >
        {t('nfc.zone.promoCode.addPromo')}
      </button>
    )
  }
  const renderAppliedPromoCode = () => {
    return (
      <div className={classNames(styles.promoCode, styles.applied)}>
        <div>{t('nfc.zone.promoCode.discount')}</div>
      </div>
    )
  }

  return hasPromoCode ? renderAppliedPromoCode() : renderEmptyPromoCode()
}

PromoCode.propTypes = {
  openPromoModal: PropTypes.func.isRequired
}

export default PromoCode
