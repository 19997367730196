import classNames from "classnames"
import PropTypes from "prop-types"
import BackArrow from './_assets/icon-arrow-left.svg'

import "./Header.scss"

const DarkHeader = ({
  children,
  right,
  className,
  isDark = true,
  backNav = false,
  onBackClick
}) => {
  const headerClasses = classNames(
    'Header--container',
    className, {
      'Header--container__dark': isDark
    }
  )

  const renderBackButton = () => (
    <button className="Header--backButton" onClick={onBackClick} type="button">
      <img src={BackArrow} alt="Left arrow" />
    </button>
  )

  return (
    <header className={headerClasses}>
      <div className="Header--left">
        {backNav && renderBackButton()}
      </div>
      <div className="Header--center">
        <p>{children}</p>
      </div>
      <div className="Header--right">{right}</div>
    </header>
  )
}

DarkHeader.propTypes = {
  children: PropTypes.node,
  right: PropTypes.element,
  className: PropTypes.string,
  isDark: PropTypes.bool,
  backNav: PropTypes.bool,
  onBackClick: PropTypes.func
}

export default DarkHeader
