/* global VGS_VAULT_ID VGS_VAULT_ENV VGS_VAULT_CNAME */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Icon, Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import mapValues from 'lodash/mapValues'
import indexOf from 'lodash/indexOf'

import PayWithNewCreditCardButton from 'components/events/purchase/buttons/PayWithNewCreditCardButton'
import CvvModal from 'components/purchase/CvvModal'
import ProcessingLoader from 'components/purchase/ProcessingLoader'
import DarkFooter from 'components/events/shared/DarkFooter'
import NFCShowErrorComponent from 'components/NFCShowErrorComponent'
import CardErrorModal from 'components/CardErrorModal'
import NFCForm from 'components/NFCForm'
import VgsProvider from 'components/purchase/VgsProvider'
import vgsCss from 'components/tto/CreditCardPurchaseRoute/VgsInputCss'
import { paymentTypeToIcon } from 'components/helpers'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isRequestSuccessful } from 'app/helpers/api'
import { DEFAULT_RESERVATION_PLATE } from 'components/purchase/constants'

import * as NFCActions from 'redux/actions'

import styles from './NFCCreditCard.module.scss'

const mapStateToProps = ({ nfc }) => {
  return {
    cart: nfc.cart,
    assetTag: nfc.assetTag,
    account: nfc.account
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    nfcActions: bindActionCreators(NFCActions, dispatch)
  }
}

class NFCCreditCard extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      showCvvModal: false,
      isSubmitting: false,
      validForm: false,
      // set to true as init so it doesn't show msg at the start
      cvcValid: true,
      numberValid: true,
      expiryValid: true,
      postalValid: true,
      modalError: 'nfc.errors.general',
      isErrorModalOpen: false
    }
  }

  componentDidMount() {
    this.form = window.VGSCollect.create(
      VGS_VAULT_ID,
      VGS_VAULT_ENV,
      (state) => {
        const valids = []
        mapValues(state, (field) => {
          if (
            field.isDirty === true &&
            field.isValid === false &&
            field.isFocused === false &&
            field.isEmpty === false
          ) {
            valids.push(field.isValid)
            // Error msg for each field
            if (field.name === 'number') {
              this.setState({ numberValid: false })
            }
            if (field.name === 'cvc') {
              this.setState({ cvcValid: false })
            }
            if (field.name === 'expiry') {
              this.setState({ expiryValid: false })
            }
            if (field.name === 'avs_code') {
              this.setState({ postalValid: false })
            }
          } else {
            // Clear error msg for each field
            if (field.name === 'number') {
              this.setState({ numberValid: true })
            }
            if (field.name === 'cvc') {
              this.setState({ cvcValid: true })
            }
            if (field.name === 'expiry') {
              this.setState({ expiryValid: true })
            }
            if (field.name === 'avs_code') {
              this.setState({ postalValid: true })
            }
          }
        })
        if (indexOf(valids, false) === -1) {
          const { nfcActions } = this.props
          nfcActions.clearErrors()
          this.setState({ validForm: true })
        }
      }
    )
    const css = {
      ...vgsCss,
      'text-align': 'left',
      'font-weight': 'normal'
    }

    if (VGS_VAULT_ENV === 'live') {
      this.form.useCname(VGS_VAULT_CNAME)
    }

    this.form.field('#cc-number', {
      type: 'card-number',
      name: 'number',
      successColor: '#0FA844',
      errorColor: '#DA1818',
      validations: ['required', 'validCardNumber'],
      css
    })

    this.form.field('#cc-expiration-date', {
      type: 'card-expiration-date',
      name: 'expiry',
      validations: ['required', 'validCardExpirationDate'],
      yearLength: 2,
      placeholder: 'MM/YY',
      css
    })

    this.form.field('#cc-cvc', {
      type: 'card-security-code',
      name: 'cvc',
      validations: ['required', 'validCardSecurityCode'],
      css
    })

    this.form.field('#cc-postal', {
      type: 'text',
      name: 'avs_code',
      // Uncomment below once we decide to require/validate postal code
      // validations: ['required', 'postal_code/us,ca'],
      autoComplete: 'shipping postal-code',
      css
    })
  }

  /* eslint-disable react/sort-comp */
  showCvvModal = () => {
    this.setState({ showCvvModal: true })
  }
  /* eslint-enable */

  hideCvvModal = () => {
    this.setState({ showCvvModal: false })
  }

  closeCardErrorModal = () => {
    this.setState({ isSubmitting: false, isErrorModalOpen: false })
  }

  openCardErrorModal = () => {
    this.setState({ isErrorModalOpen: true })
  }

  _submit = (e) => {
    e.preventDefault()
    this.setState({ isSubmitting: true })
    const {
      nfcActions: {
        showProcessingTransactionLoader,
        hideProcessingTransactionLoader,
        addError,
        clearErrors
      },
      cart,
      assetTag
    } = this.props
    const { extend_id: extendId } = cart
    const analyticsData = {
      is_extension: !!extendId,
      asset_tag: assetTag
    }
    const { validForm } = this.state
    clearErrors()
    if (!validForm) {
      addError('bad_cc_form')
      this.setState({ isSubmitting: false })
      return
    }
    trackEvent('Credit Card Purchase-With-Capture Clicked', analyticsData)

    try {
      showProcessingTransactionLoader()
      this.purchase()
    } catch {
      hideProcessingTransactionLoader()
    }
  }

  purchase = () => {
    const {
      nfcActions: { addError, hideProcessingTransactionLoader },
      account: { hashid }
    } = this.props

    new Promise((resolve) => {
      this.form.submit(
        '/v4/credit_cards/encrypt',
        {
          data: { uniq_identifier: hashid }
        },
        (_, response) => {
          if (response.ok) {
            resolve(response)
          } else {
            addError('bad_cc_form')
            this.setState({ isSubmitting: false })
            hideProcessingTransactionLoader()
          }
        }
      )
    }).then((response) => {
      if (response.ok) {
        this.createParkingSession(response.data)
      }
    })
  }

  createParkingSession = (data) => {
    const { enc_payload: encPayload, request_id: requestId } = data
    const {
      nfcActions: {
        hideProcessingTransactionLoader,
        createParkingSessionTransactionId
      },
      cart,
      assetTag,
      onSuccess
    } = this.props
    const { extend_id: extendId } = cart
    const analyticsData = {
      is_extension: !!extendId,
      asset_tag: assetTag
    }
    createParkingSessionTransactionId(
      cart.id,
      DEFAULT_RESERVATION_PLATE,
      encPayload,
      requestId
    ).then((json) => {
      if (isRequestSuccessful(json)) {
        trackEvent('Credit Card Purchase Completion Succeeded', analyticsData)
        onSuccess({ parkingSession: json.data })
      } else {
        trackEvent('Credit Card Purchase Completion Failed', analyticsData)
        this.setState({ isSubmitting: false })
        hideProcessingTransactionLoader()
        this.openCardErrorModal()
        // TODO - handle errors
      }
    })
  }

  handleSubmit = debounce(this._submit, 500, { leading: true, trailing: false })

  render() {
    const { t, setIsCCScreenOpen } = this.props
    const {
      showCvvModal,
      isSubmitting,
      numberValid,
      cvcValid,
      expiryValid,
      postalValid,
      isErrorModalOpen,
      modalError
    } = this.state
    return (
      <VgsProvider>
        <div className={styles.creditCardPage}>
          <div className={styles.content}>
            <ProcessingLoader t={t} />
            <div className={styles.backContainer}>
              <button
                type="button"
                onClick={() => setIsCCScreenOpen(false)}
                className={styles.back}
              >
                <Icon name="left chevron" className={styles.backIcon} />
                {t('nfc.navigation.goBack')}
              </button>
            </div>
            <div className={styles.headerBlock}>
              <h1 className={styles.header}>
                {t('nfc.creditCardPayment.creditCard')}
              </h1>
              <p className={styles.subHeader}>
                {t('nfc.creditCardPayment.enterCard')}
              </p>
              <div className={styles.brandWrapper}>
                {['MC', 'DI', 'VI', 'AMEX'].map((cardType) => (
                  <img
                    className={styles.cardBrand}
                    src={paymentTypeToIcon(cardType)}
                  />
                ))}
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardContent}>
                <NFCShowErrorComponent t={t} />
                <div id="cc-number" className={styles.vgsInput}>
                  <div className="NFCForm--label">
                    {t('nfc.creditCardPayment.cardNumberLabel')}
                  </div>
                  <div className="form-control-static">
                    <span className="fake-input" />
                  </div>
                  <NFCForm.ErrorLabel visible={!numberValid}>
                    {t('nfc.creditCardPayment.cardNumberInvalid')}
                  </NFCForm.ErrorLabel>
                </div>
                <Grid columns={2}>
                  <Grid.Column className={styles.formGridColumn}>
                    <div id="cc-expiration-date" className={styles.vgsInput}>
                      <div className="NFCForm--label">
                        {' '}
                        {t('nfc.creditCardPayment.expirationLabel')}
                      </div>
                      <div className="form-control-static">
                        <span />
                      </div>
                      <NFCForm.ErrorLabel visible={!expiryValid}>
                        {t('nfc.creditCardPayment.expiryInvalid')}
                      </NFCForm.ErrorLabel>
                    </div>
                  </Grid.Column>
                  <Grid.Column className={styles.formGridColumn}>
                    <div id="cc-cvc" className={styles.vgsInput}>
                      <div className="NFCForm--label">
                        {' '}
                        {t('nfc.creditCardPayment.cvvLabel')} &nbsp;
                        <Icon
                          name="question circle"
                          onClick={this.showCvvModal}
                        />
                      </div>
                      <div className="form-control-static">
                        <span className="fake-input" />
                      </div>
                      <NFCForm.ErrorLabel visible={!cvcValid}>
                        {t('nfc.creditCardPayment.cvvInvalid')}
                      </NFCForm.ErrorLabel>
                    </div>
                  </Grid.Column>
                </Grid>

                <div
                  id="cc-postal"
                  className="NFCCreditCardPurchaseRoute--VGS-input"
                >
                  <div className="NFCForm--label">
                    {' '}
                    {t('nfc.creditCardPayment.postalZipCode')}
                  </div>
                  <div className="form-control-static">
                    <span className="fake-input" />
                  </div>
                  <NFCForm.ErrorLabel visible={!postalValid}>
                    {t('nfc.creditCardPayment.postalInvalid')}
                  </NFCForm.ErrorLabel>
                </div>
              </div>
            </div>
            <div className={styles.cardButton}>
              <PayWithNewCreditCardButton
                t={t}
                onClick={this._submit}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className={styles.darkFooter}>
            <DarkFooter t={t} showDeleteLink={false} />
          </div>
          <CvvModal t={t} isOpen={showCvvModal} onClose={this.hideCvvModal} />
          <CardErrorModal
            t={t}
            open={isErrorModalOpen}
            message={modalError}
            onClose={this.closeCardErrorModal}
            ref="cardErrorModal"
          />
        </div>
      </VgsProvider>
    )
  }
}

NFCCreditCard.propTypes = {
  t: PropTypes.func,
  nfcActions: PropTypes.shape({
    createParkingSessionTransactionId: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    addError: PropTypes.func.isRequired,
    showProcessingTransactionLoader: PropTypes.func.isRequired,
    hideProcessingTransactionLoader: PropTypes.func.isRequired
  }),
  cart: PropTypes.shape({
    id: PropTypes.number.isRequired,
    extend_id: PropTypes.string
  }),
  assetTag: PropTypes.string,
  account: PropTypes.shape({
    hashid: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  setIsCCScreenOpen: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter
)(NFCCreditCard)
