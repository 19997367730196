import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { PureComponent } from 'react'
import { compose } from 'redux'
import { connect as formConnect } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { trackEvent } from 'app/helpers/analyticsHelpers'

import PurchaseButton from './PurchaseButton'

import './NavigateToCreditCardButton.scss'

class NavigateToCreditCardButton extends PureComponent {
  handleClick = () => {
    const {
      disabled,
      formik: { validateForm }
    } = this.props

    if (!disabled) {
      validateForm().then((errors) => {
        if (isEmpty(errors)) {
          this.redirectToCreditCardForm()
        }
      })
    }
  }

  redirectToCreditCardForm = () => {
    const {
      history,
      match: {
        params: { assetTag }
      }
    } = this.props
    trackEvent('Credit Card Redirect Selected')
    history.push(`/p/${assetTag}/credit-card-purchase`)
  }

  render() {
    const { t, disabled } = this.props
    return (
      <PurchaseButton
        className="NFCNavigateToCreditCardButton"
        onClick={this.handleClick}
        disabled={disabled}
      >
        {t('nfc.paymentMethods.payWithCreditCard')}
      </PurchaseButton>
    )
  }
}

NavigateToCreditCardButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.shape({
    validateForm: PropTypes.func.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      assetTag: PropTypes.string
    })
  }).isRequired,
}

export default compose(formConnect, withRouter)(NavigateToCreditCardButton)
