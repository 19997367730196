import { PureComponent } from 'react'
import { Form, Input as OriginalInput } from 'semantic-ui-react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import './TTOForm.scss'

const TTOForm = (props) => {
  return <Form {...props} />
}

const Label = ({ children, className }) => {
  return (
    <div className={classNames('TTOForm--label', className)}>{children}</div>
  )
}

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

const Field = ({ className, ...otherProps }) => {
  return (
    <Form.Field
      className={classNames('TTOForm--field', className)}
      {...otherProps}
    />
  )
}

Field.propTypes = {
  className: PropTypes.string
}

const ErrorLabel = ({ className, children, visible }) => {
  if (visible) {
    return (
      <div className={classNames('TTOForm--errorLabel', className)}>
        {children}
      </div>
    )
  }

  return null
}

ErrorLabel.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.node
}

class Input extends PureComponent {
  render() {
    const { className, ...otherProps } = this.props
    return (
      <OriginalInput
        className={classNames('TTOForm--input', className)}
        {...otherProps}
      />
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool
}

TTOForm.Label = Label
TTOForm.Field = Field
TTOForm.ErrorLabel = ErrorLabel
TTOForm.Input = Input

export default TTOForm
