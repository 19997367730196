import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal, Divider } from 'semantic-ui-react'
import Button from 'components/shared/Button'

import styles from './ZoneDescriptionModal.module.scss'

const ZoneDescriptionModal = ({ open, zoneDescription, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} className={styles.modalContainer}>
      <Modal.Content className={styles.modalContent}>
        <div className={styles.modal}>
          <div
            dangerouslySetInnerHTML={{ __html: zoneDescription }}
            className={styles.message}
          />
          <Divider className={styles.divider} />
          <Button onClick={onClose}>
            {t('nfc.zone.zoneDescription.close')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

ZoneDescriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  zoneDescription: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

export default ZoneDescriptionModal
