import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { setRate } from 'redux/actions'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { formatPrice } from 'app/helpers/formatting'
import Sidebar from 'components/common/Sidebar'
import TaxIncludedMessage from 'components/TaxIncludedMessage'
import { ReactComponent as ArrowIcon } from './_assets/icon-arrow-left-dynamic-color.svg'

import styles from './RateSelectComponent.module.scss'

const RateSelectComponent = ({
  onClose,
  isOpen,
  rates,
  selectedRate,
  cartId
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleRateSelect = (e) => {
    const { value } = e.currentTarget
    const rateId = parseInt(value, 10)

    if (selectedRate?.id === rateId) return

    const analyticsData = { rate_id: rateId }
    trackEvent('Rate Selected', analyticsData)

    onClose()
    // Slideout animation is 0.3s
    // If dispatch is fired right away, the loading animation will immediately appear
    // Causing side bar disappear instead of sliding out
    setTimeout(() => {
      dispatch(setRate(cartId, rateId))
    }, 300)
  }

  return (
    <Sidebar
      className={styles.rateSelectContainer}
      onClose={onClose}
      isOpen={isOpen}
      side="right"
    >
      <div className={styles.navContainer}>
        <button type="button" onClick={onClose}>
          <ArrowIcon />
          {t('nfc.navigation.goBack')}
        </button>
        <TaxIncludedMessage className={styles.taxIncludedMessage} />
      </div>
      <div className={styles.rateButtonContainer}>
        {rates.map((rate) => (
          <button
            type="button"
            onClick={handleRateSelect}
            key={rate.id}
            value={rate.id}
            className={classNames(styles.rateButton, {
              [styles.selected]: selectedRate?.id === rate.id
            })}
          >
            <span className={styles.rateDescription}>{rate.description}</span>
            <span className={styles.ratePrice}>${formatPrice(rate.price)}</span>
          </button>
        ))}
      </div>
    </Sidebar>
  )
}

RateSelectComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedRate: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  cartId: PropTypes.number.isRequired
}

export default RateSelectComponent
