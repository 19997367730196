// VGS font-weight appears thicker than other text
// position: 'static' is required for autocomplete
// Must add a margin to see the boxShadow, subtract the margin width from the width
// Margin same as box-shadow blur radius
export default {
  height: '45px',
  width: 'calc(100% - 7px - 7px)',
  margin: '7px',
  backgroundColor: '#F0F0F0',
  boxSizing: 'border-box',
  fontFamily: 'Helvetica, sans-serif',
  fontWeight: '200',
  fontSize: '15px',
  padding: '0 15px',
  border: '1px solid transparent',
  borderRadius: '5px',
  transition: 'border-color .15s ease-in-out',
  '&:focus': {
    borderColor: '#00C1E0',
    boxShadow: '0px 0px 7px 2px #00C1E026',
    background: '#ffffff'
  },
  '&:-webkit-autofill': {
    '-webkit-box-shadow': 'none',
    '-webkit-text-fill-color': '#2B2B2B',
    background: '#F0F0F0 !important'
  },
  '-webkit-appearance': 'none',
  '-webkit-tap-highlight-color': 'transparent'
}
