import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { PureComponent } from 'react'
import { compose } from 'redux'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import PurchaseButton from './PurchaseButton'

import './NavigateToCreditCardButton.scss'

class NavigateToCreditCardButton extends PureComponent {
  handleClick = () => {
    const { disabled } = this.props

    if (!disabled) {
      this.redirectToCreditCardForm()
    }
  }

  redirectToCreditCardForm = () => {
    const { setIsCCScreenOpen } = this.props
    trackEvent('Credit Card Redirect Selected')
    setIsCCScreenOpen(true)
  }

  render() {
    const { t, disabled } = this.props
    return (
      <PurchaseButton
        className="NFCNavigateToCreditCardButton"
        onClick={this.handleClick}
        disabled={disabled}
      >
        {t('nfc.paymentMethods.payWithCreditCard')}
      </PurchaseButton>
    )
  }
}

NavigateToCreditCardButton.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setIsCCScreenOpen: PropTypes.func.isRequired
}

export default compose(withRouter)(NavigateToCreditCardButton)
