export const PURCHASE_FLOWS = {
  checkout: 'checkout',
  extension: 'extension'
}

// Required for building specific urls on the backend.
// Please don't change without testing a purchase

export const PAYPAL_PURCHASE_FLOWS = {
  checkout: 'checkout',
  extension: 'extension'
}

export const PAYPAL_PURCHASE_SOURCES = {
  event: 'web-express-checkout-events',
  default: 'web-express-checkout'
}

export const DEFAULT_RESERVATION_PLATE = 'reservation'

export const PAYMENT_METHODS = {
  card: 'card',
  paypal: 'paypal',
  one_card: 'one_card',
  google_pay: 'google_pay_v4',
  apple_pay: 'apple_pay_v4',
  credit_card_sale: 'card_sale_v4',
  payroll_deduction: 'payroll_deduction'
}
