import React from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import Header from 'components/tto/layout/Header'
import PaymentTimeIcon from './_assets/icon-payment-time.svg'
import CalculateIcon from './_assets/icon-calculate.svg'
import UnlockIcon from './_assets/icon-unlocked.svg'

import styles from './UnderstandFeesRoute.module.scss'

const UnderstandFeesRoute = () => {
  const { t } = useTranslation()
  const { assetTag } = useParams()
  const history = useHistory()
  const { state: routerState } = useLocation()
  const barcode = get(routerState, 'barcode', null)
  const email = get(routerState, 'email', null)

  const onBackClick = () => {
    history.push({
      pathname: `/t/${assetTag}/authorization`,
      state: { barcode, email }
    })
  }

  const feesInfo = [
    {
      image: PaymentTimeIcon,
      imageAlt: 'Blue card with a yellow clock',
      header: t('nfc.tto.feesInfo.header1'),
      copy: t('nfc.tto.feesInfo.copy1')
    },
    {
      image: CalculateIcon,
      imageAlt: 'Pink and blue math operator blocks',
      header: t('nfc.tto.feesInfo.header2'),
      copy: t('nfc.tto.feesInfo.copy2'),
      italicCopy: t('nfc.tto.feesInfo.copyItalic')
    },
    {
      image: UnlockIcon,
      imageAlt: 'Padlock unlocked with green checkmark',
      header: t('nfc.tto.feesInfo.header3'),
      copy: t('nfc.tto.feesInfo.copy3')
    }
  ]

  return (
    <>
      <Header backNav onBackClick={onBackClick}>
        {t('nfc.tto.understandFees')}
      </Header>
      <div className={styles.container}>
        <div className={styles.content}>
          {feesInfo.map((info, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.wrapper}
            >
              <img src={info.image} alt={info.imageAlt} />
              <div className={styles.feesInfo}>
                <div className={styles.header}>{info.header}</div>
                <div className={styles.copy}>{info.copy}</div>
                {info.italicCopy && (
                  <div className={styles.copyItalic}>{info.italicCopy}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UnderstandFeesRoute
