import classnames from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import './PurchaseButton.scss'

class PurchaseButton extends PureComponent {
  render() {
    const { children, className, disabled } = this.props
    return (
      <div
        {...this.props}
        className={classnames('NFCPurchaseButton', className)}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        {children}
      </div>
    )
  }
}

PurchaseButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default PurchaseButton
