import './AppContainer.scss'
import PropTypes from 'prop-types'

const BlankContainer = ({ children }) => {
  return <div className="AppContainer ui">{children}</div>
}

BlankContainer.propTypes = {
  children: PropTypes.any
}

export default BlankContainer
