import React from 'react'
import PropTypes from 'prop-types'
import styles from './OverlayButton.module.scss'

const OverlayButton = ({ text, onClick }) => {
  return (
    <button className={styles.button} onClick={onClick} type="button">
      {text}
    </button>
  )
}

OverlayButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
}

export default OverlayButton
