import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import reducer from 'redux/reducers'

const store = createStore(
  combineReducers({ nfc: reducer }),
  compose(
    applyMiddleware(thunk),
    process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)

// hot reloading for store
if (module.hot) {
  module.hot.accept('redux/reducers', () => {
    store.replaceReducer(reducer)
  })
}

export default store
