import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import Button from 'components/tto/shared/Button'
import closeIcon from './_assets/close-icon.svg'

import styles from './Modal.module.scss'

const ModalComponent = ({ open, content, onClose, showCloseIcon = true }) => {
  return (
    <Modal open={open} onClose={onClose} className={styles.modalContainer}>
      <Modal.Content className={styles.modalContent}>
        <div className={styles.modal}>
          {showCloseIcon && (
            <button
              className={styles.closeIconButton}
              type="button"
              onClick={onClose}
              aria-label="Close"
            >
              <img src={closeIcon} alt="close x icon" />
            </button>
          )}
          <h3 className={styles.header}>{content.title}</h3>
          <p className={styles.content}>{content.message}</p>
          {content.buttonText && (
            <Button type="button" onClick={onClose} className={styles.button}>
              {content.buttonText}
            </Button>
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

ModalComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    buttonText: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired,
  showCloseIcon: PropTypes.bool
}

export default ModalComponent
