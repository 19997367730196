import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import history from 'app/history'
import isEmpty from 'lodash/isEmpty'
import { emailReceipt } from 'redux/actions'
import { Loader } from 'semantic-ui-react'
import { REMINDER_BUTTON_TYPES } from './constants'
import { ReactComponent as ResendIcon } from './_assets/icon-refresh-arrows.svg'

import styles from './ReminderButton.module.scss'

const ReminderButton = ({ type, sessionId, icon, buttonText, userInfo }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [receiptResent, setReceiptResent] = useState(false)

  const path = type === REMINDER_BUTTON_TYPES.sms ? 'sms' : 'send-receipt'

  useEffect(() => {
    let timeoutId = null

    if (receiptResent) {
      timeoutId = setTimeout(() => {
        setReceiptResent(false)
      }, 15000) // 15 seconds
    }

    // Cleanup function
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [receiptResent])

  const handleResendEmail = () => {
    setLoading(true)

    dispatch(emailReceipt(sessionId)).then((json) => {
      setLoading(false)

      if (json.ok) {
        setReceiptResent(true)
      }
    })
  }

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader active inline="centered" />
      </div>
    )
  }

  const renderResendButton = () =>
    receiptResent ? (
      <p className={styles.resendSuccess}>Resent receipt!</p>
    ) : (
      <button
        type="button"
        onClick={handleResendEmail}
        className={styles.resendButton}
      >
        <ResendIcon />
        {t('nfc.purchaseComplete.resend')}
      </button>
    )

  if (!isEmpty(userInfo)) {
    return (
      <div className={styles.reminderSection}>
        <div
          className={classNames(styles.nonClickableSection, {
            [styles.email]: type === REMINDER_BUTTON_TYPES.email
          })}
        >
          <img
            className={styles.reminderButtonIcon}
            src={icon}
            alt="button icon"
          />
          <div className={styles.reminderButtonText}>
            <p>{buttonText}</p>
            <p className={styles.userInfo}>
              {type === REMINDER_BUTTON_TYPES.sms
                ? userInfo
                : t('nfc.purchaseComplete.receiptSent', {
                    emailAddress: userInfo
                  })}
            </p>
            {type === REMINDER_BUTTON_TYPES.email && renderResendButton()}
          </div>
        </div>
        <Link
          className={styles.editLink}
          to={{
            pathname: `/p/session/${sessionId}/${path}`,
            state: { edit: true }
          }}
        >
          {t('nfc.purchaseComplete.edit')}
        </Link>
      </div>
    )
  }

  return (
    <button
      className={styles.reminderButton}
      type="button"
      onClick={() =>
        history.push({
          pathname: `/p/session/${sessionId}/${path}`,
          state: { edit: true }
        })
      }
    >
      <img className={styles.reminderButtonIcon} src={icon} alt="button icon" />
      <div className={styles.reminderButtonText}>
        <p>{buttonText}</p>
        <p>{userInfo}</p>
      </div>
    </button>
  )
}

ReminderButton.propTypes = {
  type: PropTypes.oneOf(['sms', 'email']).isRequired,
  sessionId: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  userInfo: PropTypes.string
}

export default ReminderButton
