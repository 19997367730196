import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import includes from 'lodash/includes'

import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isRequestSuccessful } from 'app/helpers/api'
import * as AssetTagActions from 'redux/actions'

const mapStateToProps = (state) => {
  return {
    loaded: state.nfc.loaded.assetTag
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    assetTagActions: bindActionCreators(AssetTagActions, dispatch)
  }
}

class AssetTagLoader extends Component {
  componentDidMount() {
    const { assetTagActions, name, loaded, handleError, history } = this.props

    if (!loaded) {
      const analyticsData = { asset_tag: name }
      trackEvent('Asset Tag Started Loading', analyticsData)
      assetTagActions.loadAssetTag(name).then((json) => {
        if (!isRequestSuccessful(json)) {
          if (
            json.status === 404 ||
            (json.errors && includes(json.errors, 'no_asset_tag'))
          ) {
            history.replace('/p/errors/not-found')
          } else {
            handleError()
          }
        }

        trackEvent('Asset Tag Loaded', analyticsData)
        return json

        /* TODO HANDLE ERROR
        if (includes(json.errors, 'not_allowed')) {
          return history.replace('/403')
        }

        return history.replace('/404')
        */
      })
    }
  }

  render() {
    const { loaded, children } = this.props
    if (loaded && children) {
      return <div>{children}</div>
    }
    return null
  }
}

AssetTagLoader.defaultProps = {
  handleError: () => {}
}

AssetTagLoader.propTypes = {
  name: PropTypes.string.isRequired,
  assetTagActions: PropTypes.shape({
    loadAssetTag: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleError: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AssetTagLoader)
