import React, { useState, useEffect } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import history from 'app/history'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import AssetTagLoader from 'components/tto/loaders/graphql/AssetTagLoader'
import AccountLoader from 'components/tto/loaders/graphql/AccountLoader'

import AllPurchaseButtons from './purchase/AllPurchaseButtons'
import EmailForm from './EmailForm'
import Loader from '../shared/Loader'
import Header from '../layout/Header'
import Modal from '../shared/Modal'
import AuthPageImage from './_assets/auth_page.svg'

import styles from './AuthorizationRoute.module.scss'

const AuthorizationRoute = () => {
  const { t } = useTranslation()
  const { assetTag } = useParams()
  // QR/barcode scan result is passed in as state
  const { state: routerState } = useLocation()
  const barcode = get(routerState, 'barcode', null)
  const emailFromRouter = get(routerState, 'email', null)
  const nfcAssetTag = useSelector((state) => state.nfc.assetTag)
  const [loading, setLoading] = useState(true)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [modalError, setModalError] = useState({
    title: t('nfc.tto.errors.general_title'),
    message: t('nfc.tto.errors.general_message'),
    buttonText: t('nfc.tto.errors.general_button')
  })
  const [emailAddress, setEmailAddress] = useState(emailFromRouter || '')

  useEffect(() => {
    if (!isEmpty(nfcAssetTag)) {
      setLoading(false)
    }
  }, [nfcAssetTag])

  const closeErrorModal = () => {
    setIsErrorModalOpen(false)
  }

  const handleBackClick = () => {
    history.push(`/t/${assetTag}/scan`)
  }

  useEffect(() => {
    if (isEmpty(barcode)) {
      history.replace(`/t/${assetTag}/scan`)
    }
  }, [barcode])

  if (loading) {
    return (
      <>
        <AssetTagLoader name={assetTag} redirectUrl="/t/errors/not-found">
          <AccountLoader />
        </AssetTagLoader>
        <Loader />
      </>
    )
  }

  return (
    <div className={styles.page}>
      <Header backNav onBackClick={handleBackClick}>
        {t('nfc.tto.auth.title')}
      </Header>
      <div className={styles.body}>
        <div className={styles.main}>
          <img
            src={AuthPageImage}
            alt="Credit card with clock"
            className={styles.image}
          />
          <h2>{t('nfc.tto.auth.header')}</h2>
          <p>
            {t('nfc.tto.auth.description')}{' '}
            <Link
              to={{
                pathname: `/t/${assetTag}/fees`,
                state: { barcode, email: emailAddress }
              }}
              className={styles.link}
            >
              {t('nfc.tto.auth.learnMore')}
            </Link>
          </p>
          <EmailForm
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
          />
        </div>
        <div className={styles.terms}>
          {t('nfc.event.termsCopy')}&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href={WHITELABEL_CONFIG.TERMS_URL}
          >
            {t('nfc.tto.ttoTermsLink')}
          </a>
        </div>
        <AllPurchaseButtons
          t={t}
          setIsErrorModalOpen={setIsErrorModalOpen}
          setModalError={setModalError}
          barcode={barcode}
          emailAddress={emailAddress}
        />
        <Modal
          open={isErrorModalOpen}
          content={modalError}
          onClose={closeErrorModal}
        />
      </div>
    </div>
  )
}

export default AuthorizationRoute
