import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { addressSelector, companySelector } from 'redux/reducers'
import Button from 'components/shared/Button'
import BackArrow from 'components/layout/_assets/icon-arrow-left.svg'
import { NFC_ZONE_ACTION_TYPES } from 'components/constants'
import FallbackLogo from '../_assets/logo_operator_fallback.svg'
import FallbackImage from '../_assets/zone_fallback.svg'

import styles from './Banner.module.scss'

const Banner = () => {
  const { t } = useTranslation()
  const zoneId = useSelector((state) => state.nfc.zone.zone_id)
  const zoneImages = useSelector((state) => state.nfc.zone.images)
  const zoneOptions = useSelector((state) => state.nfc.zoneOption)
  const actionType = useSelector((state) => state.nfc.zone.action_type)
  const { street } = useSelector(addressSelector)
  const {
    proper_name: properName,
    logo_thumbnail: logo,
    square_logo_thumbnail: squareLogo
  } = useSelector(companySelector)

  const zoneImage = isEmpty(zoneImages) ? null : zoneImages[0]
  // If square logo is not available, use the regular logo
  const companyLogo = isNil(squareLogo) ? logo : squareLogo
  // If company logo is not available, use the fallback logo
  const operatorLogo = isNil(companyLogo) ? FallbackLogo : companyLogo
  const bannerImage = isNil(zoneImage) ? FallbackImage : zoneImage
  const isPickerZone = actionType === NFC_ZONE_ACTION_TYPES.picker

  const backgroundStyle = {
    backgroundImage: `linear-gradient(to top, rgba(43,43,43,0.75), rgba(43,43,43,0)), url(${bannerImage})`
  }
  const isButtonVisible = !isEmpty(zoneOptions)

  const renderAddressZoneId = () => {
    if (isPickerZone) return null

    return (
      <div className={styles.addressZoneId}>
        {street && (
          <>
            <p className={styles.address}>{street}</p>
            <span>•</span>
          </>
        )}
        {zoneId && (
          <p className={styles.zone}>{t('nfc.tto.zone', { zoneId })}</p>
        )}
      </div>
    )
  }

  return (
    <div className={styles.banner} style={backgroundStyle}>
      <div className={styles.zoneInfoContainer}>
        <div
          className={styles.logo}
          style={{ backgroundImage: `url(${operatorLogo})` }}
          role="img"
          aria-label="Operator logo"
        />
        <div className={styles.zoneInfo}>
          <p className={styles.operatorName}>{properName}</p>
          {renderAddressZoneId()}
        </div>
      </div>
      {isButtonVisible && (
        <Button
          className={styles.backButton}
          onClick={() => window.location.reload()}
        >
          <img src={BackArrow} alt="Left arrow" />
          {t('nfc.navigation.goBack')}
        </Button>
      )}
    </div>
  )
}

export default Banner
