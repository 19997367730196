import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useMutation } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import PromoCodeModal from 'components/events/purchase/PromoCodeModal'

import { formatPrice } from 'app/helpers/formatting'
import { SetCartRate } from 'components/events/operations'
import { snakeCaseKeysDeep } from 'components/helpers/formatting'
import {
  cartSelector,
  ratesSelector,
  purchaseElementsLoadedSelector
} from 'redux/reducers'

import * as actionTypes from 'redux/actionTypes'
import PromoCode from './PromoCode'

import styles from './CartInformation.module.scss'

const CartInformation = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false)
  const dispatch = useDispatch()
  const cart = useSelector(cartSelector)
  const rates = useSelector(ratesSelector)
  const rate = rates[0] || {}
  const purchaseLoaded = useSelector(purchaseElementsLoadedSelector)

  const [setRate] = useMutation(SetCartRate, {
    onCompleted: ({ setCartRate }) => {
      const { cart: cartWithRate } = setCartRate
      const storeCart = snakeCaseKeysDeep(cartWithRate)
      storeCart.is_rate_selected = cartWithRate.itemQuantity > 0
      const isZeroDollar =
        cartWithRate.total === 0 && cartWithRate.itemQuantity > 0
      storeCart.is_zero_dollar_purchase = isZeroDollar

      dispatch({
        type: actionTypes.NFC_SET_RATE,
        cart: snakeCaseKeysDeep(storeCart),
        rateId: rate.hashid
      })
    }
  })

  const rateId = rate.hashid

  useEffect(() => {
    if (purchaseLoaded) {
      if (isEmpty(rateId)) {
        history.replace('/e/no-rates-available')
      } else {
        setRate({
          variables: { input: { cartId: cart.hashid, rateId } }
        })
      }
    }
  }, [rateId, purchaseLoaded])

  const displayPrice = (amount) => {
    const { is_rate_selected: isRateSelected } = cart

    if (!purchaseLoaded) {
      return ''
    }
    if (!isRateSelected) {
      return '---'
    }

    return `$${formatPrice(amount)}`
  }

  const { fee, total, discount } = cart

  return (
    <>
      <div className={styles.ratesCTA}>{t('nfc.event.payForParkingCta')}</div>
      <div className={styles.ratesCard}>
        <div className={styles.rateItemLine}>
          <div>{t('nfc.event.eventName')}</div>
          <div>{rate.description}</div>
        </div>
        <div className={styles.rateItemLine}>
          <div>{t('nfc.event.parkingFees')}</div>
          <div>{displayPrice(rate.price)}</div>
        </div>
        <div className={styles.rateItemLine}>
          <div>{t('nfc.event.serviceFee')}</div>
          <div>{displayPrice(fee)}</div>
        </div>
        <div className={styles.rateItemLine}>
          <PromoCode openPromoModal={() => setIsPromoModalOpen(true)} t={t} />
          <div className={classNames({ [styles.discount]: discount > 0 })}>
            {discount > 0 ? displayPrice(discount) : '-'}
          </div>
        </div>
        <div className={styles.totalItemLine}>
          <div>Total</div>
          <div>{displayPrice(total)}</div>
        </div>
      </div>
      <PromoCodeModal
        isOpen={isPromoModalOpen}
        setIsPromoModalOpen={setIsPromoModalOpen}
      />
    </>
  )
}

export default CartInformation
