import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import startsWith from 'lodash/startsWith'
import { useTranslation } from 'react-i18next'

const RouteWithHelmet = ({ title, path, layout: Layout, ...rest }) => {
  const { t } = useTranslation()
  const helmetTitle = () => {
    if (title) return title

    if (startsWith(path, '/p')) {
      return t('nfc.xcTitle')
    }
    if (startsWith(path, '/e')) {
      return t('nfc.xcEventTitle')
    }
    if (startsWith(path, '/t')) {
      return t('nfc.xcTTOTitle')
    }
    return t('nfc.honkTitle')
  }

  if (Layout) {
    return (
      <Layout>
        <Helmet title={helmetTitle()} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Route path={path} {...rest} />
      </Layout>
    )
  }

  return (
    <>
      <Helmet title={helmetTitle()} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Route path={path} {...rest} />
    </>
  )
}

RouteWithHelmet.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string.isRequired,
  layout: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

export default RouteWithHelmet
