import chargerOnIcon from './_assets/icon-ev-charger-on.svg'
import chargerOffIcon from './_assets/icon-ev-charger-off.svg'
import chargerLoadingIcon from './_assets/icon-ev-charger-loading.svg'

export const NFC_ZONE_ACTION_TYPES = {
  purchase: 'purchase',
  picker: 'picker'
}

export const NFC_ZONE_VALIDATION_TYPES = {
  by_plate: 'by_plate',
  by_space: 'by_space',
  by_zone_option_key: 'by_zone_option_key'
}

export const CHARGER_CARD_TEXT = {
  active: {
    status: 'active',
    text: 'Charger is on',
    icon: chargerOnIcon
  },
  inactive: {
    status: 'inactive',
    text: 'Charger is off',
    icon: chargerOffIcon
  },
  pending: {
    status: 'pending',
    text: 'Turning charger on',
    icon: chargerLoadingIcon
  }
}

export const AMERICAN_EXPRESS = 'Amex'
export const DISCOVER = 'Discover'
export const MASTERCARD = 'MasterCard'
export const VISA = 'Visa'
export const PAYPAL = 'PayPal'
export const ONE_CARD = 'OneCard'
export const APPLE_PAY = 'Apple Pay'
export const GOOGLE_PAY = 'Google Pay'
export const PAYROLL_DEDUCTION = 'Payroll Deduction'
export const INTERAC = 'Interac'
export const GENERIC = 'Generic'
export const ZERO = 'zero'

export const PAYMENT_CARD_TYPES = {
  apple_pay: APPLE_PAY,
  google_pay: GOOGLE_PAY, // does this make sense?
  AMEX: AMERICAN_EXPRESS,
  AMERICAN_EXPRESS,
  AX: AMERICAN_EXPRESS,
  DI: DISCOVER,
  DISCOVER,
  MC: MASTERCARD,
  MASTERCARD,
  PP: PAYPAL,
  one_card: ONE_CARD,
  paypal: PAYPAL,
  VI: VISA,
  VISA,
  payroll_deduction: PAYROLL_DEDUCTION,
  INTERAC,
  GENERIC
}

export const ERROR_TYPES = {
  blacklisted: 'blacklisted',
  cart_was_used: 'cart_was_used',
  over_capacity: 'over_capacity'
}

export const PAYMENT_GATEWAYS = {
  google_pay: 'google_pay',
  paypal: 'paypal',
  apple_pay: 'apple_pay',
  zero: 'zero'
}

export const REMINDER_BUTTON_TYPES = {
  email: 'email',
  sms: 'sms'
}

export const APPLICATION_TYPE = {
  publicXC: 'public_xc'
}
