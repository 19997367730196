import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { trackEvent } from 'app/helpers/analyticsHelpers'
import { isRequestSuccessful } from 'app/helpers/api'
import * as nfcActions from 'redux/actions'

const mapStateToProps = (state) => {
  return {
    loaded: state.nfc.loaded.plateNumber
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    vehicleActions: bindActionCreators(nfcActions, dispatch)
  }
}

const VehicleLoader = ({
  vehicleActions: { loadVehicle },
  loaded,
  children
}) => {
  useEffect(() => {
    if (!loaded) {
      loadVehicle().then((json) => {
        if (isRequestSuccessful(json)) {
          trackEvent('Vehicle Loaded')
        }
      })
    }
  }, [])

  if (loaded && children) {
    return children
  }

  return null
}

VehicleLoader.propTypes = {
  vehicleActions: PropTypes.shape({
    loadVehicle: PropTypes.func.isRequired
  }).isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node
}
export default connect(mapStateToProps, mapDispatchToProps)(VehicleLoader)
