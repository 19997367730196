const css = {
  background:
    'rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid rgb(185, 185, 185)',
  borderBlockEnd: '1px solid rgb(185, 185, 185)',
  borderBlockEndStyle: 'solid',
  borderBlockEndWidth: '1px',
  borderBlockStart: '1px solid rgb(185, 185, 185)',
  borderBlockStartStyle: 'solid',
  borderBlockStartWidth: '1px',
  borderBottom: '1px solid rgb(185, 185, 185)',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderInlineEnd: '1px solid rgb(185, 185, 185)',
  borderInlineEndStyle: 'solid',
  borderInlineEndWidth: '1px',
  borderInlineStart: '1px solid rgb(185, 185, 185)',
  borderInlineStartStyle: 'solid',
  borderInlineStartWidth: '1px',
  borderLeft: '1px solid rgb(185, 185, 185)',
  borderLeftStyle: 'solid',
  borderLeftWidth: '1px',
  borderRadius: '4px',
  borderRight: '1px solid rgb(185, 185, 185)',
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  borderStyle: 'solid',
  borderTop: '1px solid rgb(185, 185, 185)',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderWidth: '1px',
  bottom: 'auto',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px inset',
  boxSizing: 'border-box',
  caretColor: 'rgba(0, 0, 0, 0.87)',
  color: 'rgba(0, 0, 0, 0.87)',
  columnRule: '0px none rgba(0, 0, 0, 0.87)',
  columnRuleColor: 'rgba(0, 0, 0, 0.87)',
  flex: '1 0 auto',
  flexGrow: '1',
  flexShrink: '0',
  font: '700 14px / 17px ProximaNova, Arial, Helvetica, sans-serif',
  fontFamily: 'ProximaNova, Arial, Helvetica, sans-serif',
  fontSize: '14px',
  left: 'auto',
  lineHeight: '17px',
  maxInlineSize: '100%',
  maxWidth: '100%',
  minBlockSize: 'auto',
  minHeight: 'auto',
  minInlineSize: 'auto',
  minWidth: 'auto',
  outline: 'rgba(0, 0, 0, 0.87) none 0px',
  outlineColor: 'rgba(0, 0, 0, 0.87)',
  padding: '9.5px 14px',
  paddingBlockEnd: '9.5px',
  paddingBlockStart: '9.5px',
  paddingBottom: '9.5px',
  paddingInlineEnd: '14px',
  paddingInlineStart: '14px',
  paddingLeft: '14px',
  paddingRight: '14px',
  paddingTop: '9.5px',
  perspectiveOrigin: '218px 25px',
  right: 'auto',
  textDecoration: 'none solid rgba(0, 0, 0, 0.87)',
  textDecorationColor: 'rgba(0, 0, 0, 0.87)',
  textSizeAdjust: '100%',
  top: 'auto',
  transformOrigin: '218px 25px',
  transition: 'color 0.1s ease 0s, border-color 0.1s ease 0s',
  transitionDelay: '0s, 0s',
  transitionDuration: '0.1s, 0.1s',
  transitionProperty: 'color, border-color',
  transitionTimingFunction: 'ease, ease',
  verticalAlign: 'top',
  //
  'text-align': 'center',
  height: '50px',
  'font-weight': 'bold',
  'letter-spacing': '1px',
  'border-color': 'rgba(34, 36, 38, 0.15);',
  'text-transform': 'uppercase'
}

export default css
