import { useRef, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import makeAsyncScriptLoader from 'react-async-script'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import classNames from 'classnames'
import { formatPrice } from 'app/helpers/formatting'
import { isiOS } from 'app/helpers/device'

const GOOGLE_PAY_URL = 'https://pay.google.com/gp/p/js/pay.js'
const GOOGLE_PAY_ENV = ENV.PRODUCTION ? 'PRODUCTION' : 'TEST'

import './TestButton.scss'

const formatAddress = (address = {}) => address.street

const mapStateToProps = ({ nfc }) => {
  const {
    plateNumber,
    merchantInfo,
    zone: { address }
  } = nfc

  // Provided by google, not our MerchantInfo.id
  // see https://developers.google.com/pay/api/web/guides/tutorial#tokenization,
  // In TEST env, it works with `undefined` as a merchantId, but it's
  // null in the serialized response, which doesn't work.
  // In PRODUCTION, you'll definitely need a real key either way.
  const merchantId =
    get(merchantInfo, ['google_pay_gateway', 'merchant_id']) || undefined

  return {
    address,
    plateNumber,
    merchantId,
    isSupported: get(merchantInfo, ['google_pay_gateway', 'supported']),
    merchantName: get(merchantInfo, ['google_pay_gateway', 'display_name']),
    gatewayName: get(merchantInfo, ['google_pay_gateway', 'gateway']),
    gatewayMerchantId: get(merchantInfo, [
      'google_pay_gateway',
      'gateway_merchant_id'
    ]) // see https://developers.google.com/pay/api/web/guides/tutorial#tokenization
  }
}

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0
}

const GoogleTestButton = ({
  google,
  merchantName,
  merchantId,
  gatewayName,
  gatewayMerchantId,
  plateNumber,
  cart,
  address,
  isSupported,
  disabled,
  setPayload,
  setHidePayload
}) => {
  const { total, id: cartId, currency_code: currencyCode } = cart

  if (isiOS()) {
    return null
  }
  if (!isSupported) {
    return null
  }

  // For holding one client across multiple renders
  const paymentsClient = useRef(null)
  // For holding DOM reference to attach the client button
  const buttonRef = useRef(null)
  // For holding variables outside of function scope for Google Pay callbacks
  const stashed = useRef({})

  const allowedCardNetworks =
    currencyCode === 'USD'
      ? ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA']
      : ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA', 'INTERAC']

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedCardNetworks,
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      billingAddressRequired: true,
      billingAddressParameters: { format: 'MIN' }
    }
  }

  const cardPaymentMethod = {
    ...baseCardPaymentMethod,
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: { gateway: gatewayName, gatewayMerchantId }
    }
  }

  const handlePayment = (paymentData) => {
    const tokenJson = get(
      paymentData,
      'paymentMethodData.tokenizationData.token',
      null
    )
    const tokenObj = JSON.parse(tokenJson)

    setPayload({ google_token: tokenObj })
    setHidePayload(false)
  }

  const openGooglePay = () => {
    const countryCode = currencyCode === 'USD' ? 'US' : 'CA'
    const totalPrice = formatPrice(total)

    const paymentDataRequest = {
      ...BASE_REQUEST,
      emailRequired: true,
      merchantInfo: {
        merchantName: `${merchantName} - ${formatAddress(address)}`,
        merchantId
      },
      allowedPaymentMethods: [cardPaymentMethod],
      transactionInfo: {
        // NOT_CURRENTLY_KNOWN | ESTIMATED | FINAL
        // https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo
        totalPriceStatus: 'FINAL',
        totalPrice,
        currencyCode,
        countryCode
      }
    }

    paymentsClient.current
      .loadPaymentData(paymentDataRequest)
      .then(handlePayment)
  }

  const handleClick = () => {
    if (!disabled) {
      openGooglePay()
    }
  }

  const debouncedHandleClick = useCallback(
    debounce(
      handleClick,
      500,
      { leading: true, trailing: false }
      // *Techincally* we could also be watching currencyCode, merchantId, etc
      // but really, all that should be ready and unchanging once loaded.
      // All we really need here is to make sure to rebuild if the total changes
    ),
    [total, disabled]
  )

  // Stash variables needed for handlePayment,
  // since that callback is only registered once on init
  // and we need a way to look them up dynamically
  // at call time, otherwise it uses the variables
  // from the stale scope
  useEffect(() => {
    stashed.current = { total, cartId, plateNumber }
  }, [total, cartId, plateNumber])

  const isGooglePayLoaded = google && google.payments

  useEffect(() => {
    if (isGooglePayLoaded) {
      paymentsClient.current = new google.payments.api.PaymentsClient({
        environment: GOOGLE_PAY_ENV
      })

      const isReadyToPayRequest = {
        ...BASE_REQUEST,
        allowedPaymentMethods: [baseCardPaymentMethod]
      }

      paymentsClient.current
        .isReadyToPay(isReadyToPayRequest)
        .then((res) => {
          if (res.result) {
            const button = paymentsClient.current.createButton({
              buttonSizeMode: 'fill',
              // Required parameter, but a no-op here because
              // it's easier to move the click logic into React
              // and have the function prce etc. update as props change
              onClick: () => {}
            })
            buttonRef.current.appendChild(button)
          }
        })
        .catch(() => {
          // TODO handle errors
        })
    }
  }, [isGooglePayLoaded])

  // Optional, just speeds things up a little once there's enough
  // cart info to fetch payment info fromm Google Pay in advance of the button click
  useEffect(() => {
    if (currencyCode && isGooglePayLoaded) {
      const prefetchPaymentDataRequest = {
        ...BASE_REQUEST,
        merchantInfo: { merchantName, merchantId },
        allowedPaymentMethods: [cardPaymentMethod],
        transactionInfo: {
          totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
          currencyCode
        }
      }

      paymentsClient.current.prefetchPaymentData(prefetchPaymentDataRequest)
    }
  }, [currencyCode, isGooglePayLoaded])

  return (
    <button
      type="button"
      onClick={debouncedHandleClick}
      className={classNames('NFCTestButton NFCTestButton--google', {
        disabled
      })}
    >
      Google Pay
    </button>
  )
}

GoogleTestButton.propTypes = {
  isSupported: PropTypes.bool,
  plateNumber: PropTypes.string,
  merchantName: PropTypes.string,
  merchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gatewayName: PropTypes.string,
  gatewayMerchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cart: PropTypes.shape({
    id: PropTypes.number,
    total: PropTypes.string,
    currency_code: PropTypes.string
  }),
  google: PropTypes.shape({
    payments: PropTypes.shape({
      api: PropTypes.shape({
        PaymentsClient: PropTypes.any
      })
    })
  }),
  address: PropTypes.shape({ street: PropTypes.string }),
  disabled: PropTypes.bool,
  setPayload: PropTypes.func,
  setHidePayload: PropTypes.func
}

export default compose(
  connect(mapStateToProps),
  makeAsyncScriptLoader(GOOGLE_PAY_URL, { globalName: 'google' })
)(GoogleTestButton)
